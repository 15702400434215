import React, {useState, useEffect} from 'react';
import TypeaheadResultsList from './typeahead';
import SearchBar from './searchBar';
import { useElasticSearch, searchContextToQuery, searchBarContextToQuery, yearsToDecades, decadeToYears } from '../util.js';

function DateFilterFirstLastInput(props) {

  // CHECKING YEAR INPUTS:
  const isValidYear = (val) => {
    const isnum = /^\d+$/.test(val);
    return (isnum && val.length === 4);
  }

  const yearInputKeyUp = (e, idx) => {
    const val = e.currentTarget.value;
    const entryOK = (val.length === 0 || isValidYear(val));
    let newInvalids = [...yearsInvalid];
    newInvalids[idx] = !entryOK;
    setYearsInvalid(newInvalids);
    let newEmptys = [...yearsEmpty];
    newEmptys[idx] = (val.length === 0);
    setYearsEmpty(newEmptys);
    // apply button is disabled iff both year fields are empty OR either year field has invalid data
    setApplyYearDisabled(newInvalids[0] || newInvalids[1] || (newEmptys[0] && newEmptys[1]));
    
    // giving the 'benefit of the doubt', clear any error message for this input
    let newErr = [...yearsErrorMsg];
    newErr[idx] = false;
    setYearsErrorMsg(newErr);

    // update the year range values
    let newYr = [...yearRange];
    newYr[idx] = (val.length === 0 ? null : val);
    setYearRange(newYr);
  }

  const yearInputBlur = (e, idx) => {
    const val = e.currentTarget.value;
    const entryOK = (val.length === 0 || isValidYear(val));
    let newErr = [...yearsErrorMsg];
    newErr[idx] = (!entryOK);
    setYearsErrorMsg(newErr);
    // refocus? 
  }

  const yearInputFocus = (e, idx) => {}

  const [ applyYearDisabled, setApplyYearDisabled ] = useState(true);
  const [ yearsInvalid, setYearsInvalid ] = useState([false, false]);
  const [ yearsEmpty, setYearsEmpty ] = useState([true, true]);
  const [ yearsErrorMsg, setYearsErrorMsg ] = useState([false, false]);
  const [ yearRange, setYearRange ] = useState([null, null]);

  return <div className={props.className}>
          <form className="year-select" onSubmit={(e) => { e.preventDefault(); props.applyFilters({ dateRange: yearRange },[]) }}>
            <h5>Enter a range of years:</h5>
            <div className="form-controls">
              <div>
                <label for="year-select-from">From</label>
                <input type="tel" placeholder="yyyy" pattern="[0-9]{4}" minlength="4" maxlength="4" max="9999" id="year-select-from" className="form-control" onFocus={(e) => yearInputFocus(e, 0)} onBlur={(e) => yearInputBlur(e, 0)} onKeyUp={(e) => yearInputKeyUp(e, 0)} />
                <div className={`error-msg ${(yearsErrorMsg[0]) ? '' : 'd-none'}`} id="year-select-from-error">
                  Please enter a 4-digit year
                </div>
              </div>
              <div>
                <label for="year-select-to">To</label>
                <input type="tel" placeholder="yyyy" pattern="[0-9]{4}" minlength="4" maxlength="4" max="9999" id="year-select-to" className="form-control" onFocus={(e) => yearInputFocus(e, 1)} onBlur={(e) => yearInputBlur(e, 1)} onKeyUp={(e) => yearInputKeyUp(e, 1)} />
                <div className={`filters-error-msg ${(yearsErrorMsg[1]) ? '' : 'd-none'}`} id="year-select-to-error">
                  Please enter a 4-digit year
                </div>
              </div>
              <div className="button">
                <button className="btn btn-primary" type="submit" disabled={applyYearDisabled} id="applyYearRange">Apply</button>
              </div>
            </div>
          </form>
        </div>
}

function FacetItemList(props) {
    /* 
        Produces a clickable list of items in props.aggregation 
    */
    function clickHandler(event,bucket) {
      event.preventDefault();
      if (!bucket) {
        props.clearFilter(props.filterName);
        return;
      }

      var result = {};
      switch (props.filterName) {
        case 'dateRange':
          result[props.filterName] = decadeToYears(bucket);
          break;
        default:
          result[props.filterName] = bucket.key.label;
          break;
      }
      // console.log(result,props.filterUnsets)
      props.applyFilters(result, props.filterUnsets || []);
    }

    if (props.sortByCount) {
      props.aggregation.sort((bucket1, bucket2) => { return bucket2.active.doc_count - bucket1.active.doc_count });
    }

    return <div className={props.className}>
        <h5>{ props.name }</h5>
        <div className="landing-filters-dropdown">
          <ul className="landing-filters-dropdown-menu">
            <React.Fragment>
            { props.showAnyItem ? <li><a className={`dropdown-item ${props.selected === '' ? 'active' : ''}`} href="#" onClick={ () => clickHandler(null) }>Any ({props.count})</a></li> : <div className="d-none"></div> }
            { props.aggregation.map( (bucket,i) => <li key={i}><a className={`dropdown-item ${props.selected === '' ? 'active' : ''}`} href="#" onClick={ (event) => clickHandler(event,bucket) }>{ bucket.key.label } ({ bucket.active.doc_count })</a></li> ) }
            </React.Fragment>
          </ul>
        </div>
    </div>
}

const TimeframeInputs = (props) => {
    return (
    <div className="filters-flex-container">
        <FacetItemList className="" 
                      sortByCount={false}
                      name="Select a decade of interest:" 
                      filterName="dateRange" 
                      applyFilters={props.applyFilters}
                      clearFilter={props.clearFilter}
                      aggregation={ (props.aggregations.active_years) ? yearsToDecades(props.aggregations.active_years.buckets) : [] } 
                      count={props.count}
                      />

        <div className="">
          <div className="pr-4">
            <h4 className="filters-or filters-movedup">Or</h4>
          </div>
        </div>

        <DateFilterFirstLastInput className="" applyFilters={props.applyFilters} />
        <div className="d-none d-md-block ml-auto filters-close-menu">
          <input type="button" value="Close menu" className="filter-drawer-uncheck" />
        </div>
    </div>
    )
}

const ArtTypeInputs = (props) => {

    return (
    <div className="filters-flex-container">

        <FacetItemList sortByCount={false} className="" name="Role" filterName="artist_role" applyFilters={props.applyFilters} clearFilter={props.clearFilter} aggregation={ (props.aggregations && props.aggregations.artist_role ) ? props.aggregations.artist_role.buckets : [] } count={props.count}/>

        <div className="">
          <div className="pr-4">
            <h4 className="filters-or filters-movedup">Or</h4>
          </div>
        </div>

        <FacetItemList sortByCount={false} className="" name="Medium" filterName="type_of_art" applyFilters={props.applyFilters} clearFilter={props.clearFilter} aggregation={ (props.aggregations && props.aggregations.type_of_art ) ? props.aggregations.type_of_art.buckets : [] } count={props.count}/>

        <div className="d-none d-md-block ml-auto filters-close-menu">
          <input type="button" value="Close menu" className="filter-drawer-uncheck" />
        </div>

    </div>
    )
}

const OrganizationInputs = (props) => {
    return (
        <div className="filters-flex-container">
          {
            props.entityType?.toLowerCase() === "artist" ?
              <FacetItemList sortByCount={false} className="" name="Organizations" filterName="organizations" applyFilters={props.applyFilters} clearFilter={props.clearFilter} aggregation={ (props.aggregations && props.aggregations.organizations ) ? props.aggregations.organizations.buckets : [] } count={props.count}/>
              :
              <FacetItemList sortByCount={false} className="" name="Organizations" filterName="organizer" applyFilters={props.applyFilters} clearFilter={props.clearFilter} aggregation={ (props.aggregations && props.aggregations.organizer ) ? props.aggregations.organizer.buckets : [] } count={props.count}/>              
          }
          {
            props.entityType?.toLowerCase() === "exhibitions" ?
            <FacetItemList sortByCount={false} className="pl-md-4" name="Parent Organizations" filterName="organizer_authority" applyFilters={props.applyFilters} clearFilter={props.clearFilter} aggregation={ (props.aggregations && props.aggregations.organizer_authority ) ? props.aggregations.organizer_authority.buckets : [] } count={props.count}/>            
            :
            <div className="d-none"></div>
          }
          {
            props.entityType?.toLowerCase() === "artist" ?
              <React.Fragment>
                <div className="">
                  <div className="pr-4">
                    <h4 className="filters-or filters-movedup">Or</h4>
                  </div>
                </div>
                <FacetItemList sortByCount={false} className="" name="Education" filterName="education" applyFilters={props.applyFilters} clearFilter={props.clearFilter} aggregation={ (props.aggregations && props.aggregations.education ) ? props.aggregations.education.buckets : [] } count={props.count}/>
              </React.Fragment>
              : 
              <div className="d-none"></div>
          }
          <div className="d-none d-md-block ml-auto filters-close-menu">
            <input type="button" value="Close menu" className="filter-drawer-uncheck" />
          </div>

        </div>
    )
}

const DemographicInputs = (props) => {
    return (
        <div className="filters-flex-container">
            <FacetItemList sortByCount={false} className="" name="Gender" filterName="gender" applyFilters={props.applyFilters} clearFilter={props.clearFilter} aggregation={ (props.aggregations && props.aggregations.gender ) ? props.aggregations.gender.buckets : [] } count={props.count}/>

            <div className="">
              <div className="pr-4">
                <h4 className="filters-or filters-movedup">Or</h4>
              </div>
            </div>

            <FacetItemList sortByCount={false} className="" name="Race / Ethnicity" filterName="ethnicity" applyFilters={props.applyFilters} clearFilter={props.clearFilter} aggregation={ (props.aggregations && props.aggregations.ethnicity ) ? props.aggregations.ethnicity.buckets : [] } count={props.count}/>

            <div className="pt-md-5">
              <div className="dropdown-info">
                <div className="checkmark">
                  <input className="form-check-input" type="checkbox" id={props.ethnicityCheckId} />
                  <label className="form-check-label" for={props.ethnicityCheckId}>Include artists of unknown race/ethnicity</label>
                </div>
                <p>For many artists in this collection, race/ethnicity is not documented in the historical record. Those artists are categorized as unknown.</p>
              </div>
            </div>
            <div className="d-none d-md-block ml-auto filters-close-menu">
              <input type="button" value="Close menu" className="filter-drawer-uncheck" />
            </div>
        </div>
    )
}

const LocationInputs = (props) => {
    const [ endpoint, setEndpoint ] = useState(props.endpoint);
    const [ entityType, setEntityType ] = useState('Location')
    const [ queryString, setQueryString ] = useState('');
    const [ query, setQuery ] = useState(null);
    const [ partialLocationString, setPartialLocationString ] = useState(null)
    const [ partialLocationQuery, setPartialLocationQuery ] = useState(null)
    const [ isOpen, setIsOpen ] = useState(false);
    const [ textError, setTextError ] = useState(false);

    const results = useElasticSearch(endpoint, query);
    const locationCount = useElasticSearch(endpoint, partialLocationQuery)

    useEffect(() => {
      if (locationCount) {

        let count = locationCount?.hits?.total?.value ?? 0
        if (count > 0) {
          props.applyFilters({'active_location_partial': partialLocationString},[])
        } else {
          setTextError(true)
        }

      }
    },[locationCount])

    function changeHandler(event) {
        let newQueryString = event.target.value;
        // FIXME: We may need more than simple JSON safety?
        if ( newQueryString.length > 2 ) {
            setIsOpen(true);
            setQueryString(newQueryString);
            setQuery(searchContextToQuery(newQueryString, {},entityType));
        } else {
            setIsOpen(false);
        }
    }

    const selectRegion = (r, unsets) => {
      // e.preventDefault();
      console.log(r)
      if (r === '') {
        props.clearFilter('region');
      } else {
        props.applyFilters( r, unsets);
      }
    }

    const selectCounty = (r, unsets) => {
      // e.preventDefault();
      if (r === '') {
        props.clearFilter('county');
      } else {
        props.applyFilters(r, unsets);
      }
    }  

    const selectLocation = ( r, unsets, partial=true ) => {
      console.log(r)
      // FIXME: 
      // setIsOpen(false);
      if (r === '') {
        props.clearFilter('active_location');
      } else {
        if (partial) {
          var query = searchBarContextToQuery(r, "Location")
          delete query['sort']
          setPartialLocationString(r)
          setPartialLocationQuery(query) 
        } else {
          props.applyFilters( { active_location: r }, []);          
        }
      }

    }

    const searchBarSelectionHandler = ({partial,town,county}) => {
      if (partial) {

        var query = searchBarContextToQuery(partial, "Location")
        delete query['sort']
        setPartialLocationString(partial)
        setPartialLocationQuery(query) 

      } else if (town) {
        props.applyFilters({active_location: town},[])        
      } else if (county) {
        props.applyFilters({county: county},[])
      }

    }

    const clearError = () => { setTextError(false) }

    // console.log(`entity type is artist? ${props.entityType?.toLowerCase() === "artist"} ${props.entityType}`)
    return (
        <div className="filters-flex-container">
          {
            props.entityType?.toLowerCase() === "artist" ? 
            <React.Fragment><FacetItemList className=""
                        sortByCount={false}
                        name="Region"
                        filterName="region"
                        filterUnsets={['county']} // should be unset because it's mutually exclusive w. this filter
                        applyFilters={selectRegion}
                        clearFilter={props.clearFilter}
                        aggregation={ (props.aggregations?.active_regions) ? props.aggregations.active_regions.buckets : [] }
                        count={props.count}
                      />
            <FacetItemList className=""
                        sortByCount={false}
                        name="County"
                        filterName="county"
                        filterUnsets={['region']} // should be unset because it's mutually exclusive w. this filter
                        applyFilters={selectCounty}
                        clearFilter={props.clearFilter}
                        aggregation={ (props.aggregations?.active_counties) ? props.aggregations.active_counties.buckets : [] }
                        count={props.count}
                      />

            <div className="pt-md-5">
                <h5>Look up a location</h5>
                <SearchBar applyFilters={searchBarSelectionHandler} searchBarType={'facet'} searchBarClassName="filters-search-bar" inputDidFocus={clearError} inputDidBlur={clearError} clearError={clearError} endpoint={endpoint} />
                <p>Type in a Missouri town/city or county</p>
                <div className={`filters-error-msg ${textError ? "" : "d-none"}`}>
                  No matching Missouri locations found
                </div>
            </div></React.Fragment>
            :
            <FacetItemList className=""
                        sortByCount={false}
                        name="Location"
                        filterName="location"
                        filterUnsets={[]}
                        applyFilters={selectRegion}
                        clearFilter={props.clearFilter}
                        aggregation={ (props.aggregations?.location) ? props.aggregations.location.buckets : [] }
                        count={props.count} />
          
          }
            <div className="d-none d-md-block ml-auto filters-close-menu">
                <input type="button" value="Close menu" className="filter-drawer-uncheck" />
            </div>
        </div>
    )
}

export { TimeframeInputs, ArtTypeInputs, OrganizationInputs, DemographicInputs, LocationInputs };