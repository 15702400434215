import React, {useState, useEffect, useRef} from 'react';

import * as d3 from "d3";
import { textWrap } from './util.js';

function TermBarPlotView(props) {
    const container = useRef(null);

    useEffect(() => {
        if ( props.dataSource === null && container !== null ) { return; }
        drawChart();
    }, [props.dataSource]);

	// const [selectedYears, setSelectedYears] = useState([props.filters]);
    const selectedYearsRef = useRef(props.filters);
    useEffect(() => {
        // setSelectedYears([props.filters]);
        selectedYearsRef.current = props.filters;
		setupBars();
        setupAxis();
    }, [props.filters]);

    let width = 320;
    let height = 380;
    let margin = { left: 83, right: 50, top: 10, bottom: 10 };
    let innerWidth = width - margin.left - margin.right;
    let innerHeight = height - margin.top - margin.bottom;

    function drawChart() {
        setupScales();
        setupBars();
        setupAxis();
    }

    var y = d3.scaleBand()
            .domain(d3.range(props.dataSource.length))
            .range([0, innerHeight])
            .paddingOuter(0.5)
            .paddingInner(0.8);

    var x = d3.scaleLinear()
            .domain([0, d3.max(props.dataSource, d => d.doc_count)])
            .nice()
            .range([0, innerWidth]);

    function setupScales() {
        if (props.dataSource.length == 0) { return; }

        y = d3.scaleBand()
            .domain(d3.range(props.dataSource.length))
            .range([0, innerHeight])
            .paddingOuter(0.1)
            .paddingInner(0.5);

        x = d3.scaleLinear()
            .domain([0, d3.max(props.dataSource, d => d.doc_count)])
            .nice()
            .range([0, innerWidth]);
    }

    function noChildren(d3Selection) { 
        return d3Selection.selectChild("*").empty();
    }

    function selectedYearRange() {
        return `f.dateRange=${[selectedYearsRef.current.active_begin,selectedYearsRef.current.active_end]}`;
    }

    function selectedYearText() {
        const begin = selectedYearsRef.current.active_begin;
        const end = selectedYearsRef.current.active_end;
        const isEndSameCentury = (Math.floor(begin/100) === Math.floor(end/100));
        // console.log('isEndSameCentury=' + isEndSameCentury + '; ' + (Math.floor(begin/100)) + '; ' + (Math.floor(end/100)))
        return `${begin}${(end > begin) ? '-' + (isEndSameCentury ? (end % 100) : end) : ''}: `;
    }

    // ======== setupAxis
    function setupAxis() {
        const svg = d3.select(container.current);
        const axisNode = svg.select(".bars-axis")
            .attr('transform', `translate(0,${margin.top})`);

        if ( !noChildren(axisNode) ) {
            // remove children
            axisNode.selectAll('g').remove();
        }

        if ( props.dataSource.length == 0 ) { return; }

        axisNode.append("g")
            .call(
                d3.axisLeft(y)
                    // .tickValues( props.dataSource.map( item => item.key ) )
                    .tickFormat( (i) => props.dataSource[i].key )
                    .tickSize(6)
            )
            .call(g => g.select(".domain").remove()) // hide the axis line
            .selectAll(".tick")
                .on("click", (event, i) => {
                    //console.log((event.path && event.path.length>1) ? event.path[1].textContent : '');
                    let url = `/search?f.${props.chartType}=${encodeURI(props.dataSource[i].key)}&${selectedYearRange()}`;
                    window.location = url;
            })
            .selectAll("text")
                .attr("class", "barchart-tick-text")
                .style("cursor", "pointer")
                .call(textWrap, margin.left - 5, 1.0, true, true)

    }

    // ======== main data:
    function setupBars() {
        const svg = d3.select(container.current);
        const barsNode = svg.select(".term-bars")
            .attr('transform', `translate(${margin.left},${margin.top})`);

        if ( !noChildren(barsNode) ) {
            // remove children
            barsNode.selectAll('rect').remove();
        }

        barsNode.selectAll("rect.graybars")
                .data(props.dataSource)
                // gray background bars 1st:
                .join("rect")
                    .attr("fill", "lightgray")
                    .attr("x", -1)
                    .attr("y", (d, i) => y(i))
                    .attr("rx", 2)
                    .attr("ry", 2)
                    .attr("height", y.bandwidth())
                    .attr("width", function(d) { return x(d.doc_count)+2; })    // +2 for inset effect
                    .attr("class", "graybars");

        barsNode.selectAll("rect.bars")
                .data(props.dataSource)
                // now blue bars:
                .join("rect")
                    .attr("fill", "#5F90B9")
                    .attr("x", 0)   // needs to be min. 0
                    .attr("y", (d, i) => y(i)+1)    // +1 for inset effect
                    .attr("rx", 2)
                    .attr("ry", 2)
                    .attr("height", y.bandwidth()-2)    // for inset effect
                    .attr("width", function(d) { return x(d.active.doc_count); })   // achieve this inset effect w. gray
                    .attr("class", "bars")
                    .style("cursor", "pointer")
                    .on("click", (event, d) => {
                        const url = `/search?f.${props.chartType}=${encodeURI(d.key)}&${selectedYearRange()}`;
                        window.location = url;
                    })
                    .append('title')
                        .text(d => selectedYearText() + d.active.doc_count + ' ' + d.key);

        barsNode.selectAll("text")
                .data(props.dataSource )
                .join("text")
                    .attr("x", function(d) { return x(d.doc_count); })
                    .attr("y", function(d, i) { return y(i); })
                    .text( function(d) { return d.active.doc_count; } )
                    .attr("class", "barchart-number")
                    // move text over & down a bit:
                    .attr("dx", 5)
                    .attr("dy", 13);

    }

    function animateBars() {
    }

    return <div style={{width: "100%", height: "100%"}}>
                <h4>{props.title.toUpperCase()}</h4>
                <svg 
                    className="term-bar-viz"
                    viewBox={`0 0 ${width} ${height}`}
                    height={`${height}`}
                    width="100%"
                    preserveAspectRatio="none"
                    ref={container}
                >
                    <g className="term-bars" />
                    <g className="bars-axis" />
                </svg>
            </div>

}

export { TermBarPlotView };