import React from 'react';
import SearchBar from './components/searchBar';

function OrganizationHeroView(props) {

    /* FIXME: Location search bar handling */
    const selectionHandler = ({searchWithin}) => {
     let newSelectionEvent = new CustomEvent('cb-search-within-list',{ detail: { searchWithin } });
     window.dispatchEvent(newSelectionEvent);
    }

    return <React.Fragment>
            <div className="justify-content-between mt-4">

              <div className="organization-icon-row d-flex mb-3">
                <div className="organization-icon-box">
                  <span className="icon-circle icon-circle--white">
                    <span className="icon icon-property-user"></span>
                  </span>
                  <p><span>{props.artistCount}</span> <span className="d-block d-sm-inline">Artists</span></p>
                </div>
                <div className="organization-icon-box">
                  <span className="icon-circle icon-circle--white">
                    <span className="icon icon-institution"></span>
                  </span>
                  <p><span>{props.orgCount}</span> <span className="d-block d-sm-inline">Organizations</span></p>
                </div>
                <div className="organization-icon-box">
                  <span className="icon-circle icon-circle--white">
                    <span className="icon icon-exhibition"></span>
                  </span>
                  <p><span>{props.exhibsCount}</span> <span className="d-block d-sm-inline">Exhibitions</span></p>
                </div>
              </div>
              <div className="organization-search-row d-flex">
                <p className="organization-search-label text-white text-right mr-2 mb-0 ml-0">
                  Search in list
                </p>
                <SearchBar type={props.type} applyFilters={ (r,event) => selectionHandler({searchWithin: r}) } searchBarType={'searchWithin'} searchBarClassName="location-search-form morem-pr-hide" endpoint={props.endpoint} />
              </div>

            </div>
          </React.Fragment>
}

export {OrganizationHeroView}
