import React from 'react';

const AboutPage = (props) => {
    return (
        <div>
        <h1>About this Artists Portal</h1>
        <p>Missouri Remembers is a freely available resource that documents the careers of visual artists whose practice has shaped the artistic heritage of the state. Throughout the planning and development of the <em>Missouri Remembers: Artists in Missouri through 1951</em> portal the project team had to make many decisions which define the types of information and scope included in this resource. These decisions are recorded here on this page so researchers, students and enthusiasts can use this data effectively to discover Missouri artists.</p>
        <div className="accordion" id="accordionAboutPg">
            <div className="card">
                <div className="card-header-about" id="heading01">
                <h4 className="mb-0" id="who-is-a-missouri-artist">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse01" aria-expanded="false" aria-controls="collapse01">
                    Who is a Missouri Artist?
                    </button>
                </h4>
                </div>
                <div id="collapse01" className="collapse" aria-labelledby="heading01" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <p>Limiting the scope of this database by location, the state of Missouri, allows us to include regional artists whose careers and impact may be regionally important but may have little impact beyond the boundaries of Missouri state lines. Many other states have biographical dictionaries that document artists practicing within their state, even lesser known artists whose reputation may not have extended beyond the state’s border. Without a similar resource, Missouri’s regional artists aren’t easily discoverable and Missourians aren’t able to learn about the state’s artistic and cultural heritage. Missouri was not only a jumping off point for those traveling West during westward expansion but it was also a crossroads, especially in St. Louis, a thriving cultural hub. The research team took several factors into account to determine who is a Missouri artist. In general artists who have practiced in the state for three or more years are included. In addition, artists who may not have practiced for three years but whose presence had a significant impact on the artistic culture of the state will also be included. This could include artists who started artist colonies, taught classes, have a significant exhibition record in regional exhibitions or went to school in the state but will generally not include artists who were born in Missouri but whose artistic career was in another state or artists who were based elsewhere but exhibited or were commissioned to complete projects in the state.</p>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header-about" id="heading02">
                <h4 className="mb-0" id="why-does-this-resource-only-cover-artists-active-through-1951">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse02" aria-expanded="false" aria-controls="collapse02">
                    Why does this resource only cover artists active through 1951?
                    </button>
                </h4>
                </div>
                <div id="collapse02" className="collapse" aria-labelledby="heading02" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <p>Although the idea for a biographical dictionary of Missouri artists has been around for a long time the impetus for moving this project forward was Missouri’s Bicentennial in 2021. The State Historical Society of Missouri is leading the coordination of the bicentennial celebration across the state with a spirit of collaboration and a desire to share the state’s rich and diverse history and culture.</p>
                    <p>The portal’s closing date, 1951, was selected based on two factors. The first is the increased mobility of the general population after World War II. People moving more frequently makes it harder to determine who is a Missouri artist. The second is that the partner institutions have initiatives that strive to document contemporary practicing artists. Rather than duplicate our efforts we see this portal as part of a continuum, where artists whose careers occur after 1951 will be documented in other initiatives like The Nelson-Atkins Museum of Art Artists File Initiative or the Kansas City Art Institutes institutional archives.</p>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header-about" id="heading03">
                <h4 className="mb-0" id="how-long-do-you-have-to-practice-in-missouri-to-be-considered-a-missouri-artist">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse03" aria-expanded="false" aria-controls="collapse03">
                    How long do you have to practice in Missouri to be considered a Missouri artist?
                    </button>
                </h4>
                </div>
                <div id="collapse03" className="collapse" aria-labelledby="heading03" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <p>This is a complicated question and one that the project team discussed often. Within the scope of this project a Missouri artist is someone who can be documented to have practiced in the state for at least 3 years. This definition has many implications for determining which artists are included in the portal. An artist like Donald Judd, who was born in Excelsior Springs, Missouri, but was not active until he began studying painting at Columbia in NYC in 1947, is not included. Similarly, most artists who worked on artistic projects throughout the state as part of the FSA WPA may or may not be included in the portal depending on how long they were in the state and the significance of the work they created.</p>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header-about" id="heading04">
                <h4 className="mb-0" id="is-any-artist-eligible-for-inclusion">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse04" aria-expanded="false" aria-controls="collapse04">
                    Is any artist eligible for inclusion?
                    </button>
                </h4>
                </div>
                <div id="collapse04" className="collapse" aria-labelledby="heading04" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <p>The initial 300 artists who were selected for inclusion in the portal were selected from the artist files or archival materials at each of the project partner institutions: St. Louis Public Library, Kansas City Art Institute and The Nelson-Atkins Museum of Art. One goal of the portal is to tell the stories of artists who have been marginalized or erased from the broader story of art and culture in Missouri, including People of Color and women. In the initial artist selections these artists were prioritized with input from the project’s advisory board. Artists included in the portal are each researched and generally only artists that the research team was able to locate at least three sources on are included in the portal. Artists who the research team cannot find a minimum of information on will be included in our project inventory spreadsheet. If you can help us find additional information on any of the artists we would appreciate your help so we can include them in the portal. The project team acknowledges that many of the sources that we rely on for our research of Missouri artists including newspapers, art magazines and journals, and even our own collections often exclude the artistic achievements of oppressed groups including Black, Indigenous and People of Color (BIPOC) and women. As part of our efforts to broaden the historical record and share the stories of underrepresented artists in Missouri the project team will review these artists with the assistance of our advisory board, making every effort to include information about their careers in the portal.</p>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header-about" id="heading05">
                <h4 className="mb-0" id="can-i-submit-an-artist-for-inclusion">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse05" aria-expanded="false" aria-controls="collapse05">
                    Can I submit an artist for inclusion?
                    </button>
                </h4>
                </div>
                <div id="collapse05" className="collapse" aria-labelledby="heading05" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <p>Anyone can submit an artist for consideration. Any artists included in the portal must have information documenting their career in a publicly accessible Missouri Institution. Use the <a href="https://nelson-atkins.org/contact/#library" target="_blank"><u>Contact Us</u></a> link for more information.</p>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header-about" id="heading06">
                <h4 className="mb-0" id="why-arent-all-races-included-in-the-portal">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse06" aria-expanded="false" aria-controls="collapse06">
                    Why aren’t all races included in the portal?
                    </button>
                </h4>
                </div>
                <div id="collapse06" className="collapse" aria-labelledby="heading06" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <p>One of the goals of the <em>Missouri Remembers</em> portal is to broaden our understanding of the diverse artists who worked in Missouri. As the database grows we hope that the diversity of the artists included in the portal will too. When using drop-down filters under demographics the only racial categories that will appear are those associated with artists in the portal. To allow for the discovery of the race of artists included in the portal we have used US Census race categories to inform our search options: White, Black, American Indian, Asian, and Pacific Islander. In addition to these categories, we have added Mixed Race which will be used when we find documentation that acknowledges multiple racial backgrounds. We have also added Hispanic. Although people of any race may identify as Hispanic, since ethnicity isn’t captured anywhere else in the portal the decision was made to include this ethnic description in race to facilitate discovery of artists who identified as Hispanic. Researchers may learn of an artist’s race through other sources but the primary source for racial data is the US Census. The racial categories that we are using in this portal may not align with the historic description of race and ethnicity that we find in the actual census record, in those cases the research team will translate historical description of race and use the current race descriptors mentioned above.</p>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header-about" id="heading07">
                <h4 className="mb-0" id="how-does-missouri-remembers-acknowledge-anonymous-makers-especially-native-americans-and-indigenous-peoples-of-missouri">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse07" aria-expanded="false" aria-controls="collapse07">
                    How does <em>Missouri Remembers</em> acknowledge anonymous makers, especially Native Americans and Indigenous Peoples of Missouri?
                    </button>
                </h4>
                </div>
                <div id="collapse07" className="collapse" aria-labelledby="heading07" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <p>We acknowledge that art and culture did not begin in Missouri at the declaration of statehood and that Native American Peoples have lived in and cared for the land long before European settlers arrived. Missouri has been home to many Native peoples including most recently members of the Missouria, Oto, Kansa, Osage, Shawnee and Delaware tribes. The <em>Missouri Remembers</em> project is a database which relies on individual fields that contain information like an artist’s name, year of birth and location that they practiced. It was designed to document individual artists and is not well-suited to document the works of anonymous makers. The project team will make every effort to include Native artists and anonymous makers and will prioritize indigenous stories and oral histories when possible.</p>
                    <p>“Measuring Race and Ethnicity Across the Decades: 1790—2010 - U.S. Census Bureau.” Accessed March 30, 2021. <a href="https://www.census.gov/data-tools/demo/race/MREAD_1790_2010.html" target="_blank"><u>https://www.census.gov/data-tools/demo/race/MREAD_1790_2010.html</u></a>.</p>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header-about" id="heading08">
                <h4 className="mb-0" id="who-is-responsible-for-missouri-remembers">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse08" aria-expanded="false" aria-controls="collapse08">
                    Who is responsible for Missouri Remembers?
                    </button>
                </h4>
                </div>
                <div id="collapse08" className="collapse" aria-labelledby="heading08" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <p>Missouri Remembers is a collaborative project between The Nelson-Atkins Museum of Art, The Kansas City Art Institute and the St. Louis Public Library. The partners first started to work together on this project in late 2018 as an endorsed project of Missouri’s Bicentennial Commission. Initially the project partners worked from their institutions’ artists files or archival materials to research the artists and create artists records. After the launch of the portal in 2021 the project will be opened to contributing institutions from across the state.</p>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header-about" id="heading09">
                <h4 className="mb-0" id="how-was-this-resource-funded">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse09" aria-expanded="false" aria-controls="collapse09">
                    How was this resource funded?
                    </button>
                </h4>
                </div>
                <div id="collapse09" className="collapse" aria-labelledby="heading09" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <p>This project was generously funded in its planning phase through a grant from the Missouri Humanities Council in 2020 and through a National Endowment for the Humanities: Humanities Collections and Reference Resources grant. NEH funding supported portal creation and the hiring of research interns at each of the project partner institutions.</p>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header-about" id="heading10">
                <h4 className="mb-0" id="how-do-i-cite-this-resource">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                    How do I cite this resource?
                    </button>
                </h4>
                </div>
                <div id="collapse10" className="collapse" aria-labelledby="heading10" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <p>Individual artist records and landing pages include a cite button that will create a bibliographic reference for you. To cite the entire portal, use the citation:</p>
                    <blockquote>
                    <p><em>Missouri Remembers: Artists in Missouri through 1951</em>. Kansas City: The Kansas City Art Institute and The Nelson-Atkins Museum of Art; St. Louis: The St. Louis Public Library, 2021, <a href="https://doi.org/10.37764/5776" target="_blank">https://doi.org/10.37764/5776</a>.</p>
                    </blockquote>
                    <p>To cite a specific entry:</p>
                    <blockquote>
                    <p>Researcher (last name, first name). “Artist.” In <em>Missouri Remembers: Artists in Missouri through 1951</em>. Kansas City: The Kansas City Art Institute and The Nelson-Atkins Museum of Art; St. Louis: The St. Louis Public Library, 2021, <a href="https://doi.org/10.37764/5776" target="_blank">https://doi.org/10.37764/5776</a>.</p>
                    </blockquote>
                    <p><em>Updated On</em> - this field is updated by the project team when new information is added, content on a page is updated or content is removed. This date will not be changed if an update is to correct a typo, grammatical error or is administrative in nature.</p>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header-about" id="heading11">
                <h4 className="mb-0" id="what-if-i-have-additional-information-or-a-correction">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                    What if I have additional information or a correction?
                    </button>
                </h4>
                </div>
                <div id="collapse11" className="collapse" aria-labelledby="heading11" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <p>Please use the project’s <a href="https://nelson-atkins.org/contact/#library" target="_blank"><u>Contact Us</u></a> link to contact the project team.</p>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header-about" id="heading12">
                <h4 className="mb-0" id="what-information-is-available-through-the-missouri-remembers-portal">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                    What information is available through the Missouri Remembers portal?
                    </button>
                </h4>
                </div>
                <div id="collapse12" className="collapse" aria-labelledby="heading12" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <h5 id="artist-entries">Artist Entries</h5>
                    <p>Artist entries include basic information about each artist included in the portal, like when they lived, where they were active, their gender and race, where they were educated and organizations they participated in. These pages also include biographies that focus on the artist’s time in Missouri and often includes examples of their work, awards or exhibitions, relationships they had with other artists and sources to learn more about the artist.</p>
                    <h5 id="landing-pages">Landing Pages</h5>
                    <p>Landing pages include information on institutions, organizations, and exhibitions that artists included in the portal were associated with. In addition to providing information these pages also connect the artists who participated in or were members of these entities.</p>
                    <h5 id="images">Images</h5>
                    <p>Examples of an artist’s work and their portrait are included in the portal to extend its reach and to make these artists and their work more accessible to a general audience. Many of the works used in artist entries and to illustrate landing pages are in the public domain. For images that are not in the public domain the project team is making use of fair use criteria based on the educational, scholarly, research potential and freely accessible nature of the Missouri Remembers portal. Images will also be captioned using guidelines established by the College Art Association for use in their publication, <em>The Art Bulletin</em>. If you are a rights holder and would like to request further information about how an image is used in the portal, that the caption information be corrected, or you do not feel that the use of this image in the Missouri Remembers portal is supported by Fair Use please use the <a href="https://nelson-atkins.org/contact/#library" target="_blank"><u>Contact Us</u></a> link.</p>
                    <p>“The Art Bulletin | Publications | CAA.” Accessed March 30, 2021. <a href="https://www.collegeart.org/publications/art-bulletin/preparation" target="_blank"><u>https://www.collegeart.org/publications/art-bulletin/preparation</u></a></p>
                    <h5 id="search-by-location">Search by Location</h5>
                    <p>You can discover where an artist was active by searching for a specific city or county within the state. To browse or search by location you can use the map of the state at the bottom of the home page or select location from the header.</p>
                    <h5 id="data-set">Data Set</h5>
                    <p>Use the <a href="https://nelson-atkins.org/contact/#library" target="_blank"><u>Contact Us</u></a> link to request a copy of the project’s data set.</p>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header-about" id="heading13">
                <h4 className="mb-0" id="how-can-i-use-missouri-remembers-in-my-classroom">
                    <button className="btn btn-link text-left collapsed" type="button" data-toggle="collapse" data-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                    How can I use Missouri Remembers in my classroom?
                    </button>
                </h4>
                </div>
                <div id="collapse13" className="collapse" aria-labelledby="heading13" data-parent="#accordionAboutPg">
                <div className="card-body">
                    <p>Educator Resource Staff from the Nelson-Atkins Museum of Art created a Teacher Resource Guide that offers a starting point for using the portal with students. The guide is best suited to middle and high school grades and has particular relevance for art and social studies classes. The guide’s teaching objectives include learning how to use a web-based research tool, gaining familiarity with Missouri artists, and examining connections between artists and their historical contexts.</p>
                    <p>Access the Teaching Resource Guide:<br/>
                        <a href="https://nelson-atkins.org/wp-content/uploads/2021/08/Teacher-Resource-Guide-Missouri-Remembers.pdf" target="_blank"><u>https://nelson-atkins.org/wp-content/uploads/2021/08/Teacher-Resource-Guide-Missouri-Remembers.pdf</u></a></p>
                </div>
                </div>
            </div>
        </div>
        <h2 id="acknowledgements">Acknowledgements</h2>
        <h3 id="project-contributors">Project Contributors</h3>
        <p>Susan Craig, retired art librarian and author of <em>Biographical Dictionary of Kansas Artists (active before 1945)</em></p>
        <p>Sam Blain, independent researcher of Texas and Missouri artists</p>
        <p>Emily Leck, Development Director, Grants &amp; Foundations Support, The Nelson-Atkins Museum of Art</p>
        <p>Shelly Xiong, Software Developer, The Nelson-Atkins Museum of Art</p>
        <p>Michael Sweeney, Coordinator, Bicentennial, The State Historical Society of Missouri</p>
        <p>Anne Manning, Director, Education and Interpretation, The Nelson-Atkins Museum of Art</p>
        <p>Suzy Frechette, retired Manager and Senior Subject Specialist, Fine Arts Department, St. Louis Public Library</p>
        <h3 id="advisory-board-members">Advisory Board Members</h3>
        <p>Stephanie Fox Knappe – Samuel Sosland Curator, American Art, The Nelson-Atkins Museum of Art</p>
        <p>Madeline Rislow – Assistant Professor and Director of Art History, Missouri Western State University</p>
        <p>Carmaletta Williams – Executive Director, The Black Archives of Mid-America in Kansas City, Inc.</p>
        <h3 id="project-team">Project Team</h3>
        <p><strong>Project Leads</strong></p>
        <p>Amelia Nelson, <em>Project Director</em>, Head, Library and Archives, The Nelson-Atkins Museum of Art</p>
        <p>Amanda Harlan, <em>Project Manager</em>, Digital Librarian, The Nelson-Atkins Museum of Art</p>
        <p><strong>Partners and Researchers</strong></p>
        <p>Lora Farrell, <em>Site Supervisor</em>, Catalog and Digital Services Librarian, The Kansas City Art Institute</p>
        <p>M.J. Poehler, <em>Site Supervisor</em>, Jannes Director of Library, The Kansas City Art Institute</p>
        <p>Lencia Beltran, <em>Research Intern</em>, The Kansas City Art Institute (September 2020 - December 2020)</p>
        <p>Elinore Noyes, <em>Research Intern</em>, The Kansas City Art Institute</p>
        <p>Sarah Biggerstaff, <em>Project Researcher</em>, The Nelson-Atkins Museum of Art (September 2020 - February 2021)</p>
        <p>Sydney Breakfield, <em>Project Researcher</em>, The Nelson-Atkins Museum of Art (October 2020 - March 2021)</p>
        <p>Roberta Wagener, <em>Project Researcher</em>, Specialist, Access Services, The Nelson-Atkins Museum of Art</p>
        <p>Christain Hartman, <em>Project Researcher</em>, Archives Assistant, The Nelson-Atkins Museum of Art</p>
        <p>Alan McDermott, <em>Editor</em>, Spencer Art Reference library volunteer and project copy editor, The Nelson-Atkins Museum of Art</p>
        <p>Tracey Boswell, <em>Project Researcher,</em> former Senior Librarian, Reference and Cataloging, The Nelson-Atkins Museum of Art (2018 - October 2020)</p>
        <p>Adele Heagney, <em>Site Supervisor</em>, Subject Specialist, St. Louis Room, St. Louis Public Library</p>
        <p>Kristina Impastato, <em>Site Supervisor</em>, Senior Subject Specialist Fine Arts/Steedman Library, St. Louis Public Library</p>
        <p>Jacob Blumenfeld, <em>Site Supervisor and Project Researcher</em>, Fine Arts Librarian/Steedman Library, St. Louis Public Library</p>
        <p>John Knuteson, <em>Research Intern</em>, St. Louis Public Library</p>
        <p><strong>Website Design and Development</strong></p>
        <p>Duane Degler, Project Lead, Principal Consultant, Design for Context</p>
        <p>Charlie Butcosk, Data and Technology Architect, Design for Context</p>
        <p>Lisa Battle, Design Lead, Principal Consultant, Design for Context</p>
        <p>Brian White, Visual Designer, Design for Context</p>
        <p>Rachel Sengers, Interaction Designer, Design for Context</p>
        <p>Gabe Arronte, Front-end Developer, Design for Context</p>
        <p>Dan Eckam, Front-end Developer, Design for Context</p>
        </div>
    )
}

export { AboutPage };
