import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.js';
import SearchBar from './components/searchBar';
import { LocationLandingView, OrganizationLandingView } from './views';
import {RelationshipsTab} from './relationships';

import { SearchConfigContext, SearchConfigProvider } from './context/searchConfigContext';

import 'core-js/stable'
import 'regenerator-runtime/runtime';

import '../css/main.css';
import '../css/main.scss';

window.addEventListener('DOMContentLoaded', (event) => {

    if (document.getElementById('appRoot')) {
        ReactDOM.render(<App {...appRoot.dataset} />, document.getElementById('appRoot'));
    }

    if (document.getElementById('relationshipsTabRoot')) {
        let dataset = document.getElementById('relationshipsTabRoot').dataset;
        let endpointPath = dataset.indexName ? `${dataset.indexName}/_search`: 'entity/_search' 
        let endpointUrl = new URL(endpointPath, dataset.endpoint);

        ReactDOM.render(<RelationshipsTab {...relationshipsTabRoot.dataset} endpoint={endpointUrl.href} associatedPeople={JSON.parse(relationshipsTabRoot.dataset.associatedPeople?.replace(/'/g, '"') ?? '[]' ) } activeLocations={JSON.parse(relationshipsTabRoot.dataset.activeLocations.replace(/'/g, '"')) } />, document.getElementById('relationshipsTabRoot'));
    }
    
    // duplicate content for print version:
    if (document.getElementById('relationshipsTabPrintRoot')) {
        let dataset = document.getElementById('relationshipsTabPrintRoot').dataset;
        let endpointPath = dataset.indexName ? `${dataset.indexName}/_search`: 'entity/_search' 
        let endpointUrl = new URL(endpointPath, dataset.endpoint);

        ReactDOM.render(<RelationshipsTab {...relationshipsTabPrintRoot.dataset} endpoint={endpointUrl.href} associatedPeople={JSON.parse(relationshipsTabPrintRoot.dataset.associatedPeople?.replace(/'/g, '"') ?? '[]' )} activeLocations={JSON.parse(relationshipsTabPrintRoot.dataset.activeLocations.replace(/'/g, '"')) } />, document.getElementById('relationshipsTabPrintRoot'));
    }
    
    if (document.getElementById('searchBarRoot')) {
        let dataset = document.getElementById('searchBarRoot').dataset;
        let endpointPath = dataset.indexName ? `${dataset.indexName}/_search`: 'entity/_search' 
        let endpointUrl = new URL(endpointPath, dataset.endpoint);

        ReactDOM.render(<SearchBar {...searchBarRoot.dataset} endpoint={endpointUrl.href} searchBarClassName="hero_search-container" />, document.getElementById('searchBarRoot'));
    }

    if ( document.getElementById('locationLandingViewRoot') ) {
        let dataset = document.getElementById('locationLandingViewRoot').dataset;
        let endpointPath = dataset.indexName ? `${dataset.indexName}/_search`: 'entity/_search' 
        let endpointUrl = new URL(endpointPath, dataset.endpoint);

        ReactDOM.render(<LocationLandingView {...locationLandingViewRoot.dataset} endpoint={endpointUrl.href} />, document.getElementById('locationLandingViewRoot'));
    }

    if ( document.getElementById('organizationLandingViewRoot') ) {

        let dataset = document.getElementById('organizationLandingViewRoot').dataset;
        let endpointPath = dataset.indexName ? `${dataset.indexName}/_search`: 'entity/_search' 
        let endpointUrl = new URL(endpointPath, dataset.endpoint);

        ReactDOM.render(<OrganizationLandingView {...dataset} endpoint={endpointUrl.href} />, document.getElementById('organizationLandingViewRoot'));
    }

});
