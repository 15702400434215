import React, {useRef, useEffect, useState} from 'react';
import * as d3 from "d3";
import { textWrap } from './util.js';

function TimelineVisualization(props) {

    const container = useRef(null);
    const [ animating, setAnimating ] = useState(false);    // helps w. transition after user clicks an era
    const [ defs, setDefs ] = useState(null);   // svg defs used with clip mask
    const [ count, setCount ] = useState(props.count);
    const [ filters, setFilters ] = useState(props.filters);
    const animMs = 450;
    const minMaxYearRef = useRef([props.minYear, props.maxYear]);
    const filtersRef = useRef(props.filters);
    const countRef = useRef(props.count);

    useEffect(() => {
        // console.log('updating filters');
        // console.log(props.filters);
		setFilters(props.filters);
        filtersRef.current = props.filters;
    }, [props.filters]);

    useEffect(() => {
        if (props.resetTrigger) {
    		props.setResetTrigger(false);
            // set the 'View n Artists' label
            // do it twice, in case we're animating:
            setViewArtistsLabelText();
            setTimeout(() => {
                setViewArtistsLabelText();
            }, animMs + 10);
            // deselect eras - bars & labels
            d3.selectAll(".eraGroup .timeline-yearBars").classed("active", false);
            d3.selectAll(".eraGroup .timeline-era-label").classed("active", false);
        }
    }, [props.resetTrigger]);

    useEffect(() => {
        // Fires when our datasource changes, or when our container changes (eg, goes from undefined to defined)
        if (props.dataSource !== null && props.dataSource.length > 0 && container !== null && container.current !== null) {
            drawChart();
        }
    }, [props.dataSource, container]);

    useEffect(() => {
        setupScales();
        setupEras(true);
    }, [props.erasSet]);

    useEffect(() => {
        console.log('count chgd to ' + props.count + '; x? ' + (x ? 'yes' : 'no') + '; filts? ' + (props.filters ? 'yes' : 'no'));
        countRef.current = props.count;
        setCount(props.count);
        animateSelection();
        // do it twice, in case we're animating:
        // setViewArtistsLabelText(props.count);
        // setTimeout(() => {
        //     setViewArtistsLabelText(props.count);
        // }, animMs + 10);
    }, [props.count]);

    useEffect(() => {
        // do it twice, in case we're animating:
        setViewArtistsLabelText();
        setTimeout(() => {
            setViewArtistsLabelText();
        }, animMs + 10);
    }, [count]);

    useEffect(() => {
        // console.log('updating min/max years to ' + props.minYear + ' - ' + props.maxYear);
        minMaxYearRef.current = [props.minYear, props.maxYear];
    }, [props.minYear, props.maxYear]);

    useEffect(() => {
        if (props.highlightEra) {
            highlightSpecifiedEra(props.highlightEra.erasSet, props.highlightEra.eraIdx)
            props.setHighlightEra(null);
        }
    }, [props.highlightEra]);

    const width = 1000;
    const height = 400;
    const margin = { left: 40, right: 40, top: 10, bottom: 60 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const chart_height = innerHeight * .25;
    const eras_height = innerHeight * .75;
    const fenceCircleR = 13;
    const selectionLabelHeight = 30;
    const erasYearTextY = eras_height + 28;
    const eraYOffset = 45;
    const eraYOffsetInc = 70;

    const xValue = d => {
        return parseInt(d.key_as_string.substring(0,4));
    };

    const yValue = d => {
        return d.doc_count;
    };

    // kludgey: x0 and y0 are defined the same as x & y (scale functions) but have higher-level scope
    // needed by doSvgSizing() to reposition text
    let x0 = d3.scaleLinear()
            .domain(d3.extent(props.dataSource, xValue))
            .range([0, innerWidth]);

    let y0 = d3.scaleLinear()
            .domain([0, d3.max(props.dataSource, yValue)])
            .nice()
            .range([chart_height, 0]);

    let x, y, xAxis, yAxis;

    const isMobileMode = () => {
        const svg = d3.select(container.current);
        const svgwidth = svg.node().getBoundingClientRect().width;
        return (svgwidth < 576);
    }

    const areaGenerator = d3.area()
            .x(d => x(xValue(d)))
            .y0(chart_height)
            .y1(d => y(yValue(d)));

    function pxToYear(pt) {
        return Math.round(x.invert(pt));
    }

    function dragHandlerEnd(event) {
        // console.log('dragHandlerEnd');
        setViewArtistsLabelText();
        setTimeout(() => {
            setViewArtistsLabelText();
        }, animMs + 10);
    }

    // WARNING: because of the D3 .call used w. this function, it's exceptionally prone to stale data, hence the passing of funcs as params
    // and the use of ref.current
    function dragHandler(event) {
        // console.log('dragHandler');
        // console.log(filtersRef.current);
        if (this.classList.contains("leftFence")) {
            const newLeftSelection = Math.max(minMaxYearRef.current[0], Math.min(minMaxYearRef.current[1], pxToYear(event.x - margin.left)));
            // console.log('newLeftSelection=' + newLeftSelection);
            if (filtersRef.current && newLeftSelection !== filtersRef.current.active_begin && newLeftSelection < filtersRef.current.active_end) {
                setAnimating(false);
                props.setIsSelectedEra(false);
                props.setSelectedEra(-1);
                // this will have cascading Effects to update filters/filtersRef
                props.setFilters(filters => {
                    return filters
                        ? {...filters, active_begin: newLeftSelection}
                        : {active_begin: newLeftSelection, active_end: minMaxYearRef.current[1]};
                });
            }
        } else {
            const newRightSelection = Math.max(minMaxYearRef.current[0], Math.min(minMaxYearRef.current[1], pxToYear(event.x - margin.left)));
            // console.log('newRightSelection=' + newRightSelection);
            if (filtersRef.current && newRightSelection !== filtersRef.current.active_end && newRightSelection > filtersRef.current.active_begin) {
                setAnimating(false);
                props.setIsSelectedEra(false);
                props.setSelectedEra(-1);
                // this will have cascading Effects to update filters/filtersRef
                props.setFilters(filters => {
                    return filters
                        ? {...filters, active_end: newRightSelection}
                        : {active_begin: minMaxYearRef.current[0], active_end: newRightSelection};
                });
            }
        }
    }

    // ======== FENCES ============================================
    const fence = (g) => {
        /* Creates a simple circle-rect-text fence, w/ alternating arrows and class names */
        g.attr("class", (d, i) => { 
                return (i === 0) ? "leftFence fence-slider" : "rightFence fence-slider";
            })
            .attr("transform", (d, i) => {
                return `translate(${margin.left - fenceCircleR + x(d + props.minYear)},${chart_height})`;
            })
            .style('cursor', 'pointer');

        // add vertical line (covered until draggables are hidden)
        g.append('rect')
            .attr('class', 'timeline-slider-static-line')
            .attr("x", 0)
            .attr("y", -chart_height)
            .attr("width", 1)
            .attr("height", innerHeight + fenceCircleR)
            .attr("fill", "#666")
            .attr('visibility', 'hidden')
            .style('pointer-events', 'none');

        // add vertical rect
        g.append('rect')
            .attr('class', 'draggable')
            .attr("x", 0)
            .attr("y", -chart_height)
            .attr("width", 5)
            .attr("height", innerHeight)
            .attr("fill", "#7edce2");

        // append circle after rect so it's on top:
        g.append('circle')
            .attr('class', 'draggable')
            .attr('cx', 3)
            .attr('cy', eras_height)
            .attr('r', fenceCircleR)
            .attr('fill','#7edce2');

        // arrow lines:
        g.append("line")    // horizontal part of arrow
            .attr('class', 'draggable')
            .attr("x1", -4)
            .attr("x2", 9)
            .attr("y1", eras_height)
            .attr("y2", eras_height)
            .attr("stroke", "#666")
            .attr("stroke-width", "1.5px");
        g.append("line")    // top angled part of arrow
            .attr('class', 'draggable')
            .attr("x1", 2)
            .attr("x2", (d, i) => { return (i === 0) ? 10 : -5; })
            .attr("y1", eras_height-7)
            .attr("y2", eras_height)
            .attr("stroke", "#666")
            .attr("stroke-width", "1.5px");
        g.append("line")    // bottom angled part of arrow
            .attr('class', 'draggable')
            .attr("x1", 2)
            .attr("x2", (d, i) => { return (i === 0) ? 10 : -5; })
            .attr("y1", eras_height+7)
            .attr("y2", eras_height)
            .attr("stroke", "#666")
            .attr("stroke-width", "1.5px");

        // add label w. current year for this fence
        g.append("text")
            .attr("x", 3)
            .attr("y", erasYearTextY)
            .attr("class", "timeline-fence-text")
            .attr("id", function(d, i) { 
                return ( i === 0 ) ? "leftFenceText" : "rightFenceText";
            })
            .text((d, i) => { return d + props.minYear; });

        g.call(d3.drag()
            .on("start", function() {
                d3.select(this).attr("stroke", "black");
                // deselect eras - bars & labels
                d3.selectAll(".eraGroup .timeline-yearBars").classed("active", false);
                d3.selectAll(".eraGroup .timeline-era-label").classed("active", false);
            })
            .on("drag", dragHandler)
            .on("end", function(event) {
                d3.select(this).attr("stroke", null);
                dragHandlerEnd(event);
            })
        );
    }

    function setupSelectionFences() {
        const svg = d3.select(container.current);
        const fencesNode = svg.select('.timeline-selection-fence');

        if (!noChildren(fencesNode)) { return; }

        fencesNode.selectAll("g")
            .data([props.filters?.active_begin || props.minYear, props.filters?.active_end || props.maxYear])
            .join(
                enter => { return enter.append("g").call(fence); },
                exit => exit.remove()
            );
    }

    function setupScales() {
        if (props.dataSource.length === 0 || container === null) { return; }

        x = d3.scaleLinear()
            .domain(d3.extent(props.dataSource, xValue))
            .range([0, innerWidth]);

        y = d3.scaleLinear()
            .domain([0, d3.max(props.dataSource, yValue)])
            .nice()
            .range([chart_height, 0]);
    }

    /*
     React prefers to manage DOM nodes so we use d3 for math and node management and just redraw on data changes

     So we have two kinds of d3 funcs:
        - Some basic setup() / draw() funcs that use d3 for node creation but do nothing if their setup / draw nodes already exist
        - Some animation() funcs that use selectors to manipulate the nodes according to data changes 
    */

    function drawChart() {
        // console.log(props.dataSource);
        setupScales();
        setupAxes();
        setupArea();
        setupSelectionFences();
        setupEras();
        animateSelection();
    }

    // presumes x, if w >= 0
    function getViewButtonWidthAwareText(ct, w=-1) {
        const bw = (w < 0)
            ? x(filtersRef.current.active_end || minMaxYearRef.current[1]) - x(filtersRef.current.active_begin || minMaxYearRef.current[0])
            : w;
        // console.log('buttonWidth: ' + bw);
        return bw >= 140
            ? `View ${ct} Artists`
            : (bw >= 105    // shortened versions when not much width
                ? `${ct} Artists`
                : `${ct}`);
    }

    // returns label text for the blue rectangle that goes with each era
    function getViewButtonText(ct) {
        if (!x) {
            // using estimate of button width here:
            return getViewButtonWidthAwareText(ct, (filtersRef.current.active_end - filtersRef.current.active_begin) * 4.6);
        } else {
            console.log('getting width aware text');
            return getViewButtonWidthAwareText(ct);
        }
    }

    function adjustViewButtonText(t, text, ct) {
        if (!x || !t) { return; }

        // shorten the text if necessary to fit
        // const svg = d3.select(container.current);
        // const svgwidth = svg.node().getBoundingClientRect().width;
        // const textXFactor = textZoomFactor(svgwidth);
        // const specialXFactor = 1.15; // because I don't think the letter-spacing is accounted for otherwise
        // const buttonWidth = x(props.filters?.active_end || props.maxYear) - x(props.filters?.active_begin || props.minYear);
        // const buttonPadding = 24;
        // let buttonText = text;
        // let doneReplacing = false;
        // const paddedButtonWidth = buttonWidth - buttonPadding;
        // const node = t.node();
        // while (node && !doneReplacing && node.getComputedTextLength() * textXFactor * specialXFactor > paddedButtonWidth) {
        //     if (buttonText.length < 5) {
        //         buttonText = ct;   // just the number when there's no space for more
        //         doneReplacing = true;
        //     } else {
        //         // use ellipses to reduce text width: replace last 4 chars with '...'
        //         buttonText = buttonText.substring(0, Math.max(0, buttonText.length-4)) + '...';
        //         // num chars reduced by 1; assume that the computed length is also reduced
        //     }
        //     t.text(buttonText);
        // }
        const buttonText = getViewButtonWidthAwareText(ct);
        t.text(buttonText);
    }

    function setViewArtistsLabelText() {
        // console.log('setViewArtistsLabelText: set to ' + count + '; countref: ' + countRef.current);
        if (props.filters) {
            const buttonText = getViewButtonText(countRef.current);
            // console.log('buttonText = ' + buttonText);
            const vgText = d3.selectAll('.timeline-viewartists-button-text');
            if (vgText) {
                vgText.text(buttonText);
                adjustViewButtonText(vgText, buttonText, countRef.current);
            }
        }
    }

    function viewArtistsB(box, fadeIn) {
        const buttonWidth = x(props.filters?.active_end || props.maxYear) - x(props.filters?.active_begin || props.minYear);
        const fadeInDuration = 100;

        if (isNaN(buttonWidth)) { return; }

        if (!noChildren(box)) {
            box.selectAll('g')
                .remove();
        }

        const vg = box.append('g')
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const vgRect = vg.append('rect')
            .attr('x', x(props.filters?.active_begin || props.minYear))
            .attr('y', chart_height + 1)    // adds a wee bit of separation from axis
            .attr('width', buttonWidth)
            .attr('height', 20)
            .attr('class', 'timeline-viewartists-button')
            .style("opacity", fadeIn ? 0 : 1)
            .on('click', (event, d) => {
                let url = `/search?f.dateRange=${[props.filters?.active_begin || props.minYear, props.filters?.active_end || props.maxYear]}`;
                window.location = url;
            });
        if (fadeIn) {
            vgRect.transition().duration(fadeInDuration).style("opacity", 1);
        }

        const svg = d3.select(container.current);
        const svgwidth = svg.node().getBoundingClientRect().width;
        const textXFactor = textZoomFactor(svgwidth);

        const showReset = props.filters?.active_begin > props.minYear || props.filters?.active_end < props.maxYear;
        const resetBX = 18;
        const resetBY = 0;
        const resetBW = 74;
        const resetBH = 20;
        const resetXFactor = 1.15;
        const resetRect = vg.append('rect')
            .attr('x', resetBX)
            .attr('y', resetBY)
            .attr('width', resetBW)
            .attr('height', resetBH)
            .attr('rx', 3)
            .attr('class', 'timeline-viewartists-button')
            .style("opacity", showReset ? 1 : 0)
            .on('click', props.resetYears);

        const resetText = vg.append('text')
            .attr('x', (resetBX + resetBW / 2) / resetXFactor)
            .attr('y', 14)
            .attr('class', `timeline-reset-text`)
            .style('opacity', showReset ? 1 : 0)
            .attr('transform', `scale(${resetXFactor}, 1)`)
            .text('Reset');

        // const mob = isMobileMode();
        const xpos = x(props.filters?.active_begin || props.minYear) + (false ? 0 : (0.5 * (x(props.filters?.active_end || props.maxYear) - x(props.filters?.active_begin || props.minYear))));
        const newX = xpos / textXFactor;

        const vgText = vg.append('text')
            .attr('x', newX)
            .attr('y', chart_height + 15)
            .attr('class', `timeline-viewartists-button-text ${false && buttonWidth < 120 ? 'left-aligned' : ''}`)
            .attr('transform', `scale(${textXFactor}, 1)`)
            .style('opacity', fadeIn ? 0 : 1)
            .text('');
        if (fadeIn) {
            vgText.transition().duration(fadeInDuration).style('opacity', 1);
        }
    }

    function textZoomFactor(svgwidth) {
        return (svgwidth < 576) ?
            Math.min(1.86, 1.75 + (576-svgwidth) * 0.01)
            : ((svgwidth < 768) ?
                    1.1
                    : 1.0);
    }

    // ======== animateSelection
    function animateSelection() {
        const svg = d3.select(container.current);

        if (!x || !y) return;

        // replace the "view <n> artists" blue button
        const viewArtistsBox = d3.select('.timeline-viewartists');
        viewArtistsBox.selectAll('g')
            .remove();

        // if we're animating, then hide selected (blue) area data in preparation for fading it in later
        if (animating) {
            svg.select(".timeline-path-highlighted")
                .attr('visibility', 'hidden');
        } else {
            adjustMask(x(props.filters?.active_begin || props.minYear), x(props.filters?.active_end || props.maxYear));
        }

        // adjust the fences/boundary lines
        const selectionFence = d3.select('.timeline-selection-fence');
        selectionFence.selectAll('g')
            .data([props.filters?.active_begin || props.minYear, props.filters?.active_end || props.maxYear])
                .transition()
                .duration(animating ? animMs : 0)
                .attr("transform", function(d) {
                    return `translate(${margin.left + x(d)},${chart_height})`;
                })
                .on("end", () => {
                    if (animating) {
                        setAnimating(false);
                    }
                    if (props.isSelectedEra) {
                        // console.log('animate: selected era; count = ' + count);
                        props.setIsSelectedEra(false);
                        viewArtistsB(viewArtistsBox, true);
                        adjustMask(x(props.filters?.active_begin || props.minYear), x(props.filters?.active_end || props.maxYear));
                    }
                });

        if (!props.isSelectedEra) {
            // console.log('animate: no selected era');
            viewArtistsB(viewArtistsBox, false);
            // deselect eras - bars & labels
            d3.selectAll(".eraGroup .timeline-yearBars").classed("active", false);
            d3.selectAll(".eraGroup .timeline-era-label").classed("active", false);
        }

        // update fence labels:
        const newLeftYearLabel = (props.filters?.active_begin || props.minYear).toString();
        // abbreviate the right one if too close:
        const rightYear = props.filters?.active_end || props.maxYear;
        let newRightYearLabel = ((props.filters?.active_end || props.maxYear) - (props.filters?.active_begin || props.minYear) <= 4
            ? rightYear % 100
            : rightYear).toString();
        if (newRightYearLabel.length === 1) { newRightYearLabel = '0' + newRightYearLabel; }
        d3.select("#leftFenceText").text(newLeftYearLabel);
        d3.select("#rightFenceText").text(newRightYearLabel);

        doSvgSizing(false);
    }

    /*
        Each of these setup methods:
            - Grabs the svg node
            - Looks for a node by class 
            - Checks if it has children (to avoid double-appending DOM nodes if we're called twice)
            - If not, call the func to append to the graph node
    */

    function noChildren(d3Selection) { 
        return d3Selection.selectChild("*").empty();
    }

    // ======== setupAxes
    function setupAxes() {
        const svg = d3.select(container.current);
        const axisNode = svg.select(".timeline-axis")
            .attr('transform', `translate(${margin.left},${margin.top})`);

        if (!noChildren(axisNode)) { return; }

        const tickValuesForXAxis = props.dataSource
            .map((val, index) => {
                const label = parseInt(val.key_as_string.substring(0,4));
                return label;
            })
            .reduce((
                accumulator,
                currentValue) => {
                    // only include years ending in 0
                    if (currentValue % 10 === 0) { accumulator.push(currentValue); }
                    return accumulator;
                }, []);

        xAxis = (g) => {
            g.attr("transform", `translate(0,${chart_height})`)
                .attr('class', 'timeline-x-axis')
                .call(d3.axisBottom(x)
                    //.tickValues([2,12,22,32,42,52,62,72,82,92,102,112,122,132,142,152,162,172,182])
                    .tickValues(tickValuesForXAxis)
                    .tickFormat(d3.format("d")) // no comma after thousands
                    .tickSize(-innerHeight)
                    .tickSizeInner(eras_height)
                    .tickSizeOuter(0))
                .selectAll(".tick")
                    .attr("stroke-width", ".1px")
                    .attr("stroke", "#C3CFD8")
                    .selectAll('text')
                        .attr("class", "timeline-tick-text")
                        .attr('dy', -eras_height + selectionLabelHeight)
                        .attr('dx', '1.25em');

            g.select('path')
                .style({ 'stroke': 'black', 'fill': 'none', 'stroke-width': '.1px' });
        };

        yAxis = (g) => {
            g.attr("transform", `translate(0,0)`)  // move left 1px?
                .attr('class', 'timeline-y-axis')
                .call(d3.axisLeft(y)
                    //.tickValues([0,10,20])
                    .ticks(3)
                    .tickSize(22)
                    .tickSizeOuter(0))
                .selectAll(".tick")
                    .attr("stroke-width", ".1px")
                    .attr("stroke", "#C3CFD8")
                    .selectAll('text')
                        .attr("class", "timeline-tick-text")
                        .attr('dx', 22)    // was 18
                        .attr('dy', 13);
        };

        axisNode.append("g")
            .call(xAxis);

        axisNode.append("g")
            .call(yAxis);
    }

    // ======== createMask
    function createMask(svg, x, width) {
        // give it a new name every time
        const pathName = 'hlt-clip-'+(Math.floor(Math.random() * 1000000000000).toString());

        setDefs(defs => {
            let d = svg.append("defs");
            // define the clip path
            d.append('clipPath')
                .attr('id', pathName)
                .append('rect')
                    .attr('id', 'clip-mask-rect')
                    .attr("x", x)
                    .attr("y", 0)
                    .attr("width", width)
                    .attr("height", chart_height);
            setDefs(d);
            return d;
        });

        return pathName;
    }

    function applyMask(sel, pathName) {
        if (!defs) {
            console.log('cannot mask item');
            return;
        }

        sel.attr('clip-path', `url(#${pathName})`);
        sel.attr('webkit-clip-path', `url(#${pathName})`);  // for safari
        sel.attr('visibility', 'visible');
    }

    function adjustMask(x0, x1) {
        if (!defs) {
            console.log('cannot adjust mask');
            return;
        }

        // NOTE: for Safari, we can't simply modify the existing mask; we have to remove it & create a new one
        // or at least, we're gonna do it even if (maybe) it's overkill

        const svg = d3.select(container.current);
        svg.select('defs').remove();
        const pathName = createMask(svg, x0, x1-x0);
        let hltCurve = svg.select(".timeline-path-highlighted");
        if (hltCurve) { applyMask(hltCurve, pathName); }
    }

    // ======== setupArea -- draw the main data
    function setupArea() {
        const svg = d3.select(container.current);
        const areaNode = svg.select(".timeline-area")
            .attr('transform', `translate(${margin.left},${margin.top})`);

        if (!noChildren(areaNode)) { return; }

        areaNode.append("path")
            .attr('class', 'timeline-path')
            .attr('d', areaGenerator(props.dataSource));

        // now the highlighted area on top:
        const pathName = createMask(svg, 0, innerWidth);
        const hltCurve = areaNode.append("path")
            .attr('class', 'timeline-path-highlighted')
            .attr('visibility', 'hidden')
            .attr("clip-path", `url(#${pathName})`)
            .attr("webkit-clip-path", `url(#${pathName})`)  // for safari
            .attr('d', areaGenerator(props.dataSource))
            .on('click', (event, d) => {
                const url = `/search?active_begin=${filters?.active_begin || props.minYear}&active_end=${filters?.active_end || props.maxYear}`;
                window.location = url;
            });
        applyMask(hltCurve, pathName);
    }

    // ======== ERAS ===========================================

    function doEraHighlights(el) {
        // highlight the era bar
        const clickedG = el.parentNode;

        // assign class for clicked element, else unassign class
        d3.selectAll(".eraGroup .timeline-yearBars").classed("active", function() {
           return clickedG === this.parentNode;
        });
        // now similarly for the text label:
        d3.selectAll(".eraGroup .timeline-era-label").classed("active", function() {
            return clickedG === this.parentNode;
        });
    }

    function highlightSpecifiedEra(erasSet, eraIdx) {
        // deselect eras - bars & labels
        d3.selectAll(".eraGroup .timeline-yearBars").classed("active", false);
        d3.selectAll(".eraGroup .timeline-era-label").classed("active", false);
        // select specified
        d3.selectAll(`#era-group${erasSet}-bar${eraIdx}`).classed("active", true);
        d3.selectAll(`#era-group${erasSet}-label${eraIdx}`).classed("active", true);
    }

    function eraClicked(event, d) {
        // set up a nonzero-duration transition for the selection update:
        setAnimating(true);
        props.setIsSelectedEra(true);
        props.setSelectedEra(event.currentTarget.getAttribute('eraIdx'));
        props.setFilters({active_begin: props.dateLimited(d.date_first, 0), active_end: props.dateLimited(d.date_last, 1)});
        doEraHighlights(event.currentTarget);
    }

    function eraMouseover(event, d) {
        // make the associated label for the era-bar underlined as if hovering over it
        const parentG = this.parentNode;
        d3.selectAll(".eraGroup .timeline-era-label").classed("hovering", function() {
            return parentG === this.parentNode;
        });
    }

    function eraMouseout(event, d) {
        // remove 'hovering' class from era labels
        d3.selectAll(".eraGroup .timeline-era-label").classed("hovering", false);
    }

    function setupEras(redo = false) {
        const svg = d3.select(container.current);
        const erasNode = svg.select(".timeline-eras")
            .attr('transform', `translate(${margin.left},${margin.top})`);

        if (!noChildren(erasNode) && !redo) { return; }

        if (redo) erasNode.select('g').remove();

        if (!x) { return; }     // need x scale to compute positions & widths

        const erabarHtPx = 22;
        const erabarStrokeColor = "cornflowerblue";

        const eraMarkers = erasNode.append('g')
            .selectAll('rect')
            .data(props.erasCollection[props.erasSet])
            .join('g').attr("class", "eraGroup");

        // const mob = isMobileMode();

        eraMarkers.append("rect")
            .attr("x", d => x(props.dateLimited(d.date_first, 0)))
            .attr("y", d => chart_height + eraYOffset + eraYOffsetInc * d.display_row)
            .attr("height", erabarHtPx)
            .attr("width", d => Math.max(1, x(props.dateLimited(d.date_last, 1)) - x(props.dateLimited(d.date_first, 0))))  // 1px at minimum -- kludge b/c some eras are just 1 year
            .attr("class", "timeline-yearBars")
            .attr("id", (d, i) => `era-group${props.erasSet}-bar${i}`)
            .attr("eraIdx", (d, i) => i)
            .on("mouseover", eraMouseover)
            .on("mouseout", eraMouseout)
            .on("click", (event, d) => eraClicked(event, d));
        eraMarkers.append("line")
            // .attr('class', 'draggable')
            .attr("x1", d => x(props.dateLimited(d.date_first, 0)))
            .attr("x2", d => x(props.dateLimited(d.date_first, 0)))
            .attr("y1", d => chart_height + eraYOffset + eraYOffsetInc * d.display_row)
            .attr("y2", d => chart_height + eraYOffset + eraYOffsetInc * d.display_row + erabarHtPx)
            .attr("stroke", erabarStrokeColor)
            .attr("stroke-width", "1px");
        eraMarkers.append("line")
            // .attr('class', 'draggable')
            .attr("x1", d => x(props.dateLimited(d.date_last, 1)))
            .attr("x2", d => x(props.dateLimited(d.date_last, 1)))
            .attr("y1", d => chart_height + eraYOffset + eraYOffsetInc * d.display_row)
            .attr("y2", d => chart_height + eraYOffset + eraYOffsetInc * d.display_row + erabarHtPx)
            .attr("stroke", erabarStrokeColor)
            .attr("stroke-width", "1px");
        eraMarkers.append("text")
            .attr("dx", d => {
                switch (d.align) {
                    case 'left':
                        return -0.5 * (x(props.dateLimited(d.date_last, 1)) - x(props.dateLimited(d.date_first, 0))) + 2.0;
                    case 'right':
                        return 0.5 * (x(props.dateLimited(d.date_last, 1)) - x(props.dateLimited(d.date_first, 0))) - 2.0;
                    default:
                        return 0;
                }

            })
            .attr("y", d => chart_height + eraYOffset + eraYOffsetInc * d.display_row)
            .text(d => d.name)
            .attr('class', d => `timeline-era-label ${d.align ? d.align + '-align' : ''}`) // FIXME: add 'left-align' and 'right-justified' if the era data calls it ?
            .attr('id', (d, i) => `era-group${props.erasSet}-label${i}`)
            .attr("eraIdx", (d, i) => i)
            .attr('dy', '2.5em')
            .each(function(d) {
                d3.select(this).call(textWrap, (d.maxLabelWidth || 0), 1.0, false, false, `timeline-era-label ${d.align ? d.align + '-align' : ''}`) // FIXME: add 'left-justified' and 'right-justified' if the era data calls it
            })
            .on("click", (event, d) => eraClicked(event, d));

        eraMarkers.attr('opacity', 0.1)
            .transition().duration(400)
            .attr('opacity', 1);

        if (redo) doSvgSizing();
    }

    // ======== handle screen size
    function doSvgSizing(trans = true) {
        const svg = d3.select(container.current);
        const svgwidth = svg.node().getBoundingClientRect().width;
        //const width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const sliderTransitionMs = 250;
        const sliderDeltaY = 180;
        //console.log('svgwidth='+svgwidth);
        // const mob = isMobileMode();

        if (isMobileMode()) {   // hide eras & sliders
            // hide eras
            svg.select('.timeline-eras')
                .attr('visibility', 'hidden');
            // hide sliders
            svg.selectAll('.fence-slider .draggable')
                .attr('visibility', 'hidden');
            // show the static lines to go with the selection-year labels
            svg.selectAll('.fence-slider .timeline-slider-static-line')
                .attr('visibility', 'visible')
                //.transition().duration(trans ? sliderTransitionMs : 0)
                .attr('y', -chart_height - sliderDeltaY);
            // move the year labels up:
            svg.selectAll('.fence-slider .timeline-fence-text')
                //.transition().duration(trans ? sliderTransitionMs : 0)
                .attr('y', erasYearTextY - sliderDeltaY);
            // shrink the view box:
            svg.attr('height', `${height - sliderDeltaY}`);
            svg.attr('viewBox', `0 0 ${width} ${height - sliderDeltaY}`);
        } else {    // normal eras & sliders
            // show eras
            svg.select('.timeline-eras')
                .attr('visibility', 'visible');
            // show sliders
            svg.selectAll('.fence-slider .draggable')
                .attr('visibility', 'visible');
            // hide the static lines
            svg.selectAll('.fence-slider .timeline-slider-static-line')
                .attr('visibility', 'hidden')
                .attr('y', -chart_height);
            // move the year labels down: no animation as long as the draggables aren't
            svg.selectAll('.fence-slider .timeline-fence-text')
                .attr('y', erasYearTextY);
            // full-size view:
            svg.attr('height', `${height}`);
            svg.attr('viewBox', `0 0 ${width} ${height}`);
        }

        // text sizing for all text nodes
        const textXFactor = textZoomFactor(svgwidth);
        svg.selectAll('.timeline-era-label')
            .each(function(d) {
                const oldX = x0(props.dateLimited(d.date_first, 0)) + (false ? 0 : (0.5 * (x0(props.dateLimited(d.date_last, 1)) - x0(props.dateLimited(d.date_first, 0)))));
                const newX = oldX / textXFactor;
                d3.select(this).attr('x', newX)
                    .attr('transform', `scale(${textXFactor}, 1)`);
            });
        svg.selectAll('.timeline-fence-text, .timeline-tick-text')
            .attr('transform', `scale(${textXFactor}, 1)`);

        svg.selectAll('.timeline-viewartists-button-text')
            .each(function(d) {
                // in mobile mode only, using left-aligned text
                const oldX = x0(props.filters?.active_begin || props.minYear) + (false ? 0 : (0.5 * (x0(props.filters?.active_end || props.maxYear) - x0(props.filters?.active_begin || props.minYear))));
                const newX = oldX / textXFactor;
                d3.select(this).attr('x', newX)
                    .attr('transform', `scale(${textXFactor}, 1)`);
            });

        // x-axis:
        if (isMobileMode()) {   // changed x-axis ticks & no tick labels
            // hide tick text
            svg.selectAll('.timeline-x-axis .timeline-tick-text')
                .attr('visibility', 'hidden');
            // set x-axis tick lines to shorter height
            svg.selectAll('.timeline-x-axis .tick line')
                //.transition().duration(trans ? sliderTransitionMs : 0)
                .attr('y2', 60);
        } else {    // normal x-axis ticks & tick labels
            // show tick text
            svg.selectAll('.timeline-x-axis .timeline-tick-text')
                .attr('visibility', 'visible');
            // set x-axis tick lines to regular (tall) height
            svg.selectAll('.timeline-x-axis .tick line')
                .attr('y2', 247.5);
        }
    }
    window.onresize = doSvgSizing;

    return <div style={{width: "100%", height: "100%"}}>
                <svg 
                    className="timeline-viz"
                    viewBox={`0 0 ${width} ${height}`}
                    height={`${height}`}
                    width="100%"
                    preserveAspectRatio="none"
                    ref={container}
                >
                    <g className="timeline-area" />
                    <g className="timeline-axis" />
                    <g className="timeline-eras" />
                    <g className="timeline-viewartists" />
                    <g className="timeline-selection-fence" />
                </svg>
            </div>
}

export { TimelineVisualization };