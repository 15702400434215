import React, { useRef, useEffect, useState } from 'react';

import * as d3 from "d3";

function SummaryView(props) {
	return <div>
	<SummaryVisualization selection={props.selection} filters={props.filters} dataSource={props.dataSource}></SummaryVisualization>
	</div>
}

function SummaryVisualization(props) {
	const container = useRef(null);
	const [selection, setSelection] = useState(props.selection);

	const [selectedYears, setSelectedYears] = useState([props.filters]);
    useEffect(() => {
        setSelectedYears([props.filters]);
		setupData();
    }, [props.filters]);

	useEffect(() => {
		// Fires when our datasource changes, or when our container changes (eg, goes from undefined to defined)
		if (props.dataSource !== null && props.dataSource.length > 0 && container !== null) {
			drawChart();
		}
	}, [props.dataSource, container]);

	let height = 240;
	let width = 240;
	let margin = { left: 20, right: 20, top: 20, bottom: 20 };
    let innerWidth = width - margin.left - margin.right;
    let innerHeight = height - margin.top - margin.bottom;

	function drawChart() {
		setupCirc();
		setupData();
	}

	function noChildren(s) {
		return s.selectChild("*").empty();
	}

	function setupCirc() {
		let svg = d3.select(container.current);
		let circNode = svg.select(".summary-circ")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        if (!noChildren(circNode)) { return; }

		const cx = innerWidth/2;
		const cy = innerHeight/2;
        const triW = 28;
        const triH = 12;
        const triY = 80;

        // drop shadow:
        let defs = svg.append("defs");
        // create filter with id #drop-shadow
        // height=130% so that the shadow is not clipped
        let filter = defs.append("filter")
            .attr("id", "drop-shadow")
            .attr("height", "130%");
        filter.append("feGaussianBlur")
            .attr("in", "SourceAlpha")
            .attr("stdDeviation", 3)
            .attr("result", "blur");
        filter.append("feOffset")
            .attr("in", "blur")
            .attr("dx", 0)
            .attr("dy", 5)
            .attr("result", "offsetBlur");

        let feMerge = filter.append("feMerge");
        feMerge.append("feMergeNode")
            .attr("in", "offsetBlur")
        feMerge.append("feMergeNode")
            .attr("in", "SourceGraphic");

		circNode.append("circle")
			.attr("cx", cx)
			.attr("cy", cy)
			.attr("r", innerWidth/2)
			.attr("class", "vizSummaryCircle")
			.attr("fill", "white")
            .style("filter", "url(#drop-shadow)")
            .style("opacity", "0.2");
        circNode.append("polygon")
            .attr("points", `${cx-triW/2},${cy+triY} ${cx+triW/2},${cy+triY} ${cx},${cy+triY+triH}`)
            .attr("fill", "lightblue")
            .attr("stroke", "none");
	}

	function setupData() {
		let svg = d3.select(container.current);
		let dataNode = svg.select(".summary-data")
            .attr("transform", `translate(${margin.left},${margin.top})`);

		const cx = innerWidth/2;
		const cy = innerHeight/2;

		dataNode.selectAll("text.vizSummaryHeadline")
			.data(props.dataSource)
			.join("text")
                .attr("class", "vizSummaryHeadline")
                .attr("x", cx)
                .attr("y", cy)
                .attr("dy", -2)
                .text((d, i) => d);

        dataNode.selectAll("text.vizSummaryYears")
            .data(selectedYears)
            .join("text")
                .attr("class", "vizSummaryYears")
                .attr("x", cx)
                .attr("y", cy)
                .attr("dy", 40)
                .text((d, i) => `${d.active_begin}-${d.active_end}`);

        dataNode.selectAll("text.vizSummaryAA")
            .data(selectedYears)
            .join("text")
                .attr("class", "vizSummaryAA")
                .attr("x", cx)
                .attr("y", cy)
                .attr("dy", 20)
                .text("Artists Active");

        dataNode.selectAll("text.vizSummaryLink")
            .data(selectedYears)
            .join("text")
                .attr("class", "vizSummaryLink")
                .attr("x", cx)
                .attr("y", cy)
                .attr("dy", 60)
                .text("View Artists")
                .on("click", (event, d) => {
                    let url = `/search#active_begin=${d.active_begin}&active_end=${d.active_end}`;
                    window.location = url;
                });

    }

	return <div style={{width: "240px", height: "240px"}}>
		<svg 
			className="summary-viz"
            viewBox={`0 0 ${width} ${height}`}
			preserveAspectRatio="xMinYMin meet"
			ref={container}
			>
			<g className="summary-circ" />
			<g className="summary-data" />
		</svg>
	</div>

}

export { SummaryView };