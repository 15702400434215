import React, {useState, useEffect} from 'react';
import { MapHeroView } from './map';
import SearchBar from './components/searchBar';
import {useElasticSearch, countQueries, searchBarContextToQuery } from './util';

function LocationHeroView(props) {

    const [ partialLocationString, setPartialLocationString ] = useState(null)
    const [ partialLocationQuery, setPartialLocationQuery ] = useState(null)
    const [ textError, setTextError ] = useState(false);

    const countEndpoint = props.endpoint.replace('_search','_count');

    const orgCount = useElasticSearch(countEndpoint,countQueries.org);
    const exhibCount = useElasticSearch(countEndpoint,countQueries.exhib);
    const artistCount = useElasticSearch(countEndpoint,countQueries.artist);

    const locationCount = useElasticSearch(props.endpoint, partialLocationQuery)

    useEffect(() => {
      if (locationCount) {

        let count = locationCount?.hits?.total?.value ?? 0
        if (count > 0) {
          props.applyFilters({active_location_partial: partialLocationString},[])
        } else {
          setTextError(true)
        }

      }
    },[locationCount])

    const selectionHandler = ({region, county, location, partial}) => {

      if (region) {
        props.applyFilters({region})
      } else if (county) {
        props.applyFilters({county})

      } else if (location) {
        props.applyFilters({active_location: location})        
      }

    }

    const searchBarSelectionHandler = ({partial,town,county}) => {
      if (partial) {

        var query = searchBarContextToQuery(partial, "Location")
        delete query['sort']
        setPartialLocationString(partial)
        setPartialLocationQuery(query) 

      } else if (town) {
        props.applyFilters({active_location: town})        
      } else if (county) {
        props.applyFilters({county: county})
      }

    }

    const clearError = () => { setTextError(false) }

    return <React.Fragment>
            <div className="col-7 p-0 d-none d-lg-block">
              <MapHeroView results={props.results} selectionHandler={selectionHandler} region={"Kansas City"} filters={props.partialFilters} minYear={props.minYear} maxYear={props.maxYear} />
            </div>
            <div className="col-9 col-lg-5">
              <h4 className="location-iconsect-hdg">MISSOURI STATE</h4>
              <div className="location-icon-row">
                <span className="icon-circle icon-circle--white">
                  <span className="icon icon-property-user icon-lg"></span>
                </span>
                <p>{ artistCount?.count ?? 0 } Artists</p>
              </div>
              <div className="location-icon-row">
                <span className="icon-circle icon-circle--white">
                  <span className="icon icon-institution icon-lg"></span>
                </span>
                <p>{ orgCount?.count ?? 0 } Organizations</p>
              </div>
              <div className="location-icon-row">
                <span className="icon-circle icon-circle--white">
                  <span className="icon icon-exhibition icon-lg"></span>
                </span>
                <p>{ exhibCount?.count ?? 0 } Exhibitions</p>
              </div>
              <div className="dropdown mt-3 morem-pr-hide">
                <a
                  className="dropdown-toggle"
                  href="#"
                  id="regionDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  View by Region
                </a>
                <div className="dropdown-menu" aria-labelledby="regionDropdown">
                  {
                    props.results?.aggregations?.active_regions?.buckets?.map( buck => 
                    {
                      return <a className="dropdown-item" href="#" onClick={ () => selectionHandler({region: buck.key.label})}>{`${buck.key.label} (${buck.doc_count})`}</a>
                    }) ?? ''
                  }
                </div>
              </div>
              <p className="small-header text-white mt-3 mb-0 morem-pr-hide">
                LOOK UP A LOCATION
              </p>
              <SearchBar applyFilters={ searchBarSelectionHandler } searchBarType={'facet'} searchBarClassName="location-search-form morem-pr-hide" inputDidFocus={clearError} inputDidBlur={clearError} clearError={clearError} endpoint={props.endpoint} />

              <div className="location-search-form-description morem-pr-hide">
                <p>Type in a Missouri town/city or county</p>
              </div>
              <div className={`filters-error-msg ${textError ? "" : "d-none"}`}>
                No matching Missouri locations found
              </div>
            </div>
          </React.Fragment>
}

export {LocationHeroView}
