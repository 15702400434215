import { useState, useEffect } from 'react';
import * as d3 from "d3";

let paramKeys = { s: 'queryString', sz: 'pageSize', i: 'fromIndex', sort: 'sortType', dir: 'sortDir', rs: 'rootState' }
let focusOrgs = [
"Kansas City Art Institute",
"St. Louis School of Fine Arts, Washington University",
"Kansas City Society of Artists",
"Society of Independent Artists of St. Louis",
"St. Louis Art League",
"St. Louis Artists' Guild",
"Ste. Genevieve Art Colony",
"Art Institute of Chicago",
"Pennsylvania Academy of the Fine Arts"
]

const stateToParams = (state) => {
    /*
    Takes state for our search-fed components and turns it into URL search params so we can bookmark / permalink
    */
    const params = new URLSearchParams();

    for (let [key,value] of Object.entries(state)) {
        // console.log(key,value)
        if (value === undefined) {
            continue
        }

        if (key === "filters" ) {
            if (Object.keys(value).length === 0 ) {
                continue
            }

            for (let [filterKey,filterValue] of Object.entries(value)) {
                params.set(`f.${filterKey}`,filterValue)
            }
        } else {
            let keyForParam = Object.entries(paramKeys).reduce((acc,[paramKey,paramValue]) => { return (paramValue === key) ? paramKey : acc }, false)
            if (keyForParam) {
                params.set(keyForParam,value)
            }
        }
    }

    return params.toString();
}

const paramsToState = (overrideParams=undefined) => {
    /*
    Parses the state encoded in the URL search paramters into a usable state variable for instantiation         
    */

    let params = overrideParams ? new URLSearchParams(overrideParams) : new URLSearchParams(window.location.search);
    const state = {}
    const filters = {}

    // Iterate params, processing "f.*" params as filter values
    for (let [key,value] of params.entries()) {

        if (key.startsWith('f.')) {
            
            let filterKey = key.substring(2)
            switch (filterKey) {
                case 'dateRange':
                    filters[filterKey] = value.split(',').map( val => Number(val) )
                    break;
                default:
                    filters[filterKey] = value
                    break;
            }
        } else {
            let keyForState = paramKeys[key]
            switch (keyForState) {
                case 'fromIndex':
                case 'pageSize':
                    state[keyForState] = Number(value)
                    break
                default:
                    state[keyForState] = value            
                    break
            }
        }

    }

    state.filters = filters
    return state

}

function useUrlParams( queryString, fromIndex, pageSize, filters, sortType, sortDir, querySetter ) {

    // Responds to any change in any of our search-facing params and sets the query, params to match
    let params = stateToParams({queryString, fromIndex, pageSize, filters, sortType, sortDir })
    const url = new URL(window.location.href)
    url.search = params

    // console.log(params)
    // console.log(window.location.search)
    if ( url.search !== window.location.search ) {
        window.history.pushState({params: params, rootState: false},document.title,url.href)
    }


}

function useElasticSearch(endpoint,query) {

    var endpointParsed = new URL(endpoint);
    var endpointHref = endpointParsed.href;

    const headers = new Headers({"Content-Type": "application/json"});

    // Check for user / pass and insert the appropriate header if so  
    const username = endpointParsed.username;
    const password = endpointParsed.password;
    const authHeader = 'Basic ' + btoa(`${username}:${password}`);

    var credentials = false;

    if ( username !== "" && password !== "" ) {
        // FIXME: There should be a nicer way to remove the user / pass..    
        endpointHref = endpointParsed.href.replace(`${username}:${password}`,'');

        headers.append("Authorization", JSON.stringify(authHeader));
        credentials = true;
    }

    const [data, setData] = useState(null);

    async function retrieve(url,body) {

        try {
            const response = await fetch(url, {
                                                method: "POST",
                                                credentials: credentials ? "include" : "same-origin",
                                                mode: 'cors',
                                                headers: headers,
                                                body: JSON.stringify(body) });        
            const json = await response.json();
            setData(json);
    
        } catch(err) {
            alert(`We did not get a proper response from the endpoint! ${err}`)
        }

    }

    useEffect(() => {
        // let url = new URL(endpointHref)

        // // Our search parameters, passed as URL args to a GET request so we can be cached (see https://www.elastic.co/guide/en/elasticsearch/reference/current/common-options.html#_request_body_in_query_string)
        // let params = new URLSearchParams({"source": JSON.stringify(query), "source_content_type": "application/json"})
        // url.search = params;
        if (query !== null ) {
            retrieve(endpointHref,query);        
        }
    },[query]);

    return data;
}

function orgsAggregation(filters) {
    return { filters: {
                filters: {
                    "Kansas City Art Institute": { term: { "organizations.label": "Kansas City Art Institute" }},
                    "Kansas City Society of Artists": { term: { "organizations.label": "Kansas City Society of Artists" }},
                    "Nelson-Atkins Museum of Art": { term: { "organizations.label": "Nelson-Atkins Museum of Art" }},
                    "Saint Louis Art Museum, Washington University": { term: { "organizations.label": "Saint Louis Art Museum, Washington University" }},
                    "St. Louis School of Fine Arts": { term: { "organizations.label": "St. Louis School of Fine Arts" }},
                    "Society of Independent Artists of St. Louis": { term: { "organizations.label": "Society of Independent Artists of St. Louis" }},
                    "St. Louis Art League": { term: { "organizations.label": "St. Louis Art League" }},
                    "St. Louis Artists' Guild": { term: { "organizations.label": "St. Louis Artists' Guild" }},
                    "Ste. Genevieve Art Colony": { term: { "organizations.label": "Ste. Genevieve Art Colony" }},
                }
            },
                    aggs: {
            active: {
                filter: { 
                    range: {
                        "active.date_years": {
                            gte: filters?.active_begin || 1800, //filters['dateRange'][0]
                            lte: filters?.active_end || 2021 // filters['dateRange'][1]                                                
                        }
                    }
                }
            }
        }

    }
}

function termAggregation(field,filters,size=200) {
    /* Just returns an elasticsearch aggregation query node 
    */
    const aggNode = { terms: 
                        { 
                            size: size, 
                            field: `${field}.label`
                        },
                        aggs: {
                            active: {
                                filter: { 
                                    range: {
                                        "active.date_years": {
                                            gte: filters?.active_begin || 1800, //filters['dateRange'][0]
                                            lte: filters?.active_end || 2021 // filters['dateRange'][1]                                                
                                        }
                                    }
                                }
                            }
                        }
                    };

    return aggNode;

}

function compositeTermAggregation(field,filters,label=true,size=200) {
    /* Just returns an elasticsearch aggregation query node 
        FIXME: No need for this to be a composite anymore as it creates skew across same-field properties
    */
    const aggNode = { composite: 
                        { size:size, sources: [ 
                            { label: { terms: { field: `${field}${ label ? '.label' : '' }` } } }, 
                            ] 
                        },
                        aggs: {
                            active: { filter: searchFilterToElasticFilterSyntax(filters) }
                        }
                    };

    return aggNode;

}

function yearsToDecades(buckets) {
    /* Converts an array of year histogram buckets into decades */

    var decades = {};
    for ( let bucket of buckets ) {
        let decade = Math.floor( Number(bucket.key_as_string) / 10 ) * 10;
        let decCount = ( decades[decade] ) ? decades[decade] : 0;

        decades[decade] = decCount + Number(bucket.active.doc_count)
    }

    var result = [];
    for (const [key,value] of Object.entries(decades)) {
        result.push( { key: { label: `${key}s`, value: key }, active: { doc_count: value } } )
    }
    return result;
}

function decadeToYears(bucket) {
    const firstYear = Number(bucket.key.value);
    const lastYear = firstYear + 9;

    return [ firstYear, lastYear ]
}

function focusOrganizationAgggregation(filters) {

    let aggsNode = termAggregation('focus_organization',filters,10);
    aggsNode.terms.include = focusOrgs;

    return aggsNode

}

function timelineAggregations(filters) {
    /*

    Takes first and last dates as numbers and constructs a query to: 
    
    Expects `filters` to be an Object with keys: {active_begin: <Number>, active_end: <Number> }

    Return tne total number of artists in the dataset active in those dates, and facets to support:
        - Location facet map 
        - Bar chart of art media (or roles?)
        - Bar chart of organizations

    */

    return {
            organizations: focusOrganizationAgggregation(filters),
            type_of_art: termAggregation('artistic_medium',filters,10),
            artist_role: termAggregation('artist_role',filters,10),
            active_locations: termAggregation('active.location.region',filters),
            active_years: {
                date_histogram: {
                    field: "active.date_years",
                    calendar_interval: "1y"
                },
                aggs: {
                    active: { filter: searchFilterToElasticFilterSyntax(filters) }
                }
            }
        }
        

}

function searchFacetAggregations(filters) {
    /*
    Returns an aggregation node for an ElasticSearch query, with the filters applied to a sub-aggregation for in-filtered-set counts 

    The twist is that to get proper counting in facet filters each aggregation needs to express the filter conditions on each aggregation
    */
        return {
                ethnicity: compositeTermAggregation('ethnicity',filters),
                gender: compositeTermAggregation('gender',filters),
                organizations: compositeTermAggregation('focus_organization',filters,true,1000),
                type_of_art: compositeTermAggregation('artistic_medium',filters),
                artist_role: compositeTermAggregation('artist_role',filters),
                occupation: compositeTermAggregation('occupation',filters),
                education: compositeTermAggregation('education',filters),
                exhibition_count: {
                    cardinality: {
                        field: "exhibited_at.link",
                    }
                },
                organization_count: {
                    cardinality: {
                        field: "focus_organization.label",
                    }  
                },
                active_regions: compositeTermAggregation('active.location.region',filters),
                active_counties: compositeTermAggregation('active.location.county',filters),
                location: compositeTermAggregation('located_in',filters,true,1200),
                organizer: compositeTermAggregation('carried_out_by',filters,true,1200),
                organizer_authority: compositeTermAggregation('carried_out_by.authority',filters,true,1200),
                active_years: {
                    terms: {
                        field: "active.date_decades",
                        size: 50
                        // calendar_interval: "1y"
                    },
                    aggs: {
                            active: { filter: searchFilterToElasticFilterSyntax(filters) }
                    }
                }
            }
}

function timelineContextToQuery(filters) {
    var query = {   query: { 
                        bool: { 
                            must: [ { term: { display_type: "Artist" } } ]
                        }
                    },
                    size: 0,
                    aggs: timelineAggregations(filters),
                    post_filter: { range: { "active.date_years": { gte: filters?.active_begin ?? 0, lte: filters?.active_end ?? 0 } } } 

                }

    return query
}

function locationContextToQuery(filters) {
    var query = {
                    // query: {
                    //     bool: {
                    //         must: [ { } ] // Should give us the context of all things related to a region (or if nothing, the whole dataset == state), so: active.location.region + (Exhibition's region location binding) + (Organization's region location binding) 
                    //     }
                    // },
                    aggs: {
                        active_regions: termAggregation('active.location.region'),
                        active_counties: termAggregation('active.location.county'),
                        // total_artists: '', // This is a term agg for display_type == "Artist"
                        // total_exhibitions: '',// This is a term agg for display_type == "Exhibition"
                        // total_institutions: '' // This is a term agg for display_type == "Organization"

                    }
    }

    return query
}

function searchFilterToElasticFilterSyntax(filters) {

    /* Filters are used across the query:
        - To create sub-aggregations on facets for cleaner filter facet display logic 
        - In a post_filter block to filter the actual results for paging via from / size
      
      So this just returns an appropriate `bool` query with each filter term as a `must` constraint  
    */
    // console.log(filters)
    let result = { bool: { must: [] } }

    for (const filter in filters) {
        switch (filter) {
            case 'ethnicity':
                result.bool.must.push({ term: { "ethnicity.label": filters[filter] } }) 
                break;
            case 'gender':
                result.bool.must.push({ term: { "gender.label": filters[filter] } })
                break;
            case 'type_of_art':
                result.bool.must.push({ term: { "artistic_medium.label": filters[filter] } })
                break;
            case 'artist_role':
                result.bool.must.push({ term: { "artist_role.label": filters[filter] } })
                break;
            case 'organizations':
                result.bool.must.push({ term: { "focus_organization.label": filters[filter] } })
                break;
            case 'organizer':
                result.bool.must.push({ term: { "carried_out_by.label": filters[filter] } })
                break;
            case 'organizer_authority':
                result.bool.must.push({ term: { "carried_out_by.authority.label": filters[filter] } })
                break;
            case 'education':
                result.bool.must.push({ term: { "education.label": filters[filter] } })
                break;
            case 'location':
                result.bool.must.push({ term: { "located_in.label": filters[filter] } })
                break;            
            case 'county':
                result.bool.must.push({ term: { "active.location.county.label": filters[filter] } })
                break;
            case 'region':
                result.bool.must.push({ term: { "active.location.region.label": filters[filter] } })
                break;
            case 'images':
                if (filters[filter]) {
                    result.bool.must.push({ exists: { field: "representation.maker.link" } })                
                }
                break;
            case 'active_location':
                result.bool.must.push({ term: { "active.location.label": filters[filter] } } )
                break;
            case 'active_location_partial':
                result.bool.must.push({ multi_match: { query: filters[filter], type: "phrase_prefix", fields: ["active.location.label.english"] } } )
                break;
            case 'dateRange':
                let beginYear = filters[filter][0];
                let endYear = filters[filter][1];
 
                let rangeNode = {};

                if (beginYear !== null) { 
                    rangeNode.gte = Number(beginYear)
                }

                if (endYear !== null) { 
                    rangeNode.lte = Number(endYear)

                }

                if (beginYear !== null || endYear !== null) {
                    result.bool.must.push( {range: { 'active.date_years': rangeNode }})
                }

                break;
        }
    }

    return result

}

function searchBarContextToQuery(queryString,type,pageSize) {

    const query = {   query: { 
                        bool: { 
                            must: ( type !== 'all' ) ? [ { term: { display_type: type } } ] : [ { term: { display_type: 'Artist' } } ],
                        }
                    },
                    size: pageSize,
                    sort: [{ '_score': {'order': 'desc'} }]
                }

    switch (type.toLowerCase()) {
        case 'location':
            // query.query.bool.must.push( { exists: { field: "location_of.label" } } )
            if ( queryString !== undefined && queryString !== "" ) {
                query.query.bool.must.push( { multi_match: { query: queryString, type: 'phrase_prefix', "fields": [ "other_title.label.english", "primary_title.label.english^2" ] } } )
                query.query.bool.must_not = [ { term: { "classified_as.label": "Region" } } ]
            }
            query.sort = [ { _script: { type: "number", script: "( doc['location_of.link'].length ?: 0 )", order: 'desc' } } , ...query.sort ]
            break;
        default:
            if ( queryString !== undefined && queryString !== "" ) {
                query.query.bool.must.push( { "simple_query_string": { "query": queryString, default_operator: "and", "fields": [ "artist_role.label.text", "biography.text", "other_title.label.english", "primary_title.label.english^2" ] } } )
            }
            break;
    }    

    return query

}

function searchContextToQuery(queryString,filters,type,landingType=undefined,landingTerm=undefined,pageSize=50,fromIndex=0,sortTerm="sort_title.label.sort",sortDir="asc",searchWithinString='') {
    /* Takes a queryString (string) and a filters object with filter names as keys and terms to filter by as values */

    const query = {   query: { 
                        bool: { 
                            must: ( type !== 'all' ) ? [ { term: { display_type: type } } ] : [ { term: { display_type: 'Artist' } } ],
                        }
                    },
                    size: pageSize,
                    from: fromIndex,
                    aggs: searchFacetAggregations(filters),
                    post_filter: searchFilterToElasticFilterSyntax(filters)
                }

    if (type.toLowerCase() === "organizations" ) {
        query.query.bool.must.push({ script: { script: { source: "( doc['member.link'].size() ?: 0 ) > 0"} } })
    } else if (type.toLowerCase() === "exhibitions" ) {
        query.query.bool.must.push({ script: { script: { source: "( doc['had_participant.link'].size() ?: 0 ) > 0"} } })
    }

    if (landingType && landingTerm) {

        switch (landingType.toLowerCase()) {
            case 'exhibitions':
                query.query.bool.must.push({term: { "exhibited_at.link": landingTerm }})
                break;
            case 'organizations':
                query.query.bool.must.push({term: { "focus_organization.link": landingTerm }})
                break;
            default:
                break;
        }

    }

    let sort = {}

    switch (sortTerm) {
        case '_script.artists_count':
            sort = { _script: { type: "number", script: `if ( doc.containsKey('member.link') ) { return doc['member.link'].length } else { return 0.0 }`, order: sortDir } }
            query.sort = [ sort, { 'primary_title.label.sort': 'asc' }, { 'active.date_begin': 'asc' } ]
            break;
        case '_script.artist_participant_count':
            sort = { _script: { type: "number", script: "if ( doc.containsKey('had_participant.link') ) { return doc['had_participant.link'].length } else { return 0.0 }", order: sortDir } }
            query.sort = [ sort, { 'primary_title.label.sort': 'asc' }, { 'active.date_begin': 'asc' } ]
            break;
        case '_script.exhibitions_count':
            sort = { _script: { type: "number", script: "if ( doc.containsKey('carried_out.link') ) { return doc['carried_out.link'].length } else { return 0.0 }", order: sortDir } }
            query.sort = [ sort, { 'primary_title.label.sort': 'asc' }, { 'active.date_begin': 'asc' } ]
            break;
        case 'primary_title.label.sort':
        case 'sort_title.label.sort':
            sort = { [sortTerm]: { order: sortDir} }            
            query.sort = [ sort, { 'active.date_begin': 'asc' } ]
            break;
        default:
            sort[sortTerm] = { "order": sortDir }
            query.sort = [ sort ]
            break;            
    }

    if (searchWithinString) {

        let queryBlock = { multi_match: { 
                            query: searchWithinString,
                            type: "bool_prefix",
                            operator: "and",
                            prefix_length: 1, 
                            fields: [ "other_title.label.english", "primary_title.label.english" ]
                            }  
                         }

        query.query.bool.must.push(queryBlock)  
        query.sort = [ ...query.sort ]
        query.highlight = { fields: { "primary_title.label.english": { number_of_fragments: 0 }, "other_title.label.english": { number_of_fragments: 0 } }}

    }
    
    if ( Object.keys(filters).length == 0 && ( queryString === null || queryString === "" ) ) { return query } 
    
    if ( queryString !== undefined && queryString !== "" ) {
        query.query.bool.must.push( { "simple_query_string": { "query": queryString, default_operator: "and", "fields": [ "artist_role.label.text", "biography.text", "other_title.label.english", "primary_title.label.english^2" ] } } )
    }

    return query

}

function relationshipsContextToQuery(ident,activeLocations,associatedPeople) {
    /* Helper function that turns a queryString and a filter array into a query */

    var query = { "size":200,
                    "query":{ 
                        bool: { 
                            must: [
                                {
                                    bool: {
                                        should: [
                                            {terms: { "active.location.link": activeLocations  }},
                                            {terms: { "related.link": associatedPeople  }}
                                        ]
                                    }
                                }

                                ], 
                            must_not: [ /*{ids: { values: [ ident ] } }*/] } } }

    return query
}

let countQueries = {
    org: { query: { bool: { must: [ { term: { display_type: "Organizations" } }, { script: { script: { source: "( doc['member.link'].size() ?: 0 ) > 0"} } } ] } } },
    exhib: { query: { bool: { must: [ { term: { display_type: "Exhibitions" } }, { script: { script: { source: "( doc['had_participant.link'].size() ?: 0 ) > 0"} } } ] } } },
    artist: { query: { bool: { must: [ { term: { display_type: "Artist" } } ] } } }
  }

const textWrap = ((tx, width, lineHtEm, verticalOffset=false, xOverride=false, tspanClassName='', lineLimit=3) => {

    if (width <= 0) { return tx; }

    // For each element in tx, take its text, split it into words, and reverse them
    // Then pop each off (in reverse order, so lol we're just popping in order) and add a text node with proper y offsets if it will fit
    
    tx.each(function() {
      var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          x = xOverride ? 0 : (parseFloat(text.attr('x')) || 0),
          dx = parseFloat(text.attr('dx') || 0),
          y = text.attr('y') || 0,
          dy = parseFloat(text.attr('dy')),
          tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('dx',dx).attr('dy', dy + 'em').attr('class', tspanClassName);
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(' '));
        const clen = tspan.node().getComputedTextLength();
        if (clen > width && line.length > 1) {
          line.pop();

          if ( lineNumber + 1 >= lineLimit ) {

              line.push('...')
              tspan.text(line.join(' '));

              words = []

          } else {
              tspan.text(line.join(' '));
              line = [word];
              tspan = text.append('tspan').attr('x', x).attr('dx',dx).attr('y', y).attr('dy', (++lineNumber) * lineHtEm + dy + 'em').attr('class', tspanClassName).text(word);
          }

        }
      }
      if (verticalOffset) {
        const allTspans = text.selectAll('tspan');
        const numTspans = allTspans.size();
        if (numTspans > 2) {
            allTspans.attr('y', y-8);
        } else if (numTspans === 2) {
            allTspans.attr('y', y-4);
        }
      }
    });
});

const truncate = (str, limit) => {
    return str.length > limit ? str.substring(0, (limit - 3)) + '...' : str;
}

export {useElasticSearch, useUrlParams, searchBarContextToQuery, searchContextToQuery, relationshipsContextToQuery, timelineContextToQuery, locationContextToQuery, textWrap, truncate, yearsToDecades, decadeToYears, paramsToState, stateToParams, countQueries}