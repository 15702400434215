import React, {useState, useEffect, useContext} from 'react';

import {SearchView, IndexView, PageNotFoundView, AboutView} from './views.js';
import { SearchConfigContext, SearchConfigProvider } from './context/searchConfigContext.js';

import { paramsToState, stateToParams } from './util.js'
const VIEW_TYPES = {
    SEARCH: 'SEARCH',
    INDEX: 'INDEX',
    ENTITY: 'ENTITY',
    ABOUT: 'ABOUT',
    PGNOTFOUND: 'PGNOTFOUND'
}


import prmoremlogo from '../images/pr-morem-logo02.png';
import pricon00 from '../images/pr-icon-architecture.png';
import pricon01 from '../images/pr-icon-crafts.png';
import pricon02 from '../images/pr-icon-drawing.png';
import pricon03 from '../images/pr-icon-fashion.png';
import pricon04 from '../images/pr-icon-furniture.png';
import pricon05 from '../images/pr-icon-jewelry.png';
import pricon06 from '../images/pr-icon-painting.png';
import pricon07 from '../images/pr-icon-photography.png';
import pricon08 from '../images/pr-icon-printmaking.png';
import pricon09 from '../images/pr-icon-sculpture.png';
import pricon10 from '../images/pr-icon-woodworking.png';
import prlticon00 from '../images/pr-lt-icon-architecture.png';
import prlticon01 from '../images/pr-lt-icon-crafts.png';
import prlticon02 from '../images/pr-lt-icon-drawing.png';
import prlticon03 from '../images/pr-lt-icon-fashion.png';
import prlticon04 from '../images/pr-lt-icon-furniture.png';
import prlticon05 from '../images/pr-lt-icon-jewelry.png';
import prlticon06 from '../images/pr-lt-icon-painting.png';
import prlticon07 from '../images/pr-lt-icon-photography.png';
import prlticon08 from '../images/pr-lt-icon-printmaking.png';
import prlticon09 from '../images/pr-lt-icon-sculpture.png';
import prlticon10 from '../images/pr-lt-icon-woodworking.png';
import prnouser from '../svg/nouser.svg';
import shareemail from '../images/share-email-icon.png';
import sharefb from '../images/share-facebook-icon.png';
import sharelinkedin from '../images/share-linkedin-icon.png';
import sharetwitter from '../images/share-twitter-icon.png';

import { searchPageSearchConfig } from './context/searchConfig';

function ContentView(props) {

    let endpointPath = props.indexName ? `${props.indexName}/_search`: 'entity/_search' 
    let endpointUrl = new URL(endpointPath, props.endpoint);

    switch (props.view) {
        case VIEW_TYPES.SEARCH:

            // let dataMapping = window.dataMapping ?? dataMapping 
            // let searchConfig = window.searchConfig ?? searchPageSearchConfig
            
            let state = paramsToState()
            // console.log(state)
            return <SearchView type={props.type} landingType={props.landingType} landingTerm={props.landingTerm} endpoint={endpointUrl.href} queryString={state.queryString ?? ''} fromIndex={state.fromIndex ?? 0} sortType={state.sortType} sortDir={state.sortDir} filters={state.filters}  />

        case VIEW_TYPES.INDEX:
            return <IndexView endpoint={endpointUrl.href} queryString={''} />

        case VIEW_TYPES.PGNOTFOUND:
            return <PageNotFoundView endpoint={endpointUrl.href} />

        case VIEW_TYPES.ABOUT:
            return <AboutView endpoint={endpointUrl.href} />

        default:
            return ''

    }

}

class App extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            title: props.title,
            endpoint: props.endpoint,
            indexName: props.indexName ?? 'entity',
            view: props.view,
            type: props.type,
            landingType: props.landingType ?? undefined,
            landingTerm: props.landingTerm ?? undefined
        }
    }

    render() {

        return <ContentView indexName={this.state.indexName} view={this.state.view} type={this.state.type} landingType={this.state.landingType} landingTerm={this.state.landingTerm} endpoint={this.state.endpoint}></ContentView>
               
    }
}

export default App;