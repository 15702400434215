import React, { useState, useEffect, useRef } from "react";
import {
  TimeframeInputs,
  ArtTypeInputs,
  OrganizationInputs,
  DemographicInputs,
  LocationInputs,
} from "./components/filterInputs";

import { ResultsPageControl, ResultsView, RESULTS_MODE, ALL_SORT_SETTINGS } from "./results.js";
import {
  useElasticSearch,
  searchContextToQuery,
  timelineContextToQuery,
  truncate,
  yearsToDecades,
  decadeToYears,
  useUrlParams,
  stateToParams,
  paramsToState,
  countQueries
} from "./util.js";

import {
  VisualizationFilterControl,
  VisualizationView,
  VisualizationSummary,
} from "./visualization.js";

import {LocationHeroView} from './locationHeroView';
import {OrganizationHeroView} from './organizationHeroView';
import SearchBar from "./components/searchBar";

import { AboutPage } from "./components/aboutPage";

const getFilterPillLabel = (key, d) => {
  /* Returns a data formatted for a filter pill according to the type of filter it is */
  switch (key) {
    case "dateRange":
      return (d[0] || "") + " - " + (d[1] || "");
    case "images":
      return "Has Artwork"
    case "active_location_partial":
      return `"${d}"`
    default:
      return d;
  }
};

function FilterBar(props) {

  let sortSettings = props.entityType.toLowerCase() in ALL_SORT_SETTINGS ? ALL_SORT_SETTINGS[props.entityType.toLowerCase()] : {}

  const [yearRange, setYearRange] = useState([null, null]);
  const [currentFilters, setCurrentFilters] = useState(props.currentFilters);

  // for the mobile filter panel:
  const [fpOpen, setFpOpen] = useState(false);
  const [fpLeft, setFpLeft] = useState(0);

  const openMFP = (e) => {
    //console.log('openMFP:');
    setFpOpen(true);
    setTimeout(() => {
      // delay so the transition applies, because after display/open
      setFpLeft("0");
    }, 13);
  };

  const hideMFP = () => {
    setFpLeft("-100%");
    setTimeout(() => {
      setFpOpen(false);
    }, 250);
  };

  const collapseHdg1 = useRef();
  const collapseHdg2 = useRef();
  const collapseHdg3 = useRef();
  const collapseHdg4 = useRef();
  const collapseHdg5 = useRef();
  const collapsibles = {
    collapse1: collapseHdg1,
    collapse2: collapseHdg2,
    collapse3: collapseHdg3,
    collapse4: collapseHdg4,
    collapse5: collapseHdg5,
  };

  const collapseRef = (id) => {
    // deactivate all but the one clicked;
    // toggle the one clicked
    for (const c in collapsibles) {
      if (c !== id) {
        collapsibles[c].current.classList.toggle("active", false);
      }
    }
    collapsibles[id].current.classList.toggle("active");
  };

  // == HANDLE RESIZING -- to make sure the mobile filter panel doesn't show at larger size
  // see https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  const [dimensions, setDimensions] = useState({
    height:
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight,
    width:
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth,
  });
  const [isMobileScreenSize, setIsMobileScreenSize] = useState(
    (window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth) < 768
  );

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      const w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const h =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      setDimensions({
        height: h,
        width: w,
      });
      console.log("window width == " + w);
      setIsMobileScreenSize(w < 768);
      if (fpOpen && w >= 768) {
        console.log("closing MFP");
        setFpOpen(false);
        setFpLeft("-100%");
      }
    }, 500);

    window.addEventListener("resize", debouncedHandleResize);
    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  const sortTypeChange = (event,sortConfig) => {
    props.changeSortType(sortConfig.key); 
    props.changeSortDirection(sortConfig.defaultSort)
  }

  const check = (event) => {
    if (props.currentFilters.images === true) {
      props.applyFilters({},['images']);
    } else {
      props.applyFilters({images: true},[])
    }
  }

  return (
    <React.Fragment>
      <div
        className={fpOpen ? "" : "d-none"}
        id="mfPanel"
        style={{ left: `${fpLeft}` }}
        aria-hidden={!isMobileScreenSize}
      >
        <div className="blocker" onClick={hideMFP}></div>
        <div className="mfp-base">
          <div className="row m-0 py-3 mfp-heading-row">
            <div className="col mfp-rowfullcol">
              <div className="d-block">
                <div className="icon icon-filter icon-sm icon--denim mr-2"></div>
                <div className="d-inline-block mfp-heading-text">Filters</div>
              </div>
              <div className="d-block">
                <input
                  className="mfp-close-button"
                  type="button"
                  value="Close menu"
                  onClick={hideMFP}
                />
              </div>
            </div>
          </div>
          <div className="row m-0 py-1 mfp-subheading-row">
            <div className="col mfp-rowfullcol">
              <div className="checkmark mfp-checkmark">
                <input id="mfpArtworkCheckbox" 
                      type="checkbox" 
                      name="mfpArtworkCheckbox" 
                      checked={props.currentFilters.images === true}
                      onChange={ check }
                      />
                <label className={`${props.entityType.toLowerCase() !== 'artist' ? 'd-none' : '' }`} htmlFor="mfpArtworkCheckbox">Has Artwork</label>
              </div>
              <div className="landing-page__table-sort">
                <div className="text-center">
                  <button
                    type="button"
                    className={`btn btn-link icon icon-sort--${ props.sortDir === 'asc' ? 'up' : 'down' }`}
                    onClick={ () => props.changeSortDirection( props.sortDir === 'asc' ? 'desc' : 'asc' ) }
                  ></button>
                  <button
                    type="button"
                    className="btn btn-link dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    Sort By
                  </button>
                    <div className="dropdown-menu dropdown-menu-right">
                    { Object.entries(sortSettings).map( ([sortType,sortConfig],idx) => {
                      return <a key={idx} className={`dropdown-item ${ props.sortType === sortConfig.key ? 'active' : '' }`} href="#" onClick={ (event) => sortTypeChange(event,sortConfig) }>{sortConfig.label}</a>
                    })}
                  </div>
               </div>
              </div>
            </div>
          </div>
          <div className="row m-0">
            <div className="col p-0">
              <div className="accordion" id="filtersAccordion">
                <div className="card">
                  <div
                    className={`mfp-collapse-hdg`}
                    ref={collapseHdg1}
                    id="headingOne"
                  >
                    <h2 className="mb-0 pb-2">
                      <button
                        className="btn btn-link mfp-heading-text collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        onClick={() => collapseRef("collapse1")}
                      >
                        <i className="icon icon-accordion icon--denim pr-1"></i>{" "}
                        Timeframe
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#filtersAccordion"
                  >
                    <div className="card-body">
                      <TimeframeInputs
                        yearToId="yearToM"
                        yearFromId="yearFromM"
                        yearRange={yearRange}
                        applyFilters={props.applyFilters}
                        filters={currentFilters}
                        aggregations={props.aggregations}
                        count={props.count}
                      />
                    </div>
                  </div>
                </div>
                <div className={`card ${ props.entityType.toLowerCase() !== 'artist' ? 'd-none' : '' }`}>
                  <div
                    className={`mfp-collapse-hdg`}
                    ref={collapseHdg2}
                    id="headingTwo"
                  >
                    <h2 className="mb-0 pb-2">
                      <button
                        className="btn btn-link mfp-heading-text collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                        onClick={() => collapseRef("collapse2")}
                      >
                        <i className="icon icon-accordion icon--denim pr-1"></i>{" "}
                        Type of Art
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    className={`collapse mfp-collapse-hdg `}
                    aria-labelledby="headingTwo"
                    data-parent="#filtersAccordion"
                  >
                    <div className="card-body">
                      <ArtTypeInputs
                        applyFilters={props.applyFilters}
                        filters={currentFilters}
                        aggregations={props.aggregations}
                        count={props.count}
                      />
                    </div>
                  </div>
                </div>
                <div className={`card ${ props.entityType.toLowerCase() !== 'artist' && props.entityType.toLowerCase() !== 'exhibitions' ? 'd-none' : '' }`}>
                  <div
                    className={`mfp-collapse-hdg`}
                    ref={collapseHdg3}
                    id="headingThree"
                  >
                    <h2 className="mb-0 pb-2">
                      <button
                        className="btn btn-link mfp-heading-text collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        onClick={() => collapseRef("collapse3")}
                      >
                        <i className="icon icon-accordion icon--denim pr-1"></i>{" "}
                        Organization
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#filtersAccordion"
                  >
                    <div className="card-body">
                      <OrganizationInputs
                        entityType={props.entityType}
                        applyFilters={props.applyFilters}
                        filters={currentFilters}
                        aggregations={props.aggregations}
                        count={props.count}
                      />
                    </div>
                  </div>
                </div>
                <div className={`card ${ props.entityType.toLowerCase() !== 'artist' ? 'd-none' : '' }`}>
                  <div
                    className={`mfp-collapse-hdg`}
                    ref={collapseHdg4}
                    id="headingFour"
                  >
                    <h2 className="mb-0 pb-2">
                      <button
                        className="btn btn-link mfp-heading-text collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                        onClick={() => collapseRef("collapse4")}
                      >
                        <i className="icon icon-accordion icon--denim pr-1"></i>{" "}
                        Demographics
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-parent="#filtersAccordion"
                  >
                    <div className="card-body">
                      <DemographicInputs
                        applyFilters={props.applyFilters}
                        ethnicityCheckId="checkUnknownEthnicityM"
                        filters={currentFilters}
                        aggregations={props.aggregations}
                        count={props.count}
                      />
                    </div>
                  </div>
                </div>
                {/* KLUDGE: to make sure typeahead menu isn't cut off 
                    -- the floating suggestion list will be relative to the card; the hidden-overflow
                    element needs to be at a lower level in the hierarchy -- the card-body */}
                <div className={`card`} style={{ overflow: "visible" }}>
                  <div
                    className="mfp-collapse-hdg"
                    ref={collapseHdg5}
                    id="headingFive"
                  >
                    <h2 className="mb-0 pb-2">
                      <button
                        className="btn btn-link mfp-heading-text collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                        onClick={() => collapseRef("collapse5")}
                      >
                        <i className="icon icon-accordion icon--denim pr-1"></i>{" "}
                        Location
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseFive"
                    className="collapse"
                    aria-labelledby="headingFive"
                    data-parent="#filtersAccordion"
                  >
                    <div className="card-body" style={{ overflow: "hidden" }}>
                                    <LocationInputs
                  entityType={props.entityType}
                  applyFilters={props.applyFilters}
                  filters={currentFilters}
                  clearFilter={props.clearFilter}
                  aggregations={props.aggregations}
                  count={props.count}
                  endpoint={props.endpoint}
                />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end of mobile filter panel */}

      <div id="filters" className="landing-filters-tabs">
        {/* Filters button for mobile + showing k to l of n */}
        <div className="landing-filters-mobile d-block d-md-none">
          <div className="row m-0 py-3">
            <div className="col mfp-rowfullcol px-0">
              <div className="d-block">
                <button onClick={openMFP} className="btn btn-secondary">
                  <i className="icon button-icon icon-sm icon-filter icon--navy"></i>{" "}
                  Filters
                </button>
              </div>
              <div className="d-block">
                <h5 className="mfp-showing">
                  <span className="mfp-showing-numbers">1-50</span> of
                  <span className="mfp-showing-total"> {props.count}</span>{" "}
                  artists
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="d-none d-md-block" aria-hidden={isMobileScreenSize}>
          <input
            className="state filter-drawer-toggle"
            type="radio"
            name="tabs-state"
            id="tab-one"
          />
          <input
            className="state filter-drawer-toggle"
            type="radio"
            name="tabs-state"
            id="tab-two"
          />
          <input
            className="state filter-drawer-toggle"
            type="radio"
            name="tabs-state"
            id="tab-three"
          />
          <input
            className="state filter-drawer-toggle"
            type="radio"
            name="tabs-state"
            id="tab-four"
          />
          <input
            className="state filter-drawer-toggle"
            type="radio"
            name="tabs-state"
            id="tab-four"
          />
          <input
            className="state filter-drawer-toggle"
            type="radio"
            name="tabs-state"
            id="tab-five"
          />

          <div className="tabs flex-tabs">
            <div className="bar">
              <div className="landing-filters__filter-by">
                <div className="icon icon-filter icon-sm icon--denim pr-1"></div>
                <h6 className="landing-filters__filter-by-text">Filter by:</h6>
              </div>
              <label for="tab-one" id="tab-one-label" className={`tab`}>
                <span className="filter-dropdown">
                  Timeframe
                  <span className="icon icon-carat-tabpanel icon--denim"></span>
                </span>
              </label>
              <div id="tab-one-panel" className="panel">
                <TimeframeInputs
                  yearToId="yearTo"
                  yearFromId="yearFrom"
                  applyFilters={props.applyFilters}
                  filters={currentFilters}
                  aggregations={props.aggregations}
                  count={props.count}
                />
              </div>
              <label for="tab-two" id="tab-two-label" className={`tab ${ props.entityType.toLowerCase() !== "artist" ? 'd-none' : '' }`}>
                <span className="filter-dropdown">
                  Type of Art
                  <span className="icon icon-carat-tabpanel icon--denim"></span>
                </span>
              </label>
              <div id="tab-two-panel" className="panel">
                <ArtTypeInputs
                  applyFilters={props.applyFilters}
                  filters={currentFilters}
                  aggregations={props.aggregations}
                  count={props.count}
                />
              </div>
              <label for="tab-three" id="tab-three-label" className={`tab ${ props.entityType.toLowerCase() !== "artist" && props.entityType.toLowerCase() !== "exhibitions" ? 'd-none' : '' }`}>
                <span className="filter-dropdown">
                  Organization
                  <span className="icon icon-carat-tabpanel icon--denim"></span>
                </span>
              </label>
              <div id="tab-three-panel" className="panel">
                <OrganizationInputs
                  entityType={props.entityType}
                  applyFilters={props.applyFilters}
                  filters={currentFilters}
                  aggregations={props.aggregations}
                  count={props.count}
                />
              </div>
              <label for="tab-four" id="tab-four-label" className={`tab ${ props.entityType.toLowerCase() !== "artist" ? 'd-none' : '' }`}>
                <span className="filter-dropdown">
                  Demographics
                  <span className="icon icon-carat-tabpanel icon--denim"></span>
                </span>
              </label>
              <div id="tab-four-panel" className="panel">
                <DemographicInputs
                  applyFilters={props.applyFilters}
                  ethnicityCheckId="checkUnknownEthnicity"
                  filters={currentFilters}
                  aggregations={props.aggregations}
                  count={props.count}
                />
              </div>
              <label for="tab-five" id="tab-five-label" className="tab">
                <span className="filter-dropdown">
                  Location
                  <span className="icon icon-carat-tabpanel icon--denim"></span>
                </span>
              </label>
              <div id="tab-five-panel" className="panel">
                <LocationInputs
                  entityType={props.entityType}
                  applyFilters={props.applyFilters}
                  filters={currentFilters}
                  clearFilter={props.clearFilter}
                  aggregations={props.aggregations}
                  count={props.count}
                  endpoint={props.endpoint}
                />
              </div>
            </div>
          </div>
        </div>

        {Object.keys(props.currentFilters).length > 0 && (
          <div className="landing-page__current-filters">
            {/* filtered-by row for mobile: */}
            <div className="container-fluid d-flex align-center d-md-none m-0">
              <div className="filtered-by-label">Filtered by</div>
              <div className="ml-3">
                <a
                  className="filters-clear-all"
                  onClick={props.clearFilters}
                  href="javascript:;"
                  title="Clear all filters"
                >
                  <i className="icon icon-sm button-icon icon-close-button-x icon--mid-blue"></i>{" "}
                  clear all filters
                </a>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col px-0">
                  <div className="d-flex">
                    {Object.keys(props.currentFilters).length > 0
                      ? Object.keys(props.currentFilters).map(function (
                          key,
                          idx
                        ) {
                          const filterValue = props.currentFilters[key];
                          const filterPillLabel = getFilterPillLabel(
                            key,
                            filterValue
                          );
                          //console.log(d);
                          return (
                            <div
                              className="filtered-by-pill"
                              title={filterPillLabel}
                            >
                              {truncate(filterPillLabel, 20)}
                              <i
                                className="icon icon-sm button-icon icon-close-button-x icon--mid-blue"
                                title="Remove filter"
                                onClick={() => props.clearFilter(key)}
                              ></i>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
                <div className="d-none d-md-block col-md-2 ml-auto">
                  <input
                    type="button"
                    value="Clear filters"
                    className="filter-clear-all"
                    onClick={props.clearFilters}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

function SearchView(props) {
  // FIXME: Attach a window listener for a custom event that sets 

  const [ endpoint, setEndpoint ] = useState(props.endpoint);
  const [ poppedParams, setPoppedParams ] = useState('');
  const [ appliedState, setAppliedState ] = useState({});

  // Query state
  const [ entityType, setEntityType ] = useState(props.type);
  const [ landingType, setLandingType ] = useState(props.landingType)
  const [ landingTerm, setLandingTerm ] = useState(props.landingTerm)
  const [ queryString, setQueryString ] = useState(props.queryString);
  const [ searchWithinString, setSearchWithinString ] = useState('');
  const [ filters, setFilters ] = useState(props.filters);
  const [ fromIndex, setFromIndex ] = useState(props.fromIndex ?? 0)
  const [ pageSize, setPageSize ] = useState(props.pageSize ?? 50);

  var defaultSortKey; 
  var defaultSortDir;

  if ( queryString !== '' ) {
    defaultSortKey = "_score"
    defaultSortDir = 'desc'

  } else {
    defaultSortKey = entityType.toLowerCase() in ALL_SORT_SETTINGS ? Object.entries(ALL_SORT_SETTINGS[entityType.toLowerCase()]).reduce( (acc,[key,cur]) => cur.default ? cur.key : acc ,'') : 'sort_title.label.sort'
    defaultSortDir = Object.entries(ALL_SORT_SETTINGS[entityType.toLowerCase()]).reduce( (acc,[key,cur]) => cur.default ? cur.defaultSort : acc ,'')
  }


  const [ sortType, setSortType ] = useState( props.sortType ?? defaultSortKey )
  const [ sortDir, setSortDir ] = useState( props.sortDir ?? defaultSortDir )
  const [ rootState, setRootState ] = useState(true)

  // Query + Results
  const [ query, setQuery ] = useState(null);

  const [ aggregations, setAggregations ] = useState({});
  const [ count, setCount ] = useState(0);

  // `useElasticSearch` is a React Hook that performs a `fetch()` against `endpoint` and asynchronously returns `results` 
  const results = useElasticSearch(endpoint, query);

  useEffect(() => {
    /* Sets our state according to the param string. Must be done in `useEffect` hook for react <= v17 to batch the state changes */
    if (poppedParams) {
      for ( let [key,value] of Object.entries( paramsToState(poppedParams) ) ) {
        switch (key) {
          case 'filters':
            setFilters(value);
            break;
          case 'fromIndex':
            // console.log(value)
            setFromIndex(value);
            break
          case 'pageSize':
            setPageSize(value);
            break
          case 'queryString':
            setQueryString(value);
            break
          case 'sortType':
            setSortType(value);
            break
          case 'sortDir':
            setSortDir(value);
            break
          case 'rootState':
            setRootState( value === 'true' || value === true ? true : false );
            break
          default: 
            continue
        }
      }
      
    }
    return () => { setPoppedParams('') }
  },[poppedParams])

  useEffect(() => {
    /* Sets our state according to the param string. Must be done in `useEffect` hook for react <= v17 to batch the state changes */
    for ( let [key,value] of Object.entries( appliedState ) ) {
      switch (key) {
        case 'filters':
          setFilters(value);
          break;
        case 'fromIndex':
          // console.log(value)
          setFromIndex(value);
          break
        case 'pageSize':
          setPageSize(value);
          break
        case 'queryString':
          setQueryString(value);
          break
        case 'sortType':
          setSortType(value);
          break
        case 'sortDir':
          setSortDir(value);
          break
        default:
          continue
      }
    }

  },[appliedState])

  useEffect(() => {

    if (props.partialFilters && Object.keys(props.partialFilters).length > 0 ) {
      applyFilters(props.partialFilters,[],false)    
    }
    // console.log(props.partialFilters)

    // console.log("partialFilters changed")
    // console.log(props.partialFilters)

    // setFromIndex(0)
    // setFilters(previousFilters => ( Object.assign(previousFilters, props.partialFilters) ) )
  },[props.partialFilters])

  useEffect(() => {

    if (rootState) {
      setRootState(false)
    } else {
      useUrlParams(queryString, fromIndex, pageSize, filters, sortType, sortDir )        
    }

    let query = searchContextToQuery(queryString, filters, entityType, landingType, landingTerm, pageSize, fromIndex, sortType, sortDir, searchWithinString )
    setQuery(query)

  }, [queryString, fromIndex, pageSize, filters, sortType, sortDir, searchWithinString ]);

  const popStateHandler = (event) => {

    let state = event.state;
    let params = state?.params;
    let paramIsRoot = state?.rootState;

    if (paramIsRoot) {
      setRootState(true)
      setPoppedParams(params);
    } else {
      setPoppedParams(params)
    }

  }

  // Sets up some callbacks:
  // - A popstate handler to handle browser back button clicks
  // - A listener for a custom event from the search within bars
  useEffect(() => {

    let state = { queryString, fromIndex, pageSize, sortType, sortDir, filters }
    let params = stateToParams(state)
    const url = new URL(window.location.href)
    url.search = params

    window.history.replaceState({params: params, rootState: true },document.title,window.location.href)

    window.onpopstate = popStateHandler 

    const triggerSearchWithin = (event) => {      
      if (event.detail?.searchWithin || event.detail?.searchWithin === "" ) {
        setSearchWithinString(event.detail.searchWithin)
        setFromIndex(0)      
      }
    }

    window.addEventListener('cb-search-within-list',triggerSearchWithin)

  }, []);

  useEffect(() => {
      if (results==null) { return; }

      if (props.resultsCallback) {
        props.resultsCallback(results)
      }
      
      setCount(results.hits.total.value);
      setAggregations(results.aggregations);
  }, [results]);

  function changeSortType(kind) {
      setSortType(kind)
      setFromIndex(0);
  }

  function changeSortDirection(dir) {
      setSortDir(dir)
      setFromIndex(0);
  }

  function changePage(newVal,scrollToTop=false) {
      if ( newVal < count ) {
          setFromIndex(newVal)
          if (scrollToTop) {
            window.scrollTo({top:0,behavior:'smooth'})
          }
      }
  }

  const applyFilters = (newFilters, unsets, callback=true) => {

    let filt1 = {...filters, ...newFilters};
    for (const u of unsets) {
      delete filt1[u];
    }

    // console.log('applyFilters'); console.log(filt1);
    setAppliedState({fromIndex:0, filters:filt1})
    if (props.filterCallback && callback) {
      props.filterCallback(filt1);    
    }

    // also close the current filter tab to let user see the newly filtered results & settings
    window.uncheckRadioBTabs();
  }

  const clearFilter = (filt) => {
    applyFilters({},[filt])
    setFromIndex(0);
  }

  const clearFilters = () => {  
    setFilters({});
    if (props.filterCallback) {
      props.filterCallback({})
    }
    setFromIndex(0);
  }

  return (
    <div className="container-fluid">
      <FilterBar
        endpoint={endpoint}
        sortType={sortType}
        sortDir={sortDir}
        entityType={entityType}
        currentFilters={filters}
        applyFilters={applyFilters}
        clearFilter={clearFilter}
        clearFilters={clearFilters}
        aggregations={aggregations}
        changeSortType={changeSortType}
        changeSortDirection={changeSortDirection}
        count={count}
      />
      <div className="row">
        <div className="col">
          <ResultsPageControl
            queryString={queryString}
            entityType={entityType}
            changePage={changePage}
            count={count}
            fromIndex={fromIndex}
            pageSize={pageSize}
            sortDir={sortDir}
            sortType={sortType}
            changeSortType={changeSortType}
            changeSortDirection={changeSortDirection}
            applyFilters={applyFilters}
            landingType={landingType}
            resultsTypeLabel={entityType.toLowerCase() === "artist" ? "artists" : entityType.toLowerCase()}
            filters={filters}
          ></ResultsPageControl>
          <ResultsView
            results={results}
            count={count}
            mode={RESULTS_MODE.LIST}
            landingType={landingType}
          ></ResultsView>
          <ResultsPageControl
            entityType={entityType}
            changePage={changePage}
            count={count}
            fromIndex={fromIndex}
            pageSize={pageSize}
            sortDir={sortDir}
            sortType={sortType}
            changeSortType={changeSortType}
            changeSortDirection={changeSortDirection}
            applyFilters={applyFilters}
            filters={filters}
            scrollToTop={true}
            landingType={landingType}
          ></ResultsPageControl>
        </div>
      </div>
    </div>
  );
}

function IndexView(props) {
  const initMinYear = 1818;
  const initMaxYear = 2015;

  const [endpoint, setEndpoint] = useState(props.endpoint);
  const [filters, setFilters] = useState({active_begin: initMinYear, active_end: initMaxYear});
  const [aggregations, setAggregations] = useState({});
  const [query, setQuery] = useState(timelineContextToQuery(filters));
  const [resetTrigger, setResetTrigger] = useState(false);

  const results = useElasticSearch(endpoint, query);
  const [minYear, setMinYear] = useState(initMinYear);
  const [maxYear, setMaxYear] = useState(initMaxYear);
  const [minMaxYearsFound, setMinMaxYearsFound] = useState(false);

  const [count, setCount] = useState(0);

  const [erasSet, setErasSet] = useState(0);
  const [eraIdx, setEraIdx] = useState(-1);
  const [isSelectedEra, setIsSelectedEra] = useState(false);
  const [highlightEra, setHighlightEra] = useState(null);

  const erasCollection = [
      [   // set 0: US
          { name: "Westward Expansion", date_first: 1801, date_last: 1861, display_row: 0 },
          { name: "Women's Rights Movement", date_first: 1848, date_last: 1920, display_row: 1 },
          { name: "Civil War and Reconstruction", date_first: 1861, date_last: 1877, display_row: 0, maxLabelWidth: 75 },
          { name: "Industrialization", date_first: 1878, date_last: 1898, display_row: 2 },
          { name: "WWI", date_first: 1914, date_last: 1918, display_row: 2, maxLabelWidth: 38 },
          { name: "\"Separate but Equal\" Jim Crow", date_first: 1896, date_last: 1954, display_row: 0 },
          { name: "Roaring Twenties", date_first: 1920, date_last: 1929, display_row: 2, maxLabelWidth: 40 },
          { name: "Great Depression", date_first: 1929, date_last: 1939, display_row: 1 },
          { name: "Civil Rights", date_first: 1954, date_last: 1968, display_row: 0 },
          { name: "WWII", date_first: 1939, date_last: 1945, display_row: 2, maxLabelWidth: 38 },
          { name: "Post-War", date_first: 1945, date_last: 1964, display_row: 1 }
      ],
      [   // set 1: Missouri
          { name: "Slavery in Missouri", date_first: 1821, date_last: 1865, display_row: 0 }, //x
          { name: "Urbanization and Infrastructure", date_first: 1900, date_last: 1930, display_row: 2, maxLabelWidth: 70 },
          { name: "Racial Activism and Violence", date_first: 1865, date_last: 1968, display_row: 0, maxLabelWidth: 70 },
          { name: "Floating Freedom School", date_first: 1847, date_last: 1854, display_row: 1, maxLabelWidth: 40, align: 'right' }, //x
          { name: "Activism for Women's Rights", date_first: 1867, date_last: 1919, display_row: 1 }, //x
          { name: "Border Wars", date_first: 1855, date_last: 1863, display_row: 1, maxLabelWidth: 40, align: 'left' },
          { name: "Truman Presidency", date_first: 1945, date_last: 1953, display_row: 1, maxLabelWidth: 50 },
          { name: "Native Peoples Expelled", date_first: 1818, date_last: 1838, display_row: 1, maxLabelWidth: 70 }, //x
          { name: "Prohibition and Crime", date_first: 1920, date_last: 1938, display_row: 1, maxLabelWidth: 70 },
          { name: "St. Louis - Gateway to the West", date_first: 1817, date_last: 1890, display_row: 2 }, // x
      ]
  ];  

  const [totalQuery, setTotalQuery] = useState(Object.entries(timelineContextToQuery({})).filter( ([key,_]) => key === 'query').reduce( (acc,[key,value]) => { return { ...acc, [key]: value } }, {}));

  const totalResults = useElasticSearch(endpoint, totalQuery);

  const dateLimited = (d, upperLimit) => {
    return (upperLimit
        ? Math.min(d, maxYear)
        : Math.max(d, minYear)
    );
  }

  const resetYearFilter = () => {
    // console.log('resetting years');
    setFilters({active_begin: minYear, active_end: maxYear});
    setIsSelectedEra(false);
    setEraIdx(-1);
    setTimeout(() => {
      setResetTrigger(true);
    }, 500);  // allow some time for count to update
  }

  useEffect(() => {
    if (results === null) {
      return;
    }
    if (!minMaxYearsFound && minYear === initMinYear && maxYear === initMaxYear) {
      const maxY = parseInt(results.aggregations.active_years.buckets.reduce((prev, current) => (parseInt(prev.key_as_string) > parseInt(current.key_as_string)) ? prev : current).key_as_string);
      const minY = parseInt(results.aggregations.active_years.buckets.reduce((prev, current) => (parseInt(prev.key_as_string) < parseInt(current.key_as_string)) ? prev : current).key_as_string);
      console.log('minY = ' + minY + '; maxY = ' + maxY);
      if (minY !== initMinYear || maxY !== initMaxYear) {
        setMinYear(minY);
        setMaxYear(maxY);
        setFilters({active_begin: minY, active_end: maxY});
        console.log('set filters to ' + minY + ' - ' + maxY);
      }
      setMinMaxYearsFound(true);
    }
    setCount(results.hits.total.value);
    setAggregations(results.aggregations);
  }, [results]);

  useEffect(() => {
    setQuery(timelineContextToQuery(filters));
  }, [props.queryString, filters]);

  const selectEraSetPlusEra = (e) => {
    const optionValue = parseInt(e.target.value);
    if (optionValue === 0) {
      resetYearFilter();
    } else {
      // if the value is positive, it minus one is the index into erasCollection[0]; otherwise, |value|-1 is the index into erasCollection[1]
      const erasSetIdx = optionValue > 0 ? 0 : 1;
      const eraIdx = optionValue > 0 ? optionValue - 1 : -(optionValue) - 1;
      const eraData = erasCollection[erasSetIdx][eraIdx];
      setErasSet(erasSetIdx);
      setEraIdx(eraIdx);
      setFilters({active_begin: dateLimited(eraData.date_first, 0), active_end: dateLimited(eraData.date_last, 1)});
      setIsSelectedEra(true);
      setHighlightEra({erasSet: erasSetIdx, eraIdx: eraIdx});
    }
  }

  return (
    <div className="home-page">
      <div className="hero">
        <div className="hero-image"></div>
        <div className="hero-inner">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <div className="hero__content-box">
                  <h4 className="hero__content-box-subheader">Welcome</h4>
                  <h1 className="hero__content-box-header">Missouri Artists</h1>
                  <h4 className="hero__content-box-dates">Explore Missouri's past through its artists</h4>
                  <p className="collapse show" id="hero__content-box-text">
                    <em>Missouri Remembers</em> documents visual artists who were active in Missouri. Use this resource to learn about the artists, exhibitions, and institutions that have defined the state’s artistic culture.
                    <br/>
                    <em>Are we missing someone?</em> New Missouri artists are being added to this resource regularly. If you have a question or would like to suggest an artist for inclusion please <a href="https://nelson-atkins.org/contact/#library" target="_blank">contact us</a>.
                  </p>
                </div>
              </div>
              <div className="col-4 d-flex flex-column justify-content-start">
                <SearchBar
                  endpoint={endpoint}
                  type={"Artist"}
                  searchBarClassName="hero_search-container"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-page-inner">
        <div className="container container-triangle">
          <div className="d-flex flex-row home_viz">
            <div className="flex-column">
              <h4 className="mb-0">Artists active in</h4>
              <h3 className="mb-0">MISSOURI</h3>
            </div>
            <div className="home_viz-seperator"></div>
            <h2 className="home_viz-number">{totalResults?.hits?.total?.value ?? 0}</h2>
            <div className="d-flex flex-column justify-content-center">
              <h6 className="mb-0 home_viz-total">TOTAL</h6>
              <a className="mb-0 h6" href="/artist/">
                VIEW ALL
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-lg-3">
              <h6>In historical context</h6>
              <div className="d-none d-lg-block">
                <a className={ erasSet === 0 ? "btn btn-block home_viz-context-button active" : "btn btn-block home_viz-context-button"  } onClick={() => { setEraIdx(-1); setErasSet(0); }}>
                  US ERAS
                </a>
                <a className={ erasSet === 1 ? "btn btn-block home_viz-context-button active" : "btn btn-block home_viz-context-button"  } onClick={() => { setEraIdx(-1); setErasSet(1); }}>
                  MISSOURI EVENTS
                </a>
              </div>
              <div className="d-block d-lg-none mb-3">
                <select className="custom-select w-auto" onChange={selectEraSetPlusEra}>
                  <option value="0">View All</option>
                  <optgroup label="United States Eras">
                  {
                    erasCollection[0].map((d, i) => (
                      <option value={i+1}>{d.name}: {d.date_first}{d.date_last > d.date_first ? `-${d.date_last}` : ''}</option>
                    ))
                  }
                  </optgroup>
                  <optgroup label="Missouri Events">
                  {
                    erasCollection[1].map((d, i) => (
                      <option value={`-${i+1}`}>{d.name}: {d.date_first}{d.date_last > d.date_first ? `-${d.date_last}` : ''}</option>
                    ))
                  }
                  </optgroup>
                </select>
              </div>
            </div>
            <div className="col-sm-12 col-lg-9">
              <p className="ml-2">
                Drag bars or click on an era to change timeframe.
              </p>{" "}
              <VisualizationFilterControl
                filters={filters}
                setFilters={setFilters}
                isSelectedEra={isSelectedEra}
                setIsSelectedEra={setIsSelectedEra}
                setSelectedEra={setEraIdx}
                dateLimited={dateLimited}
                aggregations={results !== null ? results.aggregations : {}}
                count={count}
                minYear={minYear}
                maxYear={maxYear}
                erasSet={erasSet}
                erasCollection={erasCollection}
                resetTrigger={resetTrigger}
                setResetTrigger={setResetTrigger}
                resetYears={resetYearFilter}
                highlightEra={highlightEra}
                setHighlightEra={setHighlightEra}
              />
            </div>
          </div>
        </div>
        <div className="container morem-pr-pbbefore">
          <div className="d-flex flex-row home_viz">
            <div className="flex-column">
              <h4 className="mb-0">Explore these</h4>
              <h3 className="mb-0">ARTISTS</h3>
            </div>
            <div className="home_viz-seperator home_viz-seperator--blue"></div>
            <h2 className="home_viz-number text-blue">{count}</h2>
            <div className="d-flex flex-column justify-content-center">
              <h6 className="mb-0 home_viz-total text-denim">ARTISTS ACTIVE</h6>
              <h6 className="mb-0 home_viz-total text-blue">
                {filters?.active_begin ?? minYear}-{filters?.active_end ?? maxYear} {(eraIdx >= 0 ? erasCollection[erasSet][eraIdx].name : '')}
              </h6>
              <a className="mb-0 h6 text-blue" href={`/search?f.dateRange=${[filters?.active_begin ?? minYear,filters?.active_end ?? maxYear]}`}>
                VIEW ARTISTS
              </a>
            </div>
          </div>
          <VisualizationView
            filters={filters}
            aggregations={results !== null ? results.aggregations : {}}
            count={count}
            minYear={minYear}
            maxYear={maxYear}
          />
        </div>
      </div>
    </div>
  );
}

function PageNotFoundView(props) {
  const [endpoint, setEndpoint] = useState(props.endpoint);
  return (
    <div className="home-page">
        <div className="hero">
          <div className="hero-image"></div>
          <div className="hero-inner">
            <div className="container morem-pr-hide">
              <div className="row">
                <div className="col-8">
                  &nbsp;
                </div>
                <div className="col-4 d-flex flex-column justify-content-start">
                  <SearchBar endpoint={endpoint} type={"Artist"} searchBarClassName="hero_search-container" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-page-inner">
          <div className="container container-non-triangle">
            <div className="row">
                <div className="col">
                  <h1>Page Not Found</h1>
                  <p className="hp-bigger-text">The page you are looking for does not exist or it might be at a different URL. Options:</p>
                  <ul>
                    <li>Try searching for it by using the search at the top of this page.</li>
                    <li>Go to this site's <a href="/">home page</a>.</li>
                    <li>Go back to the <a href="javascript:history.back()">previous page</a>.</li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
    </div>
  )
}

function AboutView(props) {
  const [endpoint, setEndpoint] = useState(props.endpoint);
  return (
    <div className="home-page">
        <div className="hero">
          <div className="hero-image"></div>
          <div className="hero-inner">
            <div className="container morem-pr-hide">
              <div className="row">
                <div className="col-8">
                  &nbsp;
                </div>
                <div className="col-4 d-flex flex-column justify-content-start">
                  <SearchBar endpoint={endpoint} type={"Artist"} searchBarClassName="hero_search-container" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-page-inner">
          <div className="container container-non-triangle">
            <div className="row">
                <div className="col">
                  <AboutPage />
                </div>
            </div>
          </div>
        </div>
    </div>
  )
}

function LocationLandingView(props) {

  // LocationHeroView and SearchView already know how to handle filter + result states 
  // So this component just pools and passes filters + results 

  let state = paramsToState()
  console.log(state)
  const [ mapFilters, setMapFilters ] = useState({})
  const [ searchFilters, setSearchFilters ] = useState(state.filters)
  const [ results, setResults ] = useState(undefined)
  
  // FIXME: Materialize the parameter state here so we can properly handle passed fromIndex, sortType, sortDir, and filters 

  useEffect(() => {
    console.log("User set mapFilters")
    console.log(mapFilters)
  },[mapFilters])

  useEffect(() => {
    console.log("User set searchFilters")
    console.log(searchFilters)
  }, [searchFilters])

  return (
    <div className="location-page">
      <div className="hero">
        <div className="hero-image"></div>
        <div className="hero-inner">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <div className="hero__content-box">
                  <h1 className="hero__content-box-header">
                    Missouri Artists by Location
                  </h1>
                  <div className="row">
                    <LocationHeroView endpoint={props.endpoint} minYear={1818} maxYear={2015} applyFilters={setMapFilters} partialFilters={searchFilters} results={results} />
                  </div>
                </div>
              </div>
              <div className="col-4 d-flex flex-column justify-content-start">
                <SearchBar endpoint={props.endpoint} type={"Artist"} searchBarClassName="hero_search-container" />
              </div>
            </div>
            <div className="hero__map-image"></div>
          </div>
        </div>
        <div className="landing-page">
          <SearchView endpoint={props.endpoint} type={"Artist"} queryString={''} fromIndex={state.fromIndex ?? 0} sortType={state.sortType} sortDir={state.sortDir ?? 'asc'} filters={searchFilters} partialFilters={mapFilters} resultsCallback={setResults} filterCallback={setSearchFilters}/>
        </div>
      </div>
    </div>
  )
}

function OrganizationLandingView(props) {

  const [ endpoint, setEndpoint ] = useState(props.endpoint.replace('_search','_count'))
  const [ searchFilters, setSearchFilters ] = useState({})
  const [ results, setResults ] = useState(undefined)

  useEffect(() => {
    console.log("User set searchFilters")
    console.log(searchFilters)
  }, [searchFilters])

  const orgCount = useElasticSearch(endpoint,countQueries.org);
  const exhibCount = useElasticSearch(endpoint,countQueries.exhib);
  const artistCount = useElasticSearch(endpoint,countQueries.artist);

  return (
    <div className="row">
      <OrganizationHeroView orgCount={orgCount?.count ?? 0} exhibsCount={exhibCount?.count ?? 0} artistCount={artistCount?.count ?? 0} type={props.type} endpoint={props.endpoint} partialFilters={searchFilters} results={results} />
    </div>
  )
}

export { SearchView, IndexView, PageNotFoundView, AboutView, LocationLandingView, OrganizationLandingView };
