import React from 'react';

const TypeaheadResultsListItem = (props) => {

    var label = props.result._source.primary_title.label;
    const id = props.result._id;
    const pref = 'http://www.collectionbrowse.org';
    const url = ((id.substr(0, pref.length) === pref) ? id.substr(pref.length) : id);

    let connectedArtists = props.result._source.location_of ? props.result._source.location_of.filter( (artist,idx,self) => self.findIndex( thisArtist => artist.link === thisArtist.link ) === idx ).length : 0

    var anchorClassName = `location-typeahead-result-item`
    var anchorAttribs = {}

    switch (props.result._source.display_type.toLowerCase()) {
        case 'location':
            let connectedArtists = props.result._source.location_of.filter( (artist,idx,self) => self.findIndex( thisArtist => artist.link === thisArtist.link ) === idx ).length ?? 0
            label = props.result._source.classified_as.length > 0 ? `${label} (${props.result._source.classified_as[0].label}) - ${connectedArtists} artists` : label
            anchorClassName = `location-typeahead-result-item ${ connectedArtists === 0 ? 'disabled' : '' }`
        default:
            break;
    }

    function clickHandler(event,item) {
        // console.log(`click handler`,item)
        if (props.links ) {
            return
        } else if ( props.result._source.display_type.toLowerCase() === 'location' && connectedArtists === 0 ) {
            return
        } else {
            event.preventDefault();
            if (props.result._source.display_type.toLowerCase() === 'location') {

                if (props.result._source.classified_as.some( klass => klass.label === "County" )) {
                    props.selectCallback({county: props.result._source.primary_title.label})
                } else {
                    props.selectCallback({town: props.result._source.primary_title.label})                 
                }
                // FIXME: if the location is a county, select county
            } else {
                props.selectCallback(item)
            }

        }
    }

    function keyDownHandler(event,item) {
        console.log(item)
    } 

    return <li className="list-group-item" onClick={ (event) => clickHandler(event,props.result)} onKeyDown={ (event) => keyDownHandler(event,props.result) } >{ props.links ? <a className={anchorClassName} href={url}>{label}</a> : <a className={anchorClassName} href="#" >{label}</a>} </li>
}

const TypeaheadResultsList = (props) => {
    
    function selectCallback(item) {
        props.selectCallback(item)
    }

    return (
        <div className="typeahead-results-container">
            <ul className="list-group typeahead-results-list">
            {
                (props.results.length == 0) 
                    ? <li className="list-group-item">No results found</li>
                    : props.results.map( (hit, idx) => {
                        return <TypeaheadResultsListItem links={props.links} selectCallback={selectCallback} result={hit} />
                    })
            }
            </ul>
        </div>
    )
}

export default TypeaheadResultsList;