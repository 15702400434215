import React, {useState} from 'react';
import { DebounceInput } from 'react-debounce-input';

import { useElasticSearch, searchBarContextToQuery } from '../util';
import TypeaheadResultsList from './typeahead';

function SearchBar(props) {
    /* 
    A simple SearchBar component that just constructs a URI for us to load next after entry.
    The idea is that this is standalone such that running singly as a component is expected behavior. 
    FIXME: Do we need type-ahead suggestions?
        - Names
        - Locations
        - Text from lots of other places 
        Up to 3 of each, total of 7-9
    FIXME: Actually load the page when we hit enter / click
    */

    const [ searchBarType, setSearchBarType ] = useState( props.searchBarType ?? "main" )
    const [ endpoint, setEndpoint ] = useState(props.endpoint);
    const [ entityType, setEntityType ] = useState(props.type ?? "Location");
    const [ queryString, setQueryString ] = useState(props.queryString);
    const pageSize = 10;
    const [ query, setQuery ] = useState(null);
    const [ isOpen, setIsOpen ] = useState(false);
    const results = useElasticSearch(endpoint, query);

    function blurHandler(event) {
        // delay in case we're clicking a link in the typeahead results
        // -- don't want it to close too soon
        props.inputDidBlur(true)
        setTimeout(() => {
            setIsOpen(false);
        }, 500);
    }

    function focusHandler(event) {
        if (props.inputDidFocus) {
            props.inputDidFocus(true)        
        }
    }

    function changeHandler(event) {
        let newQueryString = event.target.value;
        // FIXME: We may need more than simple JSON safety?
        // console.log(newQueryString);

        if (event.keyCode === 13) { 
            event.preventDefault()

            setIsOpen(false);
            const searchUrl = new URL('/search')
            const params = new URLSearchParams();
            params.set('s',event.target.value)
            searchUrl.search = params.toString()

            console.log(`Going to page ${searchUrl.href}`)
            // window.location.href = 
        }

        setQueryString(newQueryString);

        if ( newQueryString.length > 2 || newQueryString.length === 0) {

            if (searchBarType === 'searchWithin') {
                selectResult(newQueryString)
            } else {
                if (props.clearError) {
                    props.clearError()
                }
                setIsOpen(true);
                setQuery(searchBarContextToQuery(newQueryString, entityType,pageSize));
            }

        } else {
            setIsOpen(false);
        }
    }

    function handleEnterKeypress(event) {

        event.preventDefault()

        setIsOpen(false);
        switch (searchBarType) {
            case 'facet': // FIXME: submit the whole durn queryString
            case 'main':
                selectResult();
                break;
            case 'searchWithin':
                selectResult(queryString);
                break;
            default:
                break;
        }

    }

    function selectResult(item) {

        switch (searchBarType) {
            case 'main':
                const searchUrl = new URL('/search',window.location.href)
                const params = new URLSearchParams();
                params.set('s',queryString)
                searchUrl.search = params.toString()
                window.location.href = searchUrl.href
                break;
            case 'facet':
                if (item) {
                    props.applyFilters( item )                
                } else {
                    props.applyFilters(queryString,[],true)
                }
                break;
            case 'searchWithin':
                props.applyFilters(item)
                break;
            default:
                break;
        }

    }

    // NB: DebounceInput has forceNotify* set to False because some weird race condition was happening on entering text and hitting enter quickly

    return <div className={props.searchBarClassName}>
        <form onSubmit={handleEnterKeypress}>
            <div>
                <label htmlFor="search" className="sr-only">Search</label>
                <DebounceInput type="text" placeholder="" autocomplete="off" name="search" debounceTimeout={35} forceNotifyByEnter={false} forceNotifyByBlur={false} onBlur={blurHandler} onChange={changeHandler} onFocus={focusHandler} />
                <button type="submit" className="btn btn-link searchbar-icon-button">
                <span className="icon icon-search"></span>
                <p className="sr-only">Submit Search</p>
                </button>
            </div>
        </form>
        { (isOpen && results) ? <TypeaheadResultsList selectCallback={selectResult} links={ searchBarType === "main" ? true : false } results={ (results.hits) ? results.hits.hits : [] } /> : ''}
    </div>

}

export default SearchBar;