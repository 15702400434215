import React, { useState, useEffect, useRef } from "react";

import { useElasticSearch, relationshipsContextToQuery } from "./util.js";
import { ActivityDatesLocationsView, ResultPill, iconFromClassification } from "./results";

function SimpleRelationshipsTable(props) {

  return <tr>
            <td className="relationship__table--name w-100" scope="row">
              <div
                className="relationship__table--name-simple"
              >
                  { props.data.map( (item,idx) => {
                    return <div key={idx}><h4>
                      <a> { item }</a>
                    </h4></div>
                  }) } 
              </div>
            </td>
  </tr>
}

function RelationshipsTableItem(props) {

  let ident = props.result._id ? new URL(props.result._id) : '';
  let itemLink =  props.result._id ? `${ident.pathname}/` : '';
  let itemImageLink =
    props.result?._source?.representation?.depicted[0]?.link?.replace(
      "http://www.collectionbrowse.org/DigitalObject",
      ""
    ) ?? '';
  let hasImage = props.result?._source?.representation?.depicted.length > 0;

  return <tr>
          <td className="relationship__table-col--thumbnail">
            <div
              className="d-block img-fluid rounded-circle img-row-thumbnail" // + landing-page__thumbnail?
              style={
                hasImage
                  ? {
                      backgroundImage: `url(${itemImageLink})`,
                    }
                  : {}
              }
            >
              {hasImage ? "" : <span className="icon icon-nouser"></span>}
            </div>

          </td>
          <td className="relationship__table-col--name" scope="row">
            <a
              className="d-flex flex-row align-items-center btn btn-link p-0 text-left"
              href={itemLink}
            >
              <div>
                <h4 className="relationship__table-name">
                  {props.result._source?.primary_title?.label ?? props.result }
                </h4>
                <h5 className="relationship__table-year">
                  {props.result?._source?.birth?.date_first?.slice(0, 4) ?? ""} -{" "}
            {props.result?._source?.death?.date_last?.slice(0, 4) ?? ""}

                </h5>
              </div>
            </a>
          </td>
          <td className="relationship__table-col--when-active">

            { props.result._source?.active?.sort((a, b) => (a.date_label > b.date_label) ? 1 : -1).map( (activity,idx) => {
              return <React.Fragment key={idx}>
                      <span className="location">{activity.location.label}</span><br />
                      <span className="date-range">{activity.date_label}</span><br /> 
                </React.Fragment>
            }) ?? '' }
          </td>
          <td className="relationship__table-col--information">
            <div className="d-flex justify-content-between">
              <ResultPill classification={props.result._source?.gender?.label ?? ""}
/>
              {
                props.result._source?.artist_role?.map( role => {
                  return <button
                    className="btn btn-blank rounded-circle p-2"
                    type="button"
                  >
                    <span className={`icon ${ iconFromClassification(role)[0] } icon-lg`} ></span>
                    <span className="sr-only">{role.label}</span>
                  </button>

                }) ?? ''
              }
            </div>
          </td>

        </tr>

}

function RelationshipsTableSection(props) {

  return             <div className="row row-striped px-5">
              <div className="col-12 col-md-3 p-2">
                <h3 className="relationsip__section-title">
                  {
                    props.subhead ? <React.Fragment><small className="h-subhead">{props.subhead_1}</small><br />
                  {props.subhead_2}</React.Fragment> : props.title

                  }
                </h3>
              </div>

              <div className="col">


                <table className="table relationship__table">
                  <thead className="sr-only">
                                  {
                  props.simple ? 
                    ( <tr>
                      <th className="relationship__table-col--name">Person</th>
                      <th className="relationship__table-col--when-active">
                      </th>
                    </tr>
)
                    :
                    ( <tr>
                      <th className="relationship__table-col--thumbnail">
                        Headshot
                      </th>
                      <th className="relationship__table-col--name">Person</th>
                      <th className="relationship__table-col--when-active">
                        Dates
                      </th>
                      <th className="relationship__table-col--information">
                        Actions
                      </th>
                    </tr>
)
                }

                  </thead>
                  <tbody>
                    {
                     props.simple ? <SimpleRelationshipsTable data={props.data} /> : props.data.slice(0,props.limit).map( item => <RelationshipsTableItem result={item} /> ) 
                    }
                  </tbody>
                </table>
                              {
                props.data.length > props.limit && props.filterTerm && props.facet ? 
              <div className="relationship__table-collapse-button-row">
                    <a href={`/search?f.${props.facet}=${props.filterTerm}`}>View all {props.data.length}</a>
                </div>
                : ''
              }

              </div>
            </div>
}

function RelationshipsTab(props) {

  const tabEl = useRef(null);
  let [ endpoint, setEndpoint ] = useState(props.endpoint);
  let [ ident, setIdent ] = useState(props.ident);
  let [ associatedPeople, setAssociatedPeople ] = useState(props.associatedPeople)
  let [ associatedPeopleLabels, setAssociatedPeopleLabels ] = useState(props.associatedPeople)
  let [ activeLocations, setActiveLocations ] = useState(props.activeLocations)
  let [ query, setQuery ] = useState(relationshipsContextToQuery(props.ident, props.activeLocations, props.associatedPeople))
  let [ people, setPeople ] = useState({})
  let [ locations, setLocations ] = useState({})

  let results = useElasticSearch(endpoint,query)

  useEffect(() => {
    if ( results === null ) {
      return 
    } 

    var resultPeople = {};

    for (let person of associatedPeople ) {

      let otherAssociates = results.hits.hits.filter( item => item._source.related.some( entity => entity.link === person ) && item._id !== ident )

      var associateLabel = ''
      otherAssociates.forEach( assoc => {
        assoc._source.related.forEach( entity => {
          if (entity.link === person ) {
            associateLabel = entity.label
          }
        })
      })
      if (associateLabel !== '' && otherAssociates.length > 0) {
        resultPeople[associateLabel] = otherAssociates       
      }
    }

    setPeople(resultPeople)

    var resultLocations = {};
    for ( let location of activeLocations ) {
      let peopleAtLocation = results.hits.hits.filter( item => item._source.active.some( activity => activity.location.link === location ) && item._id !== ident )
      var locationLabel = ''
      peopleAtLocation.forEach( person => {
        person._source.active.forEach( activity => {
          if (activity.location.link === location) {
            locationLabel = activity.location.label
          }
        })
      })

      resultLocations[locationLabel] = peopleAtLocation

    }

    setLocations(resultLocations)

    let self = results.hits.hits.filter( item => item._id === ident )
    if ( self.length > 0 ) {
      let peopleLabels = self[0]._source.related.map( rel => rel.label )
      setAssociatedPeopleLabels(peopleLabels)
    }

  }, [results])

  return <React.Fragment>
            { /* PRINT ONLY: */ }
            <div className="morem-pr-block morem-pr-artist-tabs-heading mx-5">
              <h2>
                Relationships
                <span className="badge badge-info rounded-circle ml-2">{associatedPeople.length}</span>
              </h2>
            </div>
            { /* end PRINT ONLY */ }
            { associatedPeopleLabels.length > 0 ? <RelationshipsTableSection title="Related People" data={associatedPeopleLabels} simple={true} /> : '' } 

            { Object.entries(people).map( ([person,personResults]) => {
                return <RelationshipsTableSection limit={100} subhead={true} subhead_1="Other Artists Associated with" subhead_2={person} data={personResults}/>            
              }) }
        </React.Fragment>
}

export {RelationshipsTab};
