import React, {useState, useRef, useEffect, useContext} from 'react';

/* REGIONS */
// import { ReactComponent as KCRegionMap } from '../svg/map/Kansas City.svg';
// import { ReactComponent as CentralRegionMap } from '../svg/map/Central.svg';
// import { ReactComponent as NortheastRegionMap } from '../svg/map/Northeast.svg';
// import { ReactComponent as NorthwestRegionMap } from '../svg/map/Northwest.svg';
// import { ReactComponent as OzarkRegionMap } from '../svg/map/Ozark.svg';
// import { ReactComponent as SoutheastRegionMap } from '../svg/map/Southeast.svg';
// import { ReactComponent as SouthwestRegionMap } from '../svg/map/Southwest.svg';
// import { ReactComponent as StLouisRegionMap } from '../svg/map/St. Louis.svg';

/* STATE */
import { ReactComponent as MissouriRegions } from '../svg/map/MissouriRegions.svg';

let REGION_HIGHLIGHT_COLOR = "#7EDCE2";
let REGION_FILL_COLOR = "#2471A7";
let REGION_STROKE_COLOR = "#AFBFCC";

function MapFacetView(props) {
    return <div className="h-100 w-100 svg-map">
        <h4 className="mb-0">{props.title}</h4>
        <FacettedMapView dataSource={props.dataSource} filters={props.filters} minYear={props.minYear} maxYear={props.maxYear} />
    </div>
}

function MapHeroView(props) {

    const svgRef = useRef(null);
    const [ focused, setFocused ] = useState('')
    const [ selected, setSelected ] = useState('')
    const [ focusedLabel, setFocusedLabel ] = useState('')
    const [ focusedCount, setFocusedCount ] = useState(0)
    const [ toolTipX, setToolTipX ] = useState(0)
    const [ toolTipY, setToolTipY ] = useState(0)

    const regionColors = {
        "St. Louis": '#45536F',
        "Southeast": "#DBD3C3",
        "Ozark": "#5F90B9",
        "Central": "#AFBFCC",
        "Northeast": '#B5A786',
        "Southwest": '#A0A8B7',
        "Northwest": '#244B7C',
        "Kansas City": '#2471A7'
    }

    useEffect(() => {
        setSelected(props.filters.region ?? '')
    },[props.filters])

    useEffect(() => {
        // Adds the focus handler and unset to each region element

        let map = svgRef.current?.getElementById('map');

        if (map) {
            // console.log(map)
            map.onmouseleave = () => { setFocused(''); setToolTipX(0); setToolTipY(0); }

            map.querySelectorAll("g").forEach( el => {

                el.style['pointer-events'] = "bounding-box"
                el.style['cursor'] = 'pointer'

                el.onmouseenter = focusHandler
                el.onclick = clickHandler
                // el.onmouseleave = () => { console.log('sup') ; setFocused('') ; setToolTipX(0) ; setToolTipY(0) }

                let {x,y, width,height} = el.getBBox()
                let centerX = x + ( width / 2 )
                let centerY = y + ( height / 2 )
            })
        }
    }, [svgRef])

    useEffect(() => {
        // If we've got an SVG onscreen, populates the label and positions the tooltip...
        let el = svgRef.current?.getElementById(focused)

        // And then set the colors based on focused..
        Object.entries(regionColors).forEach(([region,color]) => {
            let el = svgRef.current?.getElementById(region)

            // This is the <g> element grouping paths, so descend children and set <path> els to stroke / fill
            el.childNodes.forEach( childEl => {
                if (childEl.tagName.toLowerCase() === 'path') {
                    childEl.style.fill = ( el.id === focused || el.id === selected ) ? REGION_HIGHLIGHT_COLOR : regionColors[el.id]
                    childEl.style.stroke = ( el.id === focused || el.id === selected ) ? REGION_HIGHLIGHT_COLOR : regionColors[el.id]
                }

            })

            if (el.id === focused) {

                let {x,y,width,height} = el.getBBox()
                let centerX = x + ( width / 2 )
                let centerY = y + ( height / 2 )
                // console.log(`Path bbox is ${x},${y},${width},${height} and center is ${centerX},${centerY}`)

                setFocusedLabel(el.id)

                let focusedBucket = props.results?.aggregations?.active_regions?.buckets?.filter( buck => buck.key.label == focused ) ?? []
                if ( focusedBucket.length > 0 ) {
                    setFocusedCount( focusedBucket[0].doc_count )
                } else {
                    setFocusedCount( 0 )
                }

                setToolTipX(centerX)
                setToolTipY(centerY)

            }


        })

    }, [focused, selected, props.results, svgRef])

    function focusHandler(event) {
        event.preventDefault()
        event.stopPropagation()
        let ident = event.target.id ?? ''
        setFocused(ident)
    }

    function clickHandler(event) {
        // This handler typically fires on a <g> element defining the paths that make up a region
        // Since <g> tags don't typically receive click events we find the parent <g> ourselves

        let parent = event.target.parentElement
        if (parent.tagName.toLowerCase() === 'g') {
            event.preventDefault()
            event.stopPropagation()
            let ident = parent.id ?? ''

            setSelected( prevSelected => prevSelected !== ident ? ident : '' )
            props.selectionHandler({region: ident})

        }
    }

    return <svg width="100%" style={{maxWidth: '450px'}} viewBox="0 -32 400 320">
            <MissouriRegions {...props} ref={svgRef} />
            <g><Tooltip style={{pointerEvents: 'none'}} x={toolTipX} y={ toolTipY } constant={80} show={ toolTipX !== 0 && toolTipY !== 0 } count={focusedCount} label={focusedLabel} filters={props.filters} minYear={props.minYear} maxYear={props.maxYear} /></g>
        </svg>

}

function KCRegionPath(props) {

    return  <g id="map/Kansas City" onMouseEnter={ props.onFocusEnter } onMouseLeave={ props.onFocusExit }>
                <path d="M29 62L51.2285 62.5068L51.1565 66.9955L50.7968 84.371L42.3083 81.7647L34.7549 68.8778L29 62Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="Platte x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M51.1566 66.9954L68.4933 67.285L68.2056 81.837L66.9826 80.4614L66.7668 80.2442L66.3352 80.0994L64.8245 80.027L64.321 80.2442L64.0332 80.6062L63.6735 81.2578L63.3858 82.2714L62.7384 83.1402L61.3716 84.4433L56.8396 86.5429L50.5811 87.0497L50.8688 84.2985L51.1566 66.9954Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="Clay x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M88.4918 82.0543L93.2396 79.6652L96.189 80.0996L101.009 77.0588L100.074 97.6199V98.4887L84.9669 97.8371L84.8949 99.5747L72.3779 98.9231L72.9534 85.6018L73.8167 86.1086L74.4641 86.181L75.0396 86.1086L75.5431 85.819L76.5503 85.0226L76.4783 84.3711L76.3345 84.1539L78.8522 82.1991L85.1107 81.4751L88.4918 82.0543Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="Lafayette x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M73.0252 85.6743L72.3778 98.9231L72.09 103.846L50.2932 102.76L50.509 91.1765L50.581 87.1222L56.8395 86.6154L61.3715 84.5159L62.7383 83.2127L63.3857 82.344L63.6735 81.3304L64.0331 80.6788L64.3209 80.3168L64.8244 80.0996L66.3351 80.172L66.7667 80.3168L66.9825 80.534L68.2055 81.9096L73.0252 85.6743Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="Jackson x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M100.001 98.561L99.2821 120.425L74.464 119.556L72.162 103.918L72.4497 98.9954L84.8948 99.647L84.9667 97.9094L100.001 98.561Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="Johnson x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M99.2824 120.353L99.2104 122.887L98.6349 140.842L74.8958 140.262L74.3203 126.579L74.4642 119.484L99.2824 120.353Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="Henry x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M50.2933 102.76L72.1621 103.919L74.4641 119.557L74.3202 126.652L67.8459 126.507L65.3281 126.145L62.7384 125.059L49.6459 124.48L50.0775 109.204L50.2933 102.76Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="Cass x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M74.8237 140.262L98.5628 140.842L99.4261 148.733L98.9945 158.072L97.8435 158L96.3328 160.534L96.1889 163.068L93.6712 162.995L85.3984 162.633L85.5423 159.231L73.8885 158.652L74.3202 150.688L74.8237 140.262Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="St. Clair x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M49.6459 124.48L62.6664 125.059L65.2561 126.145L67.7739 126.507L74.2482 126.652L74.8237 140.335L74.3202 150.76L70.9391 149.457L67.9178 149.602L66.1194 149.819L49.2142 149.023L49.5739 129.765L49.6459 124.48Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="Bates x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M74.3202 150.688L73.8886 158.652L73.3131 174L48.6388 172.842V171.683L49.1423 150.326V148.95L66.0475 149.747L67.8459 149.529L70.8673 149.385L74.3202 150.688Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="Vernon x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M99.426 148.805L119.281 149.457L118.921 158.869L113.669 164.733L96.1888 163.213L96.3327 160.679L97.8434 158.145L98.9944 158.217L99.426 148.805Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="Hickory x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M99.2104 122.959L109.138 123.394L109.21 121.656L113.957 121.728L119.281 121.946L118.777 137.801L119.353 149.457L99.4981 148.805L98.6349 140.914L99.2104 122.959Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="Benton x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M100.073 97.6924L108.778 98.1268L117.986 98.4164L120 98.5612L119.568 112.389L119.209 121.946L113.885 121.729L109.138 121.656L109.066 123.394L99.1383 122.959L99.2102 120.425L99.9296 98.5612L100.073 97.6924Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } id="Pettis x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#2471A7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <HotSpot id="kc_hotspot" x="75" y="115" label={"Kansas City"} count={props.count} focused={props.focused} filters={props.filters} minYear={props.minYear} maxYear={props.maxYear} />
            </g>
}

function CentralRegionPath(props) {

    return <g id="map/Central" onMouseEnter={ props.onFocusEnter } onMouseLeave={ props.onFocusExit }>
                <path d="M117.391 72L128.652 81.1785L124.875 90.5721L119.743 98.5316L117.747 98.3882L108.624 98.1013L100 97.6711L100.927 77.3063L103.706 80.82L103.991 81.1068L104.562 81.2502L106.343 80.8917L106.842 80.6048L109.479 76.4458L113.613 72.9322L115.538 72.2868L117.391 72Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Saline x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M134.853 75.5852L147.112 79.7442L141.553 95.95H131.503L128.795 95.3763L128.296 95.1612L126.086 93.5119L125.73 93.01L124.803 90.8588V90.5002L128.581 81.1066L134.853 75.5852Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Howard x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M124.875 90.572V90.9306L125.801 93.0818L126.158 93.5837L128.367 95.233L128.866 95.4481L131.575 96.0218H141.624L144.261 98.8183L140.413 108.57L139.486 111.152L139.13 112.443L133.428 112.801H129.222L119.458 112.228L119.886 98.5315L124.875 90.572Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Cooper x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M152.244 79.8879L161.51 80.1747L161.367 90.4288H160.726L160.441 90.9307L159.3 99.3204L158.801 107.782L156.378 111.726L156.592 114.953L148.823 109.431L144.19 98.8185L141.553 96.0219L147.112 79.8162L152.244 79.8879Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Boone x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M148.894 109.431L156.663 114.952L166.143 119.04L158.017 132.807L155.523 133.596L148.466 132.592L148.68 127.716L144.618 127.573L148.894 109.431Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Cole x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M144.19 98.8184L148.823 109.431L144.475 127.573L138.631 127.429L128.937 117.821L129.009 112.73H133.214L138.916 112.371L139.272 111.08L140.199 108.499L144.19 98.8184Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Moniteau x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M119.316 112.156L129.08 112.729L129.009 117.821L138.702 127.429L135.566 139.548L132.501 141.054H123.592L118.46 137.325L118.959 121.621L119.316 112.156Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Morgan x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M144.475 127.573L148.538 127.716L148.324 132.592L155.38 133.596L157.875 132.807L157.803 135.317V142.918H158.516L158.445 151.38L148.538 151.164L144.546 151.093L141.981 149.372L141.197 148.511L139.985 145.93V144.281L139.629 142.488L137.989 140.05L135.495 139.548L138.631 127.429L144.475 127.573Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Miller x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M135.566 139.548L138.061 140.05L139.7 142.488L140.056 144.281V145.93L141.268 148.511L142.052 149.372L144.618 151.093L148.609 151.165L148.538 160.558L145.83 162.423L142.693 163.928L142.266 164L141.553 163.713L139.914 161.705L139.272 160.343L138.987 159.052L139.058 158.909L138.987 158.694L128.367 158.479L118.603 158.12L118.959 148.798L118.389 137.253L123.521 140.982H132.43L135.566 139.548Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Camden x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M182.536 135.246L183.32 143.133V149.085H176.905L171.488 149.228L169.564 150.089L165.857 151.666L158.516 151.451L158.587 142.99H157.875V135.389L165.501 135.532L171.773 135.389L182.536 135.246Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Maries x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M182.108 111.152L182.393 111.08L182.536 135.246L171.844 135.317L165.572 135.461L157.946 135.317L158.017 132.808L166.142 119.04L167.924 117.749L171.559 114.451L171.916 113.877L172.272 113.518L172.7 113.303L178.544 111.009L179.97 110.865L182.108 111.152Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Osage x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M161.296 90.4287L182.393 90.5004L182.108 111.224L179.97 111.009L178.544 111.152L172.7 113.447L172.272 113.662L171.916 114.02L171.559 114.594L167.924 117.892L166.143 119.183L156.663 115.096L156.449 111.869L158.873 107.925L159.371 99.4638L160.512 91.0741L160.797 90.5721L161.296 90.4287Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Callaway x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M198.858 85.4809V94.014L199 102.691H192.3V110.578L182.465 111.08L182.179 111.152L182.465 90.4287L192.3 85.4092L198.858 85.4809Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Montgomery x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M194.439 111.224L194.652 122.267L194.937 132.592L195.009 139.691L187.596 143.133H183.32L182.536 135.246L182.393 111.08L192.229 110.578L194.439 111.224Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } id="Gasconage x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#AFBFCC" } strokeLinejoin="round" strokeWidth="0.8"/>
                <HotSpot id="central_hotspot" x="140" y="105" label={"Central"} count={props.count} focused={props.focused} filters={props.filters} minYear={props.minYear} maxYear={props.maxYear} />
            </g>
}

function NortheastRegionPath(props) {

    return <g id="map/Northeast" onMouseEnter={ props.onFocusEnter } onMouseLeave={ props.onFocusExit }>
                <path d="M152.056 74.6633L178.39 74.8764V75.7288H185.01L190.91 75.9419L192.421 86.028L182.491 91.0001L161.194 90.9291L161.338 80.7719L151.984 80.4878L152.056 74.6633Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Audrain x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M105.288 15.0694L117.592 14.9274H127.737V17.4134V35.0287L105.432 35.2418L105.288 21.8172V15.0694Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Sullivan x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M133.852 3.20752L134.572 6.0487L134.14 11.518L135.291 17.4134L127.737 17.4845V14.9984H117.591L105.288 15.0694L105 3.70473L117.088 3.56267L133.852 3.20752Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Putnam x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M149.754 2.63928L149.898 17.0583L135.22 17.3424L134.068 11.4469L134.5 5.97767L133.781 3.13649L137.09 3.06546L149.754 2.63928Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Schuyler x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M149.898 17.1293L150.041 19.6863L150.257 34.9577L128.096 35.0287H127.737V17.4134L135.291 17.3424L149.898 17.1293Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Adair x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M150.185 34.8866L152.776 39.7876L152.416 59.8179H152.344L135.004 59.6049L127.665 54.4197L128.096 34.9576L150.185 34.8866Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Macon x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M152.344 59.8181L152.056 74.6632L151.912 80.4877L146.732 80.3456L134.356 76.2259L135.004 59.605L152.344 59.8181Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Randolph x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M178.246 56.4797L178.39 74.8053L152.056 74.5922L152.344 59.7471H152.416H157.38V56.3376L172.346 56.4797H178.246Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Monroe x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M167.526 39.7877H172.49L172.418 56.4796L157.452 56.3376V59.747H152.488L152.776 39.7877H167.526Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Shelby x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M149.969 19.6154H167.454V22.1014L167.526 39.7877H152.776L150.185 34.8867L149.969 19.6154Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Knox x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M167.526 2L167.454 19.5443H149.969L149.826 16.9872L149.682 2.56824L157.165 2.35515L167.526 2Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Scotland x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M177.455 2.28487L178.318 2.8531L181.7 5.55222L181.772 5.90737L181.628 7.11487L181.772 7.54105L186.017 13.0103L187.816 14.5019L190.694 14.7861L187.24 22.3152L167.526 22.1021V19.6161L167.598 2.07178L177.455 2.28487Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Clark x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M187.168 22.3144L186.809 25.0846L189.471 39.7877L172.49 39.8587H167.526L167.454 22.1724L187.168 22.3144Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Lewis x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M189.471 39.7877L193.572 50.5132L196.09 54.7039L178.318 56.5507L172.49 56.6217L172.562 39.9298L189.471 39.7877Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Marion x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M178.246 56.4796L196.018 54.6328L202.134 59.5339H200.983L189.759 68.1995L190.838 75.7996L184.938 75.5865H178.318V74.7342L178.246 56.4796Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Ralls x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M202.134 59.5339L212.423 70.8276L223 80.7007L210.337 80.9848H202.35V86.099H199.04L192.421 86.0279L190.91 75.9417L189.831 68.3416L200.983 59.605L202.134 59.5339Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } id="Pike x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#B5A786" } strokeLinejoin="round" strokeWidth="0.8"/>
                <HotSpot id="northeast_hotspot" x="145" y="30" label={"Northeast"} count={props.count} focused={props.focused} filters={props.filters} minYear={props.minYear} maxYear={props.maxYear} />
            </g>
}

function NorthwestRegionPath(props) {

    return <g id="map/Northwest" onMouseEnter={ props.onFocusEnter } onMouseLeave={ props.onFocusExit }>
                <path d="M1 1L18.1818 1.71786L25.6898 2.00501L25.6176 7.74791H26.7005L26.5562 13.2755L26.2674 20.4541L9.87968 19.8798L9.95187 18.803V18.7312V18.6594L9.87968 18.3723L6.91979 15.2855L4.24866 8.03506L4.03209 7.03005L4.53743 6.59933L5.54813 4.94825L3.95989 3.36895L3.38235 4.58932L1 1Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Atchison x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M26.1953 20.454L30.3824 20.5976L31.2487 22.3923L31.1044 22.6076L30.8878 24.4023L31.3931 27.3455L31.6097 27.7762L32.1151 28.207L33.9921 41.631L32.4038 43.2821L32.3316 43.3539L32.0429 43.5692L31.8263 43.7128L30.1659 43.7846H29.9493H29.8049L28.9386 43.4257L27.8557 42.9949L25.7621 41.5592L20.5643 36.5342L20.2755 36.1035V35.5292L9.87976 20.0951V19.9515L26.1953 20.454Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Holt x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M51.2461 46.7279L51.1739 50.9633L51.0295 63.4542L28.722 62.9516L30.6712 57.7112L31.2488 56.9934L32.837 54.8398L33.0536 54.6244L36.3744 51.8966L37.313 51.753L38.3236 49.743L38.0349 48.1637L37.6017 46.4408L51.2461 46.7279Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Buchanan x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M51.3903 28.8531L51.3182 33.9499L51.246 46.7279L37.6016 46.4407L34.0641 41.7028L32.1871 28.2788L51.3903 28.8531Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Andrew x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M38.3236 2.50751L50.7407 2.93823L52.0402 13.7062L51.4626 28.8531L32.1872 28.2788L31.6819 27.8481L31.4653 27.4174L30.8878 24.4023L31.1765 22.6077L31.3209 22.3923L30.3824 20.5977L26.1953 20.4541L26.484 13.2755L26.6284 7.74791H25.5455L25.6177 2.005L38.3236 2.50751Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Nodaway x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M57.7433 3.08181L68.2112 3.15359L68.861 14.0651L51.9679 13.7062L50.6685 2.93823L57.7433 3.08181Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Worth x2C  NO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M51.968 13.7062L68.8611 14.0651L68.6445 28.5659L68.4279 34.4524L51.3905 33.9499L51.4627 28.8531L51.968 13.7062Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Gentry x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M68.4278 34.4525L68.7888 48.7379L68.7166 51.2505L51.1738 50.9633L51.246 46.7279L51.3182 33.95L68.4278 34.4525Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="DeKalb x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M68.7887 51.2504L68.5 63.9566L68.3556 68.192L50.9572 67.9048L51.0293 63.4541L51.1737 50.9633L68.7887 51.2504Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Clinton x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M68.7888 48.7379L73.7701 48.9533L82.7942 49.0251L88.5696 49.1686L88.4974 59.2187L88.4252 64.3155L68.4279 63.9566L68.7166 51.2504L68.7888 48.7379Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Caldwell x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M88.5696 28.9248V39.0467L88.6418 49.2404L82.8664 49.0968L73.8423 49.025L68.7888 48.8097L68.4279 34.4524L68.6444 28.5659L88.5696 28.9248Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Daviess x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M88.353 3.08179L88.7139 21.3155L88.6417 28.9249L68.6444 28.5659L68.9332 14.1369L68.2834 3.15357H77.8129L88.353 3.08179Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Harrison x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M97.8824 2.93823H105.896L106.185 14.424L106.112 21.2437L88.6417 21.3155L88.2808 3.08181L97.8824 2.93823Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Mercer x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M106.112 21.3156L106.257 34.8832V38.6878L88.5696 39.0468V28.9249L88.6418 21.3156H106.112Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Grundy x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M106.257 38.6879L106.185 43.7847L106.112 54.0501H110.444L109.939 59.147L88.5696 59.2905L88.6418 49.2405L88.5696 39.0468L106.257 38.6879Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Livingston x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M88.5694 59.2905L109.938 59.147L117.663 72.6428L115.786 72.7146L113.837 73.3607L109.65 76.8782L106.979 81.0418L106.473 81.3289L104.668 81.6879L104.091 81.5443L103.802 81.2572L100.987 77.7396L96.1497 80.7547L93.1898 80.3239L88.425 82.6929L88.4972 64.2438L88.5694 59.2905Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Carroll x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M88.4972 64.3873L88.425 82.8363L85.1763 82.3338L78.8956 83.0517L76.3688 84.9899L76.5132 85.2053L76.5854 85.8514L75.5747 86.641L75.0694 86.9282L74.4918 86.9999L73.8421 86.9282L72.9758 86.4257L68.2111 82.6928L68.4998 68.2637L68.6442 64.0283L88.4972 64.3873Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Ray x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M128.636 54.2654L136 59.5058L135.35 76.3038L129.07 81.9031L117.663 72.7145L109.939 59.2187L110.444 54.1218L128.636 54.2654Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Chariton x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M128.636 34.6677H128.997L128.564 54.3372L110.372 54.1218H106.04L106.185 43.7846L106.257 38.6878V34.8831L128.636 34.6677Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } id="Linn x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#244B7C" } strokeLinejoin="round" strokeWidth="0.8"/>
                <HotSpot id="northwest_hotspot" x="60" y="20" label={"Northwest"} count={props.count} focused={props.focused} filters={props.filters} minYear={props.minYear} maxYear={props.maxYear} />
            </g>
}

function OzarkRegionPath(props) {

    return  <g id="map/Ozark" onMouseEnter={ props.onFocusEnter } onMouseLeave={ props.onFocusExit }>
                <path d="M157.854 150.367L165.275 150.585L165.131 174.667H155.116V172.047L147.623 168.191L147.767 159.606L147.839 150.076L157.854 150.367Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } id="Pulaski x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M135.231 182.088L155.116 182.524L155.188 195.402L155.404 197.584L155.332 206.97L135.375 206.606L135.663 195.038H135.087L135.231 182.088Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } id="Wright x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M162.753 206.969L161.817 222.466L131.7 221.957L125 221.739L125.288 206.315L135.231 206.533L155.188 206.897L162.753 206.969Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } id="Douglas x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M131.772 221.957L161.888 222.466L161.672 239.855H160.303L142.796 240H131.556L131.772 221.957Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } id="Ozark x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M182.638 207.188V216.646V222.612L182.206 233.379L182.278 239.636L161.528 239.927L161.744 222.539L162.681 207.042H167.652L173.344 207.261L182.638 207.188Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } id="Howell x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M165.203 174.74L175.29 174.812H177.739L177.883 185.143H182.855L182.783 207.188H173.56L167.869 206.97H162.897L155.404 206.824L155.476 197.439L155.26 195.256L155.188 182.379L155.26 174.594L165.203 174.74Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } id="Texas x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M182.927 142H187.249L187.826 163.463H175.217V174.812L165.131 174.739L165.275 150.658L169.021 149.057L170.967 148.184L176.442 148.039H182.927V142Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } id="Phelps x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M205.045 168.555L205.117 175.03L197.913 174.885V180.05L202.596 180.123L202.668 185.216L182.782 185.143H177.811L177.667 174.812H175.217V163.462H187.826L197.84 163.39V168.555H205.045Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } id="Dent x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M182.783 185.143L202.668 185.216H204.325L208.72 199.258L211.89 203.841L207.639 204.496L202.74 204.641L202.668 216.573L182.639 216.646V207.188L182.783 185.143Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } id="Shannon x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M182.711 216.646L202.74 216.573L207.711 220.065L207.567 239.345L194.598 239.636H192.581L182.278 239.709L182.206 233.452L182.638 222.684L182.711 216.646Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } id="Oregon x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M207.711 220.065H211.025L218.158 220.502L228.533 220.356L233 238.691L223.346 238.909L207.495 239.273L207.711 220.065Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } id="Ripley x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M211.962 203.914L222.985 206.387L227.812 213.59L228.749 220.356L218.374 220.502L211.241 220.065H207.927L202.956 216.573L203.028 204.641L207.927 204.496L211.962 203.914Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } id="Carter x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#5F90B9" } strokeLinejoin="round" strokeWidth="0.8"/>
                <HotSpot id="ozark_hotspot" x="160" y="190" label={"Ozark"} count={props.count} focused={props.focused} filters={props.filters} minYear={props.minYear} maxYear={props.maxYear} />
            </g>
}

function SoutheastRegionPath(props) {

    return <g id="map/Southeast" onMouseEnter={ props.onFocusEnter } onMouseLeave={ props.onFocusExit }>
                <path d="M273.799 216.231L281.778 215.361L291.208 228.992L287.363 236.968L284.172 237.185L284.68 236.315L284.752 236.17L284.825 235.952L284.97 235.372L285.042 235.227V235.01V234.937L284.897 234.285L284.607 233.632V233.56L284.317 233.052L284.244 232.98L284.172 232.907L284.027 232.835L283.301 232.617L281.996 232.4L281.125 232.472L280.835 232.617L280.255 233.342L280.182 233.415L280.11 233.632L280.037 233.922L279.965 234.14L280.037 234.285L280.11 234.575V234.647L280.618 235.735L280.69 235.807L281.706 237.33L281.778 246.828L279.457 242.986L278.514 242.551L275.105 243.058L271.623 244.291L264.442 244.508L262.194 244.436L261.831 230.297L273.872 230.007L273.219 216.376L273.799 216.231Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="New Madrid x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M281.706 246.756L278.079 255.747L273.582 266.767L262.991 267.203L262.194 244.291L264.442 244.363L271.623 244.146L275.105 242.913L278.514 242.406L279.457 242.841L281.706 246.756Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Pemiscot x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M205.108 175.265L214.103 174.177L222.88 174.032L222.952 185.053L224.04 193.537L224.838 193.464L224.983 196.074L224.911 199.627L223.097 202.527L223.17 206.515L212.072 204.05L208.88 199.482L204.456 185.488H202.787L202.715 180.413L198 180.34V175.193L205.108 175.265Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Reynolds x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M228.32 166.057L233.47 171.277L233.325 190.419L224.838 193.392L224.04 193.464L222.952 184.981L222.88 173.96L214.103 174.105L205.108 175.193L205.036 168.74L207.43 166.202L228.32 166.057Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Iron x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M228.102 145.973L238.402 147.713L242.754 151.121L236.734 157.356L240.361 161.054L248.92 169.32L250.951 167.507L252.982 169.102L251.386 170.915L233.542 171.35L228.392 166.129L228.102 145.973Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="St. Francois x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M247.904 144.74L260.453 156.921L252.909 169.175L250.878 167.579L248.847 169.392L240.288 161.126L236.661 157.429L242.682 151.193L238.33 147.785L245.656 143L247.904 144.74Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Sainte Genevieve x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M233.325 190.491L248.63 190.201L248.775 203.615L253.997 203.47L254.07 206.08L247.469 213.258L228.03 213.548L223.17 206.37L223.097 202.382L224.911 199.482L224.983 195.929L224.838 193.319L233.325 190.491Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Wayne x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M247.397 213.403L249.5 219.421L251.023 223.046L251.313 223.191L251.749 223.481L253.199 226.454L253.707 228.557L253.562 229.717L253.127 230.442L250.008 238.345L233.47 238.78L228.973 220.509L228.03 213.766L247.397 213.403Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Butler x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M261.831 230.225L262.194 244.363L262.992 267.275L247.542 267.855L243.842 268L251.458 255.674L256.681 251.324L257.044 250.816L257.551 249.511L257.406 244.871L256.971 244.218L252.982 238.2H251.168L249.935 238.273L253.054 230.37L261.831 230.225Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Dunklin x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M265.168 200.86L269.81 200.715L270.535 206.153L271.406 207.458L272.131 208.69L273.074 211.155L273.727 213.186L273.799 216.303H273.219L273.872 229.934L261.831 230.224L253.054 230.369L253.489 229.644L253.634 228.484L253.127 226.382L251.676 223.409L251.241 223.119L250.951 222.974L249.427 219.349L247.324 213.331L253.925 206.153L258.204 206.08L260.96 205.137L260.815 200.86H265.168Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Stoddard x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M264.515 173.017L265.168 200.86L260.888 200.932L261.033 205.21L258.277 206.152L253.997 206.225L253.925 203.615L248.702 203.76L248.557 190.346L251.894 190.419L251.821 176.933L251.313 173.67L264.515 173.017Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Bollinger x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M260.453 156.921L272.639 160.764L280.11 174.105V174.468L264.442 173.09L251.313 173.597L251.241 170.987L252.837 169.175L260.453 156.921Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Perry x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M264.515 173.017L280.182 174.395V174.902L280.4 176.063L281.633 178.528L282.431 179.833L283.591 181.065L284.172 181.791L284.825 183.458V183.531V183.603L285.042 184.618V184.691V184.763L284.825 186.576L284.607 187.156L284.534 187.228L284.462 187.301L284.389 187.373L283.882 187.664L282.213 188.026L282.141 193.174L269.81 200.715L265.167 200.86L264.515 173.017Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Cape Girardeau x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M282.286 193.102L283.374 194.842L286.058 200.207L287.581 204.267L290.555 206.878L281.706 215.433L273.727 216.303L273.654 213.186L273.002 211.156L272.059 208.69L271.333 207.458L270.463 206.153L269.738 200.715L282.286 193.102Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Scott x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M290.555 206.805L299.404 208.038L301 210.213L299.041 216.013L297.736 216.738L297.663 218.116L300.275 219.639L298.244 227.687L291.208 229.064L281.778 215.433L290.555 206.805Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Mississippi x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M251.313 170.915L251.386 173.525L251.894 176.788L251.966 190.274L248.63 190.201L233.325 190.491L233.47 171.35L251.313 170.915Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } id="Madison x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#DBD3C3" } strokeLinejoin="round" strokeWidth="0.8"/>
                <HotSpot id="southeast_hotspot" x="235" y="185" label={"Southeast"} count={props.count} focused={props.focused} filters={props.filters} minYear={props.minYear} maxYear={props.maxYear} />
            </g>
}

function SouthwestRegionPath(props) {

    return <g id="map/Southwest" onMouseEnter={ props.onFocusEnter } onMouseLeave={ props.onFocusExit }>
                <path d="M74.1623 157L85.7109 157.581L85.5683 160.994L93.7663 161.357L94.194 176.461L73.4495 175.735L73.5207 172.322L74.1623 157Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Cedar x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M73.5207 175.808L94.2653 176.534L93.9801 185.102L93.695 193.671L74.3762 193.018L73.093 189.532L73.5207 175.808Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Dade x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M73.6633 172.395L73.592 175.807L73.093 189.532L48.8553 188.297L49.1405 171.233L73.6633 172.395Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Barton x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M73.0931 189.532L74.3763 193.017L73.9485 207.25L48.5703 206.379L48.8555 189.822V188.297L73.0931 189.532Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Jasper x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M48.499 206.379L73.8772 207.25L73.2357 224.896L48.2139 223.444L48.499 206.379Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Newton x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M73.3069 224.896L72.7366 239.564L48 239.201L48.1426 229.253L48.2139 223.444L73.3069 224.896Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="McDonald x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M93.6951 193.671L94.408 204.491L94.2654 210.518L94.1941 214.802L73.5922 214.076L73.8773 207.25L74.305 193.018L93.6951 193.671Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Lawrence x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M73.6633 214.076L94.2653 214.802L94.1227 220.829L95.9049 220.902L95.3346 239.782L82.4316 239.71L72.7366 239.637L73.6633 214.076Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Barry x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M96.2615 161.502L113.584 163.027L113.798 185.756L93.9803 185.03L94.2655 176.461L93.8378 161.357L96.2615 161.502Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Polk x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M94.3367 210.518L106.741 210.736L106.527 221.047L108.238 221.12L107.667 239.855L95.3347 239.782L95.905 220.902L94.1228 220.829L94.2654 214.802L94.3367 210.518Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Stone x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M113.798 185.756L118.717 185.901L118.574 194.47H119.216L119.073 205.144L94.4078 204.491L93.6949 193.671L93.9801 185.102L113.798 185.756Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Greene x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M126.202 221.628L132.832 221.846L132.547 240H128.768L108.523 239.928H107.667L108.238 221.193L126.202 221.628Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Taney x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M119.144 205.144L126.487 206.233L126.202 221.628L108.238 221.12L106.527 221.047L106.741 210.736L94.3365 210.518L94.4791 204.491L119.144 205.144Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Christian x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M128.697 181.835L136.254 181.98L136.04 194.833H136.61L136.325 206.379L126.487 206.161L119.145 205.072L119.287 194.397H118.646L118.788 185.829L128.697 181.835Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Webster x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M118.788 157.218L128.554 157.581L128.341 167.892H128.911L128.626 181.835L118.717 185.901L113.798 185.756L113.584 163.027L118.788 157.218Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Dallas x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M128.555 157.581L139.176 157.799L139.248 158.017L139.176 158.162L139.461 159.469L140.103 160.849L141.743 162.882L142.456 163.172L142.883 163.1L146.02 161.575L148.729 159.687L148.586 168.255L156 172.104V174.718L155.929 182.488L136.254 182.052L128.697 181.907L128.982 167.965H128.412L128.555 157.581Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } id="Laclede x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#A0A8B7" } strokeLinejoin="round" strokeWidth="0.8"/>
                <HotSpot id="southwest_hotspot" x="75" y="190" label={"Southwest"} count={props.count} focused={props.focused} filters={props.filters} minYear={props.minYear} maxYear={props.maxYear} />
            </g>
}

function StLouisRegionPath(props) {

    return <g id="map/St. Louis" onMouseEnter={ props.onFocusEnter } onMouseLeave={ props.onFocusExit } >

                <path d="M222.831 80L224.729 87.1601L224.656 87.8761L223.342 89.7377L223.269 89.8809L223.342 90.3105L225.604 96.8262L212.396 100.764H207.579L204.295 97.399L201.814 94.0338H198.384V85.5133H201.741V80.358H209.842L222.831 80Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } id="Lincoln x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M198.384 93.8906H201.814L204.295 97.2559L207.579 100.621H212.396L212.542 119.381L200.428 114.154L200.136 113.581L196.414 110.43L195.903 110.359L193.933 111.075L191.67 110.502V102.626H198.53L198.384 93.8906Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } id="Warren x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M212.542 119.524L222.977 113.939L223.05 123.963L221.445 139.214L206.995 139.5L194.516 139.429L194.443 132.34L194.152 122.03L193.933 111.075L195.976 110.502L196.487 110.574L200.209 113.724L200.5 114.297L212.542 119.524Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } id="Franklin x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M247.278 107.423L248.081 112.077L244.797 119.595L242.973 118.593L242.462 115.514L241.878 111.003L243.046 108.712L244.943 107.423H247.278Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } id="St. Louis x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M235.31 94.5349L243.337 96.6829L244.505 97.0409L245.6 97.6138L246.475 98.115L250.854 100.907L251 101.265V101.623L250.781 102.984L250.635 103.557L250.197 102.697L249.467 102.196L248.665 102.268L247.57 102.984L246.767 102.697L243.994 100.979L243.556 100.478L240.272 100.621L240.199 101.337L239.835 102.125L239.543 102.554L239.032 102.984H238.375L236.77 102.626H236.259L235.894 102.769L235.602 103.127L234.434 104.989L231.953 109.786V110.43L231.661 111.003L230.932 111.361L230.275 111.433L226.334 111.075L225.969 111.146L225.385 111.576L223.999 113.581L222.904 114.01L212.469 119.595L212.323 100.836L225.531 96.8977L235.31 94.5349Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } id="St. Charles x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M250.708 103.557L247.278 107.423H245.016L243.118 108.712L241.951 111.003L242.535 115.586L243.045 118.664L244.87 119.667L244.651 120.383L241.221 128.331L240.929 127.042L238.229 121.672L229.691 121.815L223.634 123.104L223.123 124.035L223.05 114.01L224.145 113.581L225.531 111.576L226.115 111.146L226.48 111.075L230.421 111.433L231.077 111.361L231.807 111.003L232.099 110.43V109.786L234.58 104.989L235.748 103.127L236.04 102.769L236.405 102.626H236.915L238.521 102.984H239.178L239.689 102.554L239.981 102.125L240.345 101.337L240.418 100.621L243.702 100.478L244.14 100.979L246.913 102.697L247.716 102.984L248.811 102.268L249.613 102.196L250.343 102.697L250.708 103.557Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } id="St. Louis Co. x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M223.05 123.963L223.561 123.032L229.618 121.743L238.083 121.529L240.783 126.899L241.075 128.187L240.929 128.331L240.71 128.76L239.981 130.98L239.908 131.195L239.835 131.767V134.202L240.346 136.78L240.856 138.068L241.659 139.214L241.805 139.429L241.951 139.572L245.673 143.367L238.302 148.093L227.94 146.374L221.299 139.142L223.05 123.963Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } id="Jefferson x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M194.517 139.5L206.995 139.572L207.36 166.494L204.952 169H197.727V163.916L187.584 163.988L187 142.866L194.517 139.5Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } id="Crawford x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } strokeLinejoin="round" strokeWidth="0.8"/>
                <path d="M221.445 139.286L228.085 146.517L228.377 166.351L207.433 166.494L207.068 139.572L221.445 139.286Z" fill={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } id="Washington x2C  MO 1" stroke={ props.focused ? REGION_HIGHLIGHT_COLOR : "#45536F" } strokeLinejoin="round" strokeWidth="0.8"/>
                <HotSpot id="stlouis_hotspot" x="205" y="110" label={"St. Louis"} count={props.count} focused={props.focused} filters={props.filters} minYear={props.minYear} maxYear={props.maxYear} />
            </g>
}

let regions = [ "Kansas City", "St. Louis", "Central", "Southeast", "Southwest", "Northwest", "Northeast", "Ozark" ]

function pathForRegion(region, props) {
  let linkHref = `/search?f.region=${region}&f.dateRange=${ [props.filters.active_begin ?? props.minYear, props.filters.active_end ?? props.maxYear] }`;
  switch (region) {
    case 'Kansas City':
        return <a href={linkHref} className="text-reset text-decoration-none"><KCRegionPath {...props} /></a>
    case 'Northwest':
        return <a href={linkHref} className="text-reset text-decoration-none"><NorthwestRegionPath {...props} /></a>
    case 'Northeast':
        return <a href={linkHref} className="text-reset text-decoration-none"><NortheastRegionPath {...props} /></a>
    case 'Central':
        return <a href={linkHref} className="text-reset text-decoration-none"><CentralRegionPath {...props} /></a>
    case 'St. Louis':
        return <a href={linkHref} className="text-reset text-decoration-none"><StLouisRegionPath {...props} /></a>
    case 'Ozark':
        return <a href={linkHref} className="text-reset text-decoration-none"><OzarkRegionPath {...props} /></a>
    case 'Southwest':
        return <a href={linkHref} className="text-reset text-decoration-none"><SouthwestRegionPath {...props} /></a>
    case 'Southeast':
        return <a href={linkHref} className="text-reset text-decoration-none"><SoutheastRegionPath {...props} /></a>
    default:
        break
  }
}

function FacettedMapView(props) {
    let counts = Object.fromEntries( regions.map( (region) => {
        return [region, props.dataSource.buckets?.filter( buck => buck.key === region).reduce( (acc,cur) => acc + cur.active.doc_count, 0) ?? 0];
    }) );

    const [ focused, setFocused ] = useState(Object.fromEntries( regions.map( region => [region,false] ) ))

    function handleRegionFocus(region,enter=true) {
        setFocused( (prevState) => ({ ...prevState, [region]: enter }) )
    }

    function sortByFocus(a,b) {
        if (focused[a]) {
            return 1
        } else if (focused[b]) {
            return -1
        } else {
            return 0
        }
    }

    return (
        <svg fill="none" height="auto" viewBox="0 -50 329 340" width="auto" xmlns="http://www.w3.org/2000/svg">
            <g id="map">
                <g id="map-group">
                    {
                    /*
                        Since SVG is a painting model, paths that are instantiated later will draw over earlier-instantiated paths.
                        So sort by focus to keep the focused region on "top"
                    */
                    regions.sort( sortByFocus ).map( region => pathForRegion(region, {count: counts[region], focused: focused[region], onFocusEnter: () => { handleRegionFocus(region) }, onFocusExit: () => { handleRegionFocus(region,false) }, filters: props.filters, minYear: props.minYear, maxYear: props.maxYear } ) )
                    }
                </g>
            </g>
        </svg>
    )
}

function HotSpot(props) {

    let cx = 16,
        cy = 16, 
        x = Number(props.x) ?? 0,
        y = Number(props.y) ?? 0,
        textXAnchor = 16,
        textYAnchor = 22,
        circlePadding = 0.0,
        limitRadius = 15.5,
        singleDigitWidth = 7.75;

    let digitCount = props.count ? props.count.toString().length : 0 
    let countWidth = digitCount * singleDigitWidth;
    let circleRadius = countWidth > limitRadius ? countWidth + circlePadding : limitRadius; 

    // FIXME: If the size of props.count is > n, increase r size
    return <g id={props.id}>
            <circle cx={cx+x} cy={cy+y} fill="white" fillOpacity="0.4" id="Ellipse 19_8" r={circleRadius} stroke="white"/>
            <text x={textXAnchor + x} y={textYAnchor + y} textAnchor="middle" fill='#222B37' className="font-size-lg">{props.count}</text>
            <Tooltip id={props.id} x={props.x} y={props.y} label={props.label} show={props.focused} count={props.count} filters={props.filters} minYear={props.minYear} maxYear={props.maxYear} />
            </g>
}

function Tooltip(props) {
    // This is just what's on the tin, but with a unique ID for the filters so multiple can be used
    let [ident, setIdent] = useState(props.id ?? 0)

    let digitCount = props.count ? props.count.toString().length : 0 
    let artistsActiveSpanX = digitCount > 2 ? 66 + 12 : 66;

    return <svg className="tooltip-svg" width="163" height="86" x={props.x - (props.constant ?? 70)} y={props.y - (props.constant ?? 70)} fill="none" xmlns="http://www.w3.org/2000/svg" display={props.show ? 'inline' : 'none'}>
            <g filter={`url(#filter0_d${ident})`}>
            <mask id="path-1-inside-1" fill="white">
            <path fillRule="evenodd" clipRule="evenodd" d="M27 4C24.7909 4 23 5.79086 23 8V65.0833C23 67.2925 24.7909 69.0833 27 69.0833H80.5404L86.3938 74.0002L92.2472 69.0833H150.483C152.692 69.0833 154.483 67.2925 154.483 65.0833V8C154.483 5.79086 152.692 4 150.483 4H27Z"/>
            </mask>
            <path fillRule="evenodd" clipRule="evenodd" d="M27 4C24.7909 4 23 5.79086 23 8V65.0833C23 67.2925 24.7909 69.0833 27 69.0833H80.5404L86.3938 74.0002L92.2472 69.0833H150.483C152.692 69.0833 154.483 67.2925 154.483 65.0833V8C154.483 5.79086 152.692 4 150.483 4H27Z" fill="white"/>
            <path d="M80.5404 69.0833L81.1836 68.3176L80.9047 68.0833H80.5404V69.0833ZM86.3938 74.0002L85.7506 74.7659L86.3938 75.3061L87.037 74.7659L86.3938 74.0002ZM92.2472 69.0833V68.0833H91.883L91.604 68.3176L92.2472 69.0833ZM24 8C24 6.34314 25.3431 5 27 5V3C24.2386 3 22 5.23857 22 8H24ZM24 65.0833V8H22V65.0833H24ZM27 68.0833C25.3431 68.0833 24 66.7402 24 65.0833H22C22 67.8448 24.2386 70.0833 27 70.0833V68.0833ZM80.5404 68.0833H27V70.0833H80.5404V68.0833ZM87.037 73.2345L81.1836 68.3176L79.8972 69.849L85.7506 74.7659L87.037 73.2345ZM91.604 68.3176L85.7506 73.2345L87.037 74.7659L92.8904 69.849L91.604 68.3176ZM150.483 68.0833H92.2472V70.0833H150.483V68.0833ZM153.483 65.0833C153.483 66.7402 152.14 68.0833 150.483 68.0833V70.0833C153.244 70.0833 155.483 67.8448 155.483 65.0833H153.483ZM153.483 8V65.0833H155.483V8H153.483ZM150.483 5C152.14 5 153.483 6.34314 153.483 8H155.483C155.483 5.23858 153.244 3 150.483 3V5ZM27 5H150.483V3H27V5Z" fill="#2471A7" mask="url(#path-1-inside-1)"/>
            </g>
            <text fill="#45536F" style={{whiteSpace: 'pre', fontFamily:"Aktiv Grotesk Cd", fontSize:"24", letterSpacing:"0em"}}><tspan x="30" y="52.456">{props.count}</tspan></text>
            <path d="M23 8C23 5.79086 24.7909 4 27 4H150C152.209 4 154 5.79086 154 8V27H23V8Z" fill="#2471A7"/>
            <text fill="#222B37" style={{whiteSpace: 'pre', fontFamily:"Aktiv Grotesk Cd", fontSize:"12", letterSpacing:"0em"}}><tspan x={artistsActiveSpanX} y="40.228">artists active</tspan><tspan x={artistsActiveSpanX} y="55.228">{props.filters?.active_begin ?? props.minYear}-{props.filters?.active_end ?? props.maxYear}</tspan></text>
            <text fill="white" className="tooltip-heading" style={{whiteSpace: 'pre', fontFamily:"Aktiv Grotesk Cd", fontSize:"12", fontWeight:"bold", letterSpacing:"0em"}}><tspan x="45" y="19.728">{props.label} region</tspan></text>
            <defs>
            <filter id={`filter0_d${ident}`} x="15" y="0" width="147.483" height="86.0002" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="4"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
            </defs>
            </svg>

}
export { MapFacetView, MapHeroView };