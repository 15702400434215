import React from 'react';
import { TimelineVisualization } from './timeline.js';
import { TermBarPlotView } from './barchart.js';
import { SummaryView } from './vizsummary.js';
import { MapFacetView } from './map.js';

// max number of bars to display in bar charts:
const barsDisplayLimit = 9;

// will only display on medium-size screens & larger
function VisualizationFilterControl(props) {
    return <div className="row">
                <div className="col-md-12">
                    <TimelineVisualization
                        minYear={props.minYear}
                        maxYear={props.maxYear}
                        filters={props.filters}
                        setFilters={props.setFilters}
                        isSelectedEra={props.isSelectedEra}
                        setIsSelectedEra={props.setIsSelectedEra}
                        setSelectedEra={props.setSelectedEra}
                        dateLimited={props.dateLimited}
                        dataSource={('active_years' in props.aggregations) ? props.aggregations.active_years.buckets : []}
                        count={props.count}
                        erasSet={props.erasSet}
                        erasCollection={props.erasCollection}
                        resetTrigger={props.resetTrigger}
                        setResetTrigger={props.setResetTrigger}
                        resetYears={props.resetYears}
                        highlightEra={props.highlightEra}
                        setHighlightEra={props.setHighlightEra}
                    />
                </div>
            </div>
}

function VisualizationSummary(props) {
    return <div className="row">
                <div className="d-flex justify-content-center col-12">
                    <SummaryView
                        selection={[0, props.maxYear-props.minYear]}
                        filters={props.filters}
                        dataSource={[props.count]}
                    />
                </div>
            </div>
}

function VisualizationView(props) {
    return <div className="row">
            <div className="col-lg-8 col-md-6 col-sm-12">
                <MapFacetView
                    dataSource={props.aggregations.active_locations ?? {}}
                    title="BY LOCATION"
                    filters={props.filters}
                    minYear={props.minYear}
                    maxYear={props.maxYear}
                />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
                <TermBarPlotView
                    title="Artists by Role"
                    filters={props.filters}
                    dataSource={(props.aggregations.artist_role) ? props.aggregations.artist_role.buckets.slice(0, barsDisplayLimit) : []}
                    chartType={'artist_role'}
                />
            </div>
        </div>
}

export { VisualizationFilterControl, VisualizationView, VisualizationSummary };