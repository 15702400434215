import React, { useState, useEffect, useContext } from "react";
import DOMPurify from 'dompurify';

const RESULTS_MODE = {
  GRID: "GRID",
  LIST: "LIST",
};

function PaginationControl(props) {

  const fi = Number(props.fromIndex);
  const ps = Number(props.pageSize);

  let firstPageAvail = fi > 0;
  let prevPageAvail = fi > 0;

  let nextPageAvail = fi + ps < props.count;
  let lastPageAvail = fi < props.count;

  let currentPage = props.count > 0 ? Number(fi / ps) + 1 : 0;
  let totalPage = Math.ceil(props.count / ps);

  function nextPageHandler(event) {
    event.preventDefault();
    // console.log([fi, props.count, ps]);
    if (nextPageAvail) {
      let idx = fi + ps;
      props.changePage(idx,props.scrollToTop);
    }
  }

  function prevPageHandler(event) {
    event.preventDefault();
    if (prevPageAvail) {
      let idx =
        fi - ps > 0
          ? fi - ps
          : 0;
      props.changePage(idx,props.scrollToTop);
    }
  }

  function lastPageHandler(event) {
    event.preventDefault();
    if (lastPageAvail) {
      var lastPage = Math.floor(props.count / ps);
      if (props.count % ps === 0) {
        // When pageSize is an integer divisors of count, the last page is one from the end
        lastPage = lastPage - 1
      }

      let lastPageIndex = lastPage * ps;
      props.changePage(lastPageIndex,props.scrollToTop);
    }
  }

  function firstPageHandler(event) {
    event.preventDefault();
    if (firstPageAvail) {
      props.changePage(0,props.scrollToTop);
    }
  }

  return (
    <div className="landing-page__table-pagination morem-pr-hide">
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className="page-item" disabled={!firstPageAvail}>
            <a
              className="page-link"
              href="javascript:;"
              aria-label="First"
              onClick={firstPageHandler}
            >
              <span
                aria-hidden="true"
                className="icon icon-double-carat"
              ></span>
              <span className="sr-only">First</span>
            </a>
          </li>
          <li className="page-item" disabled={!prevPageAvail}>
            <a
              className="page-link"
              href="javascript:;"
              aria-label="Previous"
              onClick={prevPageHandler}
            >
              <span aria-hidden="true" className="icon icon-carat-left"></span>
              <span className="sr-only">Previous</span>
            </a>
          </li>
          <li className="page-item">
            <p className="landing-page__table-pagination-text text-center">
              Page{" "}
              <span className="landing-page__table-pagination-current d-block d-sm-inline">
                {currentPage}
              </span>{" "}
              of {totalPage}
            </p>
          </li>

          <li className="page-item" disabled={!nextPageAvail}>
            <a
              className="page-link"
              href="javascript:;"
              aria-label="Next"
              onClick={nextPageHandler}
            >
              <span aria-hidden="true" className="icon icon-carat-right"></span>
              <span className="sr-only">Next</span>
            </a>
          </li>
          <li className="page-item" disabled={!lastPageAvail}>
            <a
              className="page-link"
              href="javascript:;"
              aria-label="Last"
              onClick={lastPageHandler}
            >
              <span
                aria-hidden="true"
                className="icon icon-double-carat-right"
              ></span>
              <span className="sr-only">Last</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

function ResultsLabel(props) {
  let firstIndex = `${props.count > 0 ? Number(props.fromIndex) + 1 : 0}`;
  let lastIndex =
    Number(props.fromIndex) + Number(props.pageSize) > props.count
      ? props.count
      : Number(props.fromIndex) + Number(props.pageSize);

  return (
    <h5 className="landing-page__table-showing">
      Showing{" "}
      <span className="d-block d-sm-inline">
        <span className="landing-page__table-showing-numbers">
          {firstIndex}-{lastIndex}
        </span>{" "}
        of{" "}
        <span className="landing-page__table-showing-total">
          {props.count}
        </span>{" "}
      </span>
      {props.typeLabel}
    </h5>
  );
}

function ImageCheckboxControl(props) {

  function check(event) {
    if (props.filters.images === true) {
      props.applyFilters({},['images']);
    } else {
      props.applyFilters({images: true},[])
    }
    // console.log("did check")
    // setChecked((checked) => !checked, () => { checked ? props.applyfilters({},['images']) : props.applyFilters({images: true},[]) } );
  }

  return (
    <div className="landing-page__table-hasimage morem-pr-hide d-none d-md-block">
      <div className="checkmark">
        <input
          id="image-filter-input"
          type="checkbox"
          name="imageFilterInput"
          checked={props.filters.images === true}
          onChange={ check }

        />
        <label htmlFor="image-filter-input">Has Artwork</label>
      </div>
    </div>
  );
}

export const ALL_SORT_SETTINGS = {
  artist: {
    sort_name: {label: 'Last Name', key: 'sort_title.label.sort', defaultSort: 'asc', default: true },
    name: {label: 'Full Name', key: 'primary_title.label.sort', defaultSort: 'asc' },
    active_date: {label: 'Active Dates', key: 'active.date_begin', defaultSort: 'asc'},
    life_dates: {label: 'Life Dates', key: 'birth.date_first', defaultSort: 'asc' }
  },
  organizations: {
    sort_name: {label: 'Name', key: 'primary_title.label.sort', defaultSort: 'asc', default: true },
    active_date: {label: 'Active Dates', key: 'active.date_begin', defaultSort: 'asc'},
    artists: {label: '# Artists', key: '_script.artists_count', defaultSort: 'desc' },
    exhibitions: {label: '# Exhibitions', key: '_script.exhibitions_count', defaultSort: 'desc'},
  },
  exhibitions: {
    sort_name: {label: 'Name', key: 'primary_title.label.sort', defaultSort: 'asc', default: true },
    active_date: {label: 'Dates', key: 'active.date_begin', defaultSort: 'asc'},
    artists: {label: '# Artists', key: '_script.artist_participant_count', defaultSort: 'desc' },
  },
  search: {
    score: {label: 'Relevance', key: '_score', defaultSort: 'desc', default: true },
    sort_name: {label: 'Last Name', key: 'sort_title.label.sort', defaultSort: 'asc' },
    name: {label: 'Full Name', key: 'primary_title.label.sort', defaultSort: 'asc' },
    active_date: {label: 'Active Dates', key: 'active.date_begin', defaultSort: 'asc'},
    life_dates: {label: 'Life Dates', key: 'birth.date_first', defaultSort: 'asc' }
  }
}

function SortControl(props) {
  let sortSettings = props.entityType.toLowerCase() in ALL_SORT_SETTINGS ? ALL_SORT_SETTINGS[props.entityType.toLowerCase()] : {}

  const sortTypeChange = (event,sortConfig) => {
    event.preventDefault()
    props.changeSortType(sortConfig.key); 
    props.changeSortDirection(sortConfig.defaultSort)
  }

  const sortDirectionChange = (event) => {
    event.preventDefault()
    props.changeSortDirection( props.sortDir === 'asc' ? 'desc' : 'asc' )
  }

  return (
    <div className="landing-page__table-sort morem-pr-hide d-none d-md-block">
      <div className="text-center">
        <button
          type="button"
          className={`btn btn-link icon icon-sort--${ props.sortDir === 'asc' ? 'up' : 'down' }`}
          onClick={ sortDirectionChange }
        ></button>
        <button
          type="button"
          className="btn btn-link dropdown-toggle"
          data-toggle="dropdown"
        >
          Sort By
        </button>
        <div className="dropdown-menu">
          { Object.entries(sortSettings).map( ([sortType,sortConfig],idx) => {
                      return <a key={idx} className={`dropdown-item ${ props.sortType === sortConfig.key ? 'active' : '' }`} href="#" onClick={ (event) => sortTypeChange(event,sortConfig) }>{sortConfig.label}</a>
                    })}
        </div>
      </div>
    </div>
  );
}

function ResultsPageControl(props) {
  return (
    <div className="landing-page__table-controls">
      <ResultsLabel
        count={props.count}
        fromIndex={props.fromIndex}
        pageSize={props.pageSize}
        typeLabel={props.resultsTypeLabel}
      />
      { props.entityType.toLowerCase() === 'artist' ? <ImageCheckboxControl filters={props.filters} applyFilters={props.applyFilters}/> : <div></div> }
      <SortControl entityType={ props.queryString === '' ? props.entityType : 'search' } sortType={props.sortType} sortDir={props.sortDir} changeSortType={props.changeSortType} changeSortDirection={props.changeSortDirection}/>
      <PaginationControl
        changePage={props.changePage}
        pageSize={props.pageSize}
        fromIndex={props.fromIndex}
        count={props.count}
        scrollToTop={props.scrollToTop}
      />
    </div>
  );
}

function ResultsView(props) {
  switch (props.mode) {
    case RESULTS_MODE.LIST:
      return <ResultsListView results={props.results} landingType={props.landingType}></ResultsListView>;
    case RESULTS_MODE.GRID:
      return <ResultsGridView results={props.results}></ResultsGridView>;
  }
}

function ResultPill(props) {
  switch (props.classification.toLowerCase()) {
    case "male":
      return <div className="pill">M</div>;
      break;
    case "female":
      return <div className="pill pill--female">F</div>;
      break;
    case "unknown":
      return <div className="pill pull--unknown">U</div>;
      break;
    default:
      return <div className="pill">{props.classification}</div>;
      break;
  }
}

function ActivityDatesLocationsView(props) {
  // note: using a simple lexical sort since all the date_labels start with years
  return (
    <td className="landing-page__table-col--when-active">
      {props.dataSource.sort((a, b) => (a.date_label > b.date_label) ? 1 : -1).map((activity, idx) => {
        return (
          <React.Fragment key={idx}>
            <span className="landing-page__table-locations">
              {activity.location.label}
            </span>
            <h5 className="landing-page__table-year">{activity.date_label}</h5>
            <br />
          </React.Fragment>
        );
      })}
    </td>
  );
}

function LocatedInView(props) {

  let label = props.dataSource.length > 0 ? props.dataSource[0].label : ''
  return (
    <td className="landing-page__table-col--org-location">
          <React.Fragment>
            <span className="landing-page__table-locations">
              {label}
            </span>
          </React.Fragment>
    </td>
  );
}

  function iconFromClassification(c) {
    
    if (c.icon) {
      return [ c.icon, c.label ]
    } else {
      return [ '', '' ]
    }

  }

function ClassificationIconsView(props) {

  return (
    <td className="landing-page__table-col--artist-type">
      <div className="d-flex justify-content-between">
        {props.dataSource.slice(0, props.limit).map((item,i) => {
          const [icon, label] = iconFromClassification(item);
          return (
            <div key={i}>
              <span className={`icon ${icon} icon-xxl morem-pr-hide`} title={`${item.label}`}></span>
              <img className="morem-pr-block" src={`/assets/images/pr-lt-${icon}.png`} alt="Artist type icon" width="40" height="40" />
              <span className="sr-only">{`${item.label}`}</span>
            </div>
          );
        })}
      </div>
    </td>
  );
}

function AdministrativeIconsView(props) {
  return (
    <td className="landing-page__table-col--information d-none">
      <div className="d-flex justify-content-between">
        <span>
          <span className="icon icon-property-file icon-xl"></span>
          <span className="sr-only">Artist file available</span>
        </span>
        <span>
          <span className="icon icon-property-user icon-xl"></span>
          <span className="sr-only">Biography available</span>
        </span>
        <span>
          <span className="icon icon-property-image icon-xl"></span>
          <span className="sr-only">Image available</span>
        </span>
      </div>
    </td>
  );
}

function OrganizationCountsView(props) {
  // console.log(props.dataSource)
  let filterName = props.dataSource._id.includes("MOREMOrgAuth") ? 'organizer_authority' : 'organizer'
  let exhibsAnchorProps = props.dataSource._source?.carried_out?.length > 0 ? { href: `/exhibition/?f.${filterName}=${ encodeURIComponent(props.dataSource._source.primary_title.label ?? '')}` } : { }
  return (
    <td className="landing-page__table-col--org-counts">
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex flex-column">
          <span className="landing-page__table-count d-flex">
            <span className="icon icon-property-user icon-lg"></span>
             <span className="landing-page__table-count-label">{props.dataSource._source.member?.length ?? 0} Artists</span>
          </span>
        </div>
        <div className="d-flex flex-column">
          <span className="landing-page__table-count d-flex">
            <span className="icon icon-exhibition icon-lg"></span>
             <span className="landing-page__table-count-label"><a {...exhibsAnchorProps}>{props.dataSource._source.carried_out?.length ?? 0} Exhibitions</a></span>
          </span>
        </div>
      </div>
    </td>
  );
}

function ExhibitionCountsView(props) {

  return (
    <td className="landing-page__table-col--org-counts">
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex flex-column">
          <span className="landing-page__table-count d-flex">
            <span className="icon icon-property-user icon-lg"></span>
             <span className="landing-page__table-count-label">{props.dataSource._source.had_participant.length ?? 0} Artists</span>
          </span>
        </div>
      </div>
    </td>
  );
}

function ClassifiedAsView(props) {

  const items = props.dataSource.map(({label})=> label);

  return (
      <div className="landing-page__table-classified-as">
        {items.join(", ")}
      </div>
  );
}

function ResultListItem(props) {
  let landingType = props.result._source.display_type;
  switch (landingType) {
    case "Organizations":
      return ResultListItemOrganization(props);
      break;
    case "Exhibitions":
      return ResultListItemExhibition(props);
      break;
    case "Artist":
    default:
      return ResultListItemArtist(props);
      break;
  }
}

function ResultListItemArtist(props) {
  let ident = new URL(props.result._id);
  let itemLink = `${ident.pathname}/`;
  let itemImageLink =
    props.result?._source?.representation?.depicted[0]?.link?.replace(
      "http://www.collectionbrowse.org/DigitalObject",
      ""
    );
  let hasImage = props.result?._source?.representation?.depicted.length > 0;

  return (
    <tr>
      <td>
        <div
          className="landing-page__thumbnail img-fluid rounded-circle img-row-thumbnail morem-pr-hide"
          style={
            hasImage
              ? {
                  backgroundImage: `url(${itemImageLink})`,
                }
              : {}
          }
        >
          {hasImage ? "" : <span className="icon icon-nouser"></span>}
        </div>
        <div
          className="landing-page__thumbnail img-fluid rounded-circle img-row-thumbnail morem-pr-block"
        >
          {hasImage ? <img className="morem-pr-thumbnail" src={`${itemImageLink}`} alt="Artist image" /> : <span className="icon icon-nouser"></span>}
        </div>
      </td>
      <td scope="row" className="landing-page__table-col--name">
        <div>
          <h4 className="landing-page__table-name">
            <a href={itemLink}>{props.result._source.primary_title.label}</a>
          </h4>
          <h5 className="landing-page__table-year">
            {props.result?._source?.birth?.date_first?.slice(0, 4) ?? ""} -{" "}
            {props.result?._source?.death?.date_last?.slice(0, 4) ?? ""}
          </h5>
          <div className="d-flex">
            <ResultPill
              classification={props.result._source?.gender?.label ?? ""}
            />
            <ResultPill
              classification={props.result._source?.ethnicity?.label ?? ""}
            />
          </div>
        </div>
      </td>
      <ActivityDatesLocationsView
        dataSource={props.result._source.active ?? []}
      />
      <ClassificationIconsView
        dataSource={props.result._source.artist_role ?? []}
        limit={4}
      />
      <AdministrativeIconsView dataSource={[] /* FIXME */} />
    </tr>
  );
}

function ResultListItemOrganization(props) {

  let ident = new URL(props.result._id);
  let itemLink = `${ident.pathname}/`;
  let itemImageLink =
    props.result?._source?.representation?.depicted[0]?.link?.replace(
      "http://www.collectionbrowse.org/DigitalObject",
      ""
    );
  let hasImage = props.result?._source?.representation?.depicted.length > 0;

  let highlight = props.result?.highlight ?? {};
  let primaryTitleHighlight = highlight['primary_title.label.english'] ?? [] 
  let otherTitleHighlight = highlight['other_title.label.english'] ?? [];

  let formattedFirstDate = props.result?._source?.formation?.date_label ? `${props.result?._source?.formation?.date_label} ` : ""
  let formattedLastDate = props.result?._source?.dissolution?.date_label ? ` ${props.result?._source?.dissolution?.date_label}` : ""

  let formattedDate = `${formattedFirstDate}${ formattedFirstDate && formattedLastDate ? '-' : '' }${ formattedLastDate && formattedLastDate.toLowerCase().trim() !== "present" ? formattedLastDate : '' }`

  return (
    <tr>
      <td>
        <div
          className="landing-page__thumbnail img-fluid rounded-circle img-row-thumbnail morem-pr-hide"
          style={
            hasImage
              ? {
                  backgroundImage: `url(${itemImageLink})`,
                }
              : {}
          }
        >
          {hasImage ? "" : <span className="icon icon-organization"></span>}
        </div>
        <div
          className="landing-page__thumbnail img-fluid rounded-circle img-row-thumbnail morem-pr-block"
        >
          {hasImage ? <img className="morem-pr-thumbnail" src={`${itemImageLink}`} alt="Artist image" /> : <span className="icon icon-nouser"></span>}
        </div>
      </td>
      <td scope="row" className="landing-page__table-col--name">
        <div>
          <h4 className="landing-page__table-name">
            <a href={itemLink} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(primaryTitleHighlight.length > 0 ? primaryTitleHighlight[0] : props.result._source.primary_title.label ) }}></a>
          </h4>
          <h5 className="landing-page__table-year">
            { formattedDate }
          </h5>
          <h5 className={`landing-page__table-aka ${otherTitleHighlight.length > 0 ? '' : 'd-none'}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`Also named: ${otherTitleHighlight.join(' | ')}`) }}>
          </h5>
        </div>
      </td>
      <LocatedInView
        dataSource={props.result._source.located_in ?? []}
      />
      <OrganizationCountsView dataSource={props.result} />
    </tr>
  );
}

function ResultListItemExhibition(props) {
  let ident = new URL(props.result._id);
  let itemLink = `${ident.pathname}/`;
  let itemImageLink =
    props.result?._source?.representation?.depicted[0]?.link?.replace(
      "http://www.collectionbrowse.org/DigitalObject",
      ""
    );
  let hasImage = props.result?._source?.representation?.depicted.length > 0;

  let highlight = props.result?.highlight ?? {};
  let otherTitleHighlight = highlight['other_title.label.phonetic'] ?? '';

  return (
    <tr>
      <td>
        <div
          className="landing-page__thumbnail img-fluid rounded-circle img-row-thumbnail morem-pr-hide"
          style={
            hasImage
              ? {
                  backgroundImage: `url(${itemImageLink})`,
                }
              : {}
          }
        >
          {hasImage ? "" : <span className="icon icon-exhibition-lg"></span>}
        </div>
        <div
          className="landing-page__thumbnail img-fluid rounded-circle img-row-thumbnail morem-pr-block"
        >
          {hasImage ? <img className="morem-pr-thumbnail" src={`${itemImageLink}`} alt="Artist image" /> : <span className="icon icon-nouser"></span>}
        </div>
      </td>
      <td scope="row" className="landing-page__table-col--name">
        <div>
          <h4 className="landing-page__table-name">
            <a href={itemLink}>{props.result._source.primary_title.label}</a>
          </h4>
          <h5 className="landing-page__table-org-responsible">
            {
              // A pipe-joined list of <a> tags with all organizers
              props.result?._source?.carried_out_by?.length > 0 ? 
                <React.Fragment>
                  <span>Organized by </span>{
                    props.result?._source?.carried_out_by?.map( org => { 
                              return <a className="" href={org.authority?.link ? org.authority?.link.replace('http://www.collectionbrowse.org','') : org.link.replace('http://www.collectionbrowse.org','')} >{org.label}</a>
                              })
                      .reduce((acc,cur) => <React.Fragment>{acc} | {cur}</React.Fragment> )                  
                }</React.Fragment> :
                <div className="d-none"></div>
            }
          </h5>
          <h5 className="landing-page__table-year">
            {props.result?._source?.timespan?.date_label ?? ""}
          </h5>
          <h5 className={`landing-page__table-aka ${otherTitleHighlight ? '' : 'd-none'}`} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`Also named: ${otherTitleHighlight}`) }}>
          </h5>
        </div>
      </td>
      <LocatedInView
        dataSource={props.result._source.located_in ?? []}
      />
      <ExhibitionCountsView dataSource={props.result} />
    </tr>
  );
}

function ResultsListView(props) {
  if (
    props.count === 0 ||
    props.results == null ||
    props.results.hits.total.value < 1
  ) {
    return (
      <span>"We couldn't find any results, please try another search"</span>
    );
  } else {

    if ('Organizations' == props.landingType || 'Exhibitions' == props.landingType) {

      return (
        <table className="table">
          <thead>
            <tr>
              <th className="landing-page__table-col--thumbnail"></th>
              <th className="landing-page__table-col--name">Name</th>
              <th className="landing-page__table-col--org-location">Location</th>
              <th className="landing-page__table-col--org-counts">
                information available
              </th>
            </tr>
          </thead>
          <tbody>
            {props.results.hits.hits.map((hit, idx) => {
              return <ResultListItem key={idx} result={hit} />;
            })}
          </tbody>
        </table>
      );

    } else {

      return (
        <table className="table">
          <thead>
            <tr>
              <th className="landing-page__table-col--thumbnail"></th>
              <th className="landing-page__table-col--name">Artist</th>
              <th className="landing-page__table-col--when-active">
                active in missouri
              </th>
              <th className="landing-page__table-col--artist-type">
                artist type
              </th>
              <th className="landing-page__table-col--information d-none">
                information available
              </th>
            </tr>
          </thead>
          <tbody>
            {props.results.hits.hits.map((hit, idx) => {
              return <ResultListItem key={idx} result={hit} />;
            })}
          </tbody>
        </table>
      );

    }
  }
}

function ResultsGridView(props) {
  return (
    <div className="col">
      GRID-RESULTS-HERE: <code>{JSON.stringify(props.results)}</code>
    </div>
  );
}

function ResultsContextView(props) {
  return (
    <div className="col-sm-3">
      <code>{props.aggregations}</code>
    </div>
  );
}

export { ResultsPageControl, ActivityDatesLocationsView, LocatedInView, iconFromClassification, ResultPill, ResultsView, RESULTS_MODE };
